const {
  differenceInCalendarDays,
  differenceInDays,
  isPast,
  isFuture,
  addDays,
  subDays,
} = require("date-fns");

const isEventPassed = (event) => isPast(event.event_end_time);
const isEventStarted = (event) => isPast(event.event_start_time);
const preRegEnd = (event) => subDays(event.event_start_time, 14);
const isPreRegActive = (event) => isFuture(preRegEnd(event));
const preRegPeriodRemaining = (event) =>
  differenceInDays(preRegEnd(event), Date.now());

const surveyEnd = (event) =>
  event.event_survey_open_at && addDays(event.event_survey_open_at, 15);
const isSurveyActive = (event) =>
  event.event_survey_open_at && isFuture(surveyEnd(event));
const surveyPeriodRemaining = (event) =>
  event.event_survey_open_at && differenceInDays(surveyEnd(event), Date.now());

const timeOptions = {
  dateStyle: "medium",
  timeStyle: "short",
};
const displayTime = (date) => date.toLocaleString("default", timeOptions);
const displayStartTime = (event) =>
  displayTime(new Date(event.event_start_time));
const displayEndTime = (event) => displayTime(new Date(event.event_end_time));

const eventLengthInDays = (event) =>
  differenceInCalendarDays(event.event_end_time, event.event_start_time);

const xpFunction = (pastEventCount, event) => {
  pastEventCount ||= 0;
  const baseValue =
    pastEventCount < 25
      ? 5 - Math.floor(pastEventCount / 5)
      : ((pastEventCount - 1) % 2) + 1;
  return Math.ceil(baseValue * eventLengthInDays(event) * 0.5);
};

module.exports = {
  eventLengthInDays,
  isEventPassed,
  isEventStarted,
  preRegEnd,
  isPreRegActive,
  preRegPeriodRemaining,
  surveyEnd,
  isSurveyActive,
  surveyPeriodRemaining,
  displayStartTime,
  displayEndTime,
  xpFunction,
};
