import { useState } from "react";
import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";

import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../FetchComponent";
import ConfirmationModal from "../ConfirmationModal";

import { patchUncommitUserCharacter } from "../../utils/apiInterface";

export default function UncommitCharacterButton({ character }) {
  const [modalOpen, setModalOpen] = useState(false);
  const uncommitFetcher = useFetcher();

  const uncommitCharacter = submitFetchFactory(
    { user_id: character.user._id, character_id: character._id },
    {
      method: "post",
      action: `/staff/manage_characters/uncommit_character`,
    },
    uncommitFetcher
  );

  return (
    <FetchComponent
      fetcher={uncommitFetcher}
      errorMessage="Something went wrong. Please try again later."
      invalidMessage="Something went wrong. Please reload the page and try again."
    >
      <Button onClick={() => setModalOpen(true)}>Uncommit</Button>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onConfirm={uncommitCharacter}
        confirmationButtonText="Confirm"
        allowConfirm={true}
      >
        Warning: By uncommitting the character, the user will be free change the
        character's name, race, class, attributes, and skills. Click "Uncommit"
        to complete this operation.
      </ConfirmationModal>
    </FetchComponent>
  );
}

UncommitCharacterButton.uncommitCharacterAction = fetchActionFactory(
  patchUncommitUserCharacter
);
