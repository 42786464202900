import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import FormPage from "./FormPage";
import { submissionActionFactory } from "../components/SubmissionForm";

import {
  getEventWithUserSurvey,
  postSurveyResponse,
} from "../utils/apiInterface";
import SurveyForm from "../components/forms/SurveyForm";

function SurveyPage() {
  const { event } = useLoaderData();

  const [questionAnswers, setQuestionAnswers] = useState([]);

  return (
    <FormPage backLabel="Back" backLink="/events">
      <h1>Event Survey: {event.event_title}</h1>
      <SurveyForm
        event={event}
        questionAnswers={questionAnswers}
        setQuestionAnswers={setQuestionAnswers}
      />
    </FormPage>
  );
}

SurveyPage.loader = async ({ params }) => {
  const { event } = await getEventWithUserSurvey({
    event_id: params.event_id,
  });
  return { event };
};

SurveyPage.action = submissionActionFactory(postSurveyResponse);

export default SurveyPage;
