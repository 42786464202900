import React, { useState } from "react";
import { Link, useActionData, useSubmit } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { postLogin, saveGameData } from "../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import UnauthorizedError from "../utils/errors/UnauthorizedError";
import SendVerifyComponent from "../components/SendVerifyComponent";
import FormPage from "./FormPage";

import Logo from "../assets/survive-together-revised.svg";

function LoginForm({ actionData }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const submit = useSubmit();
  const handleLogin = submitFormFactory({ email, password }, submit);

  return (
    <SubmissionForm
      onSubmit={handleLogin}
      navigateOnSubmit="/"
      submitLabel="Sign In"
      pendingMessage="Please wait while we log you in..."
      errorMessage="Error logging in. Please try again later."
      invalidMessage={
        actionData?.data?.user && !actionData.data.user.verified ? (
          <div>
            Sign in failed because your email has not yet been verified. To
            verify your account, check your email for the verification link. If
            you would like the verification email re-sent, click the button
            below.
            <SendVerifyComponent email={actionData.data.user.email} />
          </div>
        ) : (
          "Incorrect username or password. Please try again."
        )
      }
    >
      <Form.Group as={Row} className="mb-3" controlId="formEmail">
        <Form.Label column sm={2}>
          Email
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="email"
            placeholder="Email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPassword">
        <Form.Label column sm={2}>
          Password
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Link className="link-secondary" to="/forgot_password">
            Forgot Password?
          </Link>
        </Col>
      </Form.Group>
    </SubmissionForm>
  );
}

function LoginPage() {
  const actionData = useActionData();
  return (
    <FormPage>
      <Row>
        <Col>
          <h1>Welcome back to Forgotten Empires</h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs="auto">
          <img
            src={Logo}
            className="mt-2 mb-2"
            style={{ width: "300px", height: "300px" }}
            alt="logo"
          ></img>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h1 style={{ marginBottom: "1rem" }}>Log In:</h1>
          <LoginForm actionData={actionData} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 style={{ marginBottom: "1rem" }}>New here?</h1>
          <Link to={`/signup`}>
            <Button>Sign up</Button>
          </Link>
        </Col>
      </Row>
    </FormPage>
  );
}

LoginPage.action = async (req) => {
  const res = await submissionActionFactory(async (body) => {
    try {
      return await postLogin(body);
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        return new Response("{}", { status: 401 });
      }
      throw err;
    }
  })(req);
  if (res.submitted === "true") {
    saveGameData();
  }
  return res;
};

export default LoginPage;
