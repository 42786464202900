import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function ConfirmationModal({
  children,
  modalOpen,
  setModalOpen,
  onConfirm,
  allowConfirm,
  confirmationButtonText,
  hideCancel,
}) {
  return modalOpen ? (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button
          variant="primary"
          onClick={(event) => {
            if (onConfirm) {
              onConfirm(event);
            }
            setModalOpen(false);
          }}
          disabled={!allowConfirm}
        >
          {confirmationButtonText || "Confirm"}
        </Button>
        {!hideCancel && (
          <Button variant="secondary" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  ) : null;
}
