import React, { useState } from "react";
import {
  Link,
  useSubmit,
  useActionData,
  useParams,
  useLocation,
} from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { patchVerifyEmail } from "../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import SendVerifyComponent from "../components/SendVerifyComponent";
import FormPage from "./FormPage";

function VerifyPage() {
  const query = new URLSearchParams(useLocation()?.search);
  const { token } = useParams();
  const actionData = useActionData();
  const privacyOption = query.get("options") === "true";
  const [privacyAck, setPrivacyAck] = useState(false);

  const submit = useSubmit();
  const verify = submitFormFactory(
    {
      token,
      privacy_statement_accepted: privacyOption ? privacyAck : undefined,
    },
    submit
  );

  let page;

  if (actionData?.submitted === "true")
    page = (
      <>
        <h2>Account verified!</h2>
        <Link to="/login">
          <Button>Return to login</Button>
        </Link>
      </>
    );
  else if (actionData?.submitted === "invalid") {
    page = (
      <>
        {actionData?.errorMessage} To get a new verification email, please enter
        your email and click the button below.
        <SendVerifyComponent inputEmail />
      </>
    );
  } else {
    page = (
      <SubmissionForm
        onSubmit={verify}
        submitLabel="Verify"
        disableSubmit={privacyOption && !privacyAck}
        pendingMessage="Please wait while we verify your account..."
        errorMessage="Error verifying account. Please try again later."
        invalidMessage="Invalid submission. Please try again."
      >
        <h1>Click to verify your account:</h1>
        {privacyOption ? (
          <Form.Group as={Row} className="mb-3" controlId="formPrivacy">
            <Col sm={1}>
              <Form.Check
                type="checkbox"
                checked={privacyAck}
                onChange={() => {
                  setPrivacyAck(!privacyAck);
                }}
              />
            </Col>
            <Col sm={11}>
              <Form.Label>
                I acknowledge and accept that Forgotten Empires staff and
                database admins will be able to view my user information and
                character sheets. Any questions can be directed to
                info@forgottenempires.ca
              </Form.Label>
            </Col>
          </Form.Group>
        ) : null}
      </SubmissionForm>
    );
  }

  return <FormPage>{page}</FormPage>;
}

VerifyPage.action = submissionActionFactory(patchVerifyEmail);

export default VerifyPage;
