import Table from "react-bootstrap/Table";

import DeleteCharacterButton from "./buttons/DeleteCharacterButton";

export class CharacterTableColumn {
  constructor(header, className, dataFunction) {
    this.header = header;
    this.getData = dataFunction;
    this.className = className;
  }
}

export default function CharacterTable({
  characters,
  leftColumns,
  rightColumns,
  showUsers,
  showDelete,
  hideDeaths,
}) {
  return (
    <Table responsive>
      <thead>
        <tr>
          {leftColumns?.map((tableColumn, index) => (
            <th key={index}>{tableColumn.header}</th>
          ))}
          {showUsers ? <th>User</th> : null}
          <th>Name</th>
          <th>Race</th>
          <th>Class</th>
          <th>XP Total</th>
          {!hideDeaths && (
            <th className="center-align-column">Deaths -- Reaper Fights</th>
          )}
          {rightColumns?.map((tableColumn, index) => (
            <th key={index} className={tableColumn.className}>
              {tableColumn.header}
            </th>
          ))}
          {showDelete ? <th>Delete</th> : null}
        </tr>
      </thead>
      <tbody>
        {characters.map((char) => {
          return (
            <tr key={char._id}>
              {leftColumns?.map((tableColumn, index) => (
                <td className={tableColumn.className} key={index}>
                  {tableColumn.getData(char)}
                </td>
              ))}
              {showUsers ? (
                <td>{char.user.first_name + " " + char.user.last_name}</td>
              ) : null}
              <td>{char.saved_build.character_name}</td>
              <td className="text-capitalize">
                {[
                  char.saved_build.character_race.subculture,
                  char.saved_build.character_race.race,
                ].join(" ")}
              </td>
              <td className="text-capitalize">
                {char.saved_build.character_class}
              </td>
              <td>{char.character_xp_total} XP</td>
              {!hideDeaths && (
                <td className="center-align-column">
                  {char.character_deaths.death_count} --{" "}
                  {char.character_deaths.reaper_fight_count}
                </td>
              )}
              {rightColumns?.map((tableColumn, index) => (
                <td className={tableColumn.className} key={index}>
                  {tableColumn.getData(char)}
                </td>
              ))}
              {showDelete ? (
                <td className="center-align-column">
                  <DeleteCharacterButton character={char} />
                </td>
              ) : null}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
