import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";

import CharacterSelectionTable from "../../components/inputs/CharacterSelectionTable";

import { getCharacters } from "../../utils/apiInterface";
import Button from "react-bootstrap/esm/Button";
import FormPage from "../FormPage";

function SelectCharacterSheetsPage() {
  const { characters } = useLoaderData();

  const [selectedCharacters, setSelectedCharacters] = useState([]);

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_characters">
      <h2>Choose Character Sheets to Print:</h2>
      <CharacterSelectionTable
        allCharacters={characters}
        showUsers={true}
        selection={selectedCharacters}
        setSelection={setSelectedCharacters}
      />
      <Link
        to={selectedCharacters.length ? "/staff/print_character_sheets" : "#"}
        state={{
          characters: characters.filter(({ _id }) =>
            selectedCharacters.includes(_id)
          ),
        }}
      >
        <Button className="mt-3">Print</Button>
      </Link>
    </FormPage>
  );
}

SelectCharacterSheetsPage.loader = getCharacters;

export default SelectCharacterSheetsPage;
