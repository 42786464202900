import { useState } from "react";
import { useFetcher } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../FetchComponent";
import ConfirmationModal from "../ConfirmationModal";

import {
  deleteCharacter,
  deleteCurrentUserCharacter,
} from "../../utils/apiInterface";

export default function DeleteCharacterButton({
  character,
  deleteUserCharacter,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [enteredName, setEnteredName] = useState("");
  const deleteFetcher = useFetcher();

  const deleteCharacter = submitFetchFactory(
    { user_id: character.user._id, character_id: character._id },
    {
      method: "post",
      action: deleteUserCharacter
        ? "/delete_user_character"
        : "/delete_character",
    },
    deleteFetcher
  );

  return (
    <FetchComponent
      fetcher={deleteFetcher}
      errorMessage="Something went wrong. Please try again later."
      invalidMessage="Something went wrong. Please reload the page and try again."
    >
      <Button
        variant="danger"
        onClick={() => {
          setModalOpen(true);
          setEnteredName("");
        }}
      >
        Delete
      </Button>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onConfirm={deleteCharacter}
        confirmationButtonText="Delete"
        allowConfirm={enteredName === character.saved_build.character_name}
      >
        <div>
          Warning: this operation cannot be undone. This character and all their
          awarded xp will be lost forever.
          <br />
          Enter the character's name, "{character.saved_build.character_name}"
          in the box below to enable the option to delete.
        </div>
        <Form.Control
          type="text"
          placeholder="Character Name"
          onChange={(event) => setEnteredName(event.target.value)}
          value={enteredName}
        />
      </ConfirmationModal>
    </FetchComponent>
  );
}

DeleteCharacterButton.deleteCharacterAction =
  fetchActionFactory(deleteCharacter);

DeleteCharacterButton.deleteUserCharacterAction = fetchActionFactory(
  deleteCurrentUserCharacter
);
