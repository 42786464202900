import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import FormPage from "./FormPage";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";

import {
  getCurrentUserCharacters,
  getEvent,
  getEventRoleTypes,
  submitEventRegistration,
} from "../utils/apiInterface";
import EventRegistrationForm from "../components/forms/EventRegistrationForm";

function EventRegistrationPage() {
  const submit = useSubmit();
  const { event, characters, event_role_types } = useLoaderData();

  const [registrationType, setRegistrationType] = useState("");
  const [characterId, setCharacterId] = useState(characters[0]._id);
  const [questionAnswers, setQuestionAnswers] = useState({});

  const handleSubmit = submitFormFactory(
    {
      event: event._id,
      character: characterId,
      event_role: registrationType,
      question_answers: event.event_registration_questions
        .filter(
          (question) =>
            question.roles_to_ask.includes(registrationType) &&
            questionAnswers[question._id]
        )
        .map((question) => {
          return {
            event_registration_question: question._id,
            question_answer: questionAnswers[question._id],
          };
        }),
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/events">
      <h1>Event Registration: {event.event_title}</h1>
      <SubmissionForm
        onSubmit={handleSubmit}
        navigateOnSubmit="/events"
        submitLabel="Register"
        pendingMessage="Please wait while your registration is submitted..."
        errorMessage="There was a problem with the server. Please try again later."
        invalidMessage="There was a problem with your submission. Please try again."
      >
        <EventRegistrationForm
          frozen={false}
          event={event}
          eventRoleTypes={event_role_types}
          characters={characters}
          regConfirmed={false}
          prevRegistrationType={null}
          registrationType={registrationType}
          setRegistrationType={setRegistrationType}
          characterId={characterId}
          setCharacterId={setCharacterId}
          questionAnswers={questionAnswers}
          setQuestionAnswers={setQuestionAnswers}
        />
      </SubmissionForm>
    </FormPage>
  );
}

EventRegistrationPage.loader = async ({ params }) => {
  const { event } = await getEvent({ event_id: params.event_id });
  const { characters } = await getCurrentUserCharacters();
  const { event_role_types } = await getEventRoleTypes();
  return { event, characters, event_role_types };
};

EventRegistrationPage.action = submissionActionFactory(submitEventRegistration);

export default EventRegistrationPage;
