import React, { useState } from "react";
import { useSubmit, useLoaderData } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import {
  getRaces,
  getUsers,
  postInitializeCharacter,
} from "../../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../../components/SubmissionForm";

import CharacterBasicsForm from "../../components/forms/CharacterBasicsForm";
import UserDropDown from "../../components/inputs/UserDropDown";
import FormPage from "../FormPage";

function CreateCharacterForUserPage() {
  const { races, users } = useLoaderData();
  const submit = useSubmit();

  const [characterName, setCharacterName] = useState("");
  const [characterRace, setCharacterRace] = useState(races[0]);
  const [characterClass, setCharacterClass] = useState("fighter");
  const [startingXp, setStartingXp] = useState("10");
  const [deathCount, setDeathCount] = useState("0");
  const [reaperFights, setReaperFights] = useState("0");
  const [userId, setUserId] = useState(users[0]._id);

  const handleCreate = submitFormFactory(
    {
      character_name: characterName,
      race: characterRace,
      character_class: characterClass,
      character_xp_total: startingXp,
      death_count: deathCount,
      reaper_fight_count: reaperFights,
      user_id: userId,
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_characters">
      <SubmissionForm
        onSubmit={handleCreate}
        submitLabel="Create"
        navigateOnSubmit="/staff/manage_characters"
        pendingMessage="Please wait while we initialize the character..."
        invalidMessage="Error initializing character. Please try again later."
        errorMessage="Error occurred. Please try again later"
      >
        <h1>Create character for user:</h1>
        <CharacterBasicsForm
          races={races}
          setCharacterName={setCharacterName}
          setCharacterRace={setCharacterRace}
          setCharacterClass={setCharacterClass}
          characterName={characterName}
          characterRace={characterRace}
          characterClass={characterClass}
        >
          <Form.Group as={Row} className="mb-3" controlId="formStartingXP">
            <Form.Label column sm={2}>
              Starting XP
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="number"
                required
                placeholder="Starting XP"
                value={startingXp}
                onChange={(event) => setStartingXp(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formDeathCount">
            <Form.Label column sm={2}>
              Previous Character Deaths
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="number"
                required
                placeholder="Number of Deaths"
                value={deathCount}
                onChange={(event) => setDeathCount(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formReaperFights">
            <Form.Label column sm={2}>
              Previous Reaper Fights
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="number"
                required
                placeholder="Number of Reaper Fights"
                value={reaperFights}
                onChange={(event) => setReaperFights(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formUser">
            <Form.Label column sm={2}>
              User
            </Form.Label>
            <Col sm={10}>
              <UserDropDown
                users={users}
                userId={userId}
                setUserId={setUserId}
              />
            </Col>
          </Form.Group>
        </CharacterBasicsForm>
      </SubmissionForm>
    </FormPage>
  );
}

CreateCharacterForUserPage.loader = async () => {
  const races = await getRaces();
  const users = await getUsers();

  return { ...races, ...users };
};

CreateCharacterForUserPage.action = submissionActionFactory(
  postInitializeCharacter
);

export default CreateCharacterForUserPage;
