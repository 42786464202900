import React from "react";
import DisplayError from "../utils/errors/DisplayError";
import UnauthorizedError from "../utils/errors/UnauthorizedError";

export function fetchActionFactory(fetchFunction) {
  return async ({ request }) => {
    try {
      const body = JSON.parse((await request.formData()).get("body"));
      const res = await fetchFunction(body);
      let resBody = {};
      if (res.constructor.name === "Response") {
        if (res.status !== 204) {
          resBody = await res.json();
        }
      } else {
        resBody.data = res;
        res.status = 200;
      }
      if (res.status < 300 && res.status >= 200) {
        return { fetched: "true", data: resBody.data, date: new Date() };
      } else if (res.status < 600 && res.status >= 500) {
        return {
          fetched: "error",
          data: resBody.data,
          errorMessage: resBody.message,
          date: new Date(),
        };
      } else if (res.status < 500 && res.status >= 400) {
        return {
          fetched: "invalid",
          data: resBody.data,
          errorMessage: resBody.message,
          date: new Date(),
        };
      }
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        throw err;
      } else if (err instanceof DisplayError) {
        return {
          fetched: "error",
          errorMessage: err.message,
          date: new Date(),
        };
      } else {
        return { fetched: "error", date: new Date() };
      }
    }
  };
}

export function submitFetchFactory(data, args, fetcher) {
  return (event) => {
    event?.preventDefault();
    const body = JSON.stringify(data);

    fetcher.submit({ body }, args);
  };
}

export default function FetchComponent({
  fetcher,
  pendingMessage,
  fetchedMessage,
  errorMessage,
  invalidMessage,
  children,
}) {
  return (
    <div>
      {children}
      {fetcher.state === "submitting"
        ? pendingMessage
        : fetcher.data?.fetched === "true"
        ? fetchedMessage
        : fetcher.data?.fetched === "error"
        ? fetcher.data?.errorMessage || errorMessage
        : fetcher.data?.fetched === "invalid"
        ? fetcher.data?.errorMessage || invalidMessage
        : null}
    </div>
  );
}
