import Form from "react-bootstrap/Form";

import CharacterTable, { CharacterTableColumn } from "../CharacterTable";

export default function CharacterSelectionTable({
  selection,
  setSelection,
  allCharacters,
  showUsers,
  singleCharacter,
}) {
  const handleCheck = (event) => {
    let newSelection;
    if (singleCharacter) {
      if (event.target.checked) {
        newSelection = event.target.value;
      }
    } else {
      newSelection = [...selection];
      if (event.target.checked) {
        newSelection = [...newSelection, event.target.value];
      } else {
        newSelection.splice(selection.indexOf(event.target.value), 1);
      }
    }
    setSelection(newSelection);
  };

  return (
    <CharacterTable
      characters={allCharacters}
      showUsers={showUsers}
      leftColumns={[
        new CharacterTableColumn("Select", "center-align-column", (char) => (
          <Form.Check
            value={char._id}
            type={singleCharacter ? "radio" : "checkbox"}
            name={singleCharacter ? "character-select" : null}
            onChange={handleCheck}
            checked={
              singleCharacter
                ? selection === char._id
                : selection.includes(char._id)
            }
          />
        )),
      ]}
    />
  );
}
