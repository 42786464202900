import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import CharacterSelectionTable from "../../components/inputs/CharacterSelectionTable";
import SubmissionForm, {
  submitFormFactory,
  submissionActionFactory,
} from "../../components/SubmissionForm";

import { getCharacters, postCharacterDeaths } from "../../utils/apiInterface";
import FormPage from "../FormPage";

function ManageCharacterDeathsPage() {
  const submit = useSubmit();
  const { characters } = useLoaderData();

  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [deathCount, setDeathCount] = useState(0);
  const [reaperFightCount, setReaperFightCount] = useState(0);
  const [event, setEvent] = useState("");

  const handleSubmit = submitFormFactory(
    {
      character_id: selectedCharacter,
      death_count: deathCount,
      reaper_fight_count: reaperFightCount,
      event,
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_characters">
      <SubmissionForm
        onSubmit={handleSubmit}
        successMessage="Deaths counted successfully."
        pendingMessage="Please wait while the deaths are counted..."
        invalidMessage="There was a problem with your submission. Please try again."
        errorMessage="There was a problem with the server. Please try again later."
      >
        <h2>Character Deaths:</h2>
        <div className="mb-3">
          {" "}
          <CharacterSelectionTable
            allCharacters={characters}
            showUsers={true}
            selection={selectedCharacter}
            setSelection={setSelectedCharacter}
            singleCharacter={true}
          />
        </div>
        <Form.Group as={Row} className="mb-3" controlId="formDeathCount">
          <Form.Label column sm={2}>
            Deaths
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              required
              placeholder="Number of Deaths"
              value={deathCount}
              onChange={(event) => setDeathCount(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formReaperFights">
          <Form.Label column sm={2}>
            Reaper Fights
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              required
              placeholder="Number of Reaper Fights"
              value={reaperFightCount}
              onChange={(event) => setReaperFightCount(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formEvent">
          <Form.Label column sm={2}>
            Event
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              required
              placeholder="Event where the deaths occurred"
              value={event}
              onChange={(event) => setEvent(event.target.value)}
            />
          </Col>
        </Form.Group>
      </SubmissionForm>
    </FormPage>
  );
}

ManageCharacterDeathsPage.loader = getCharacters;

ManageCharacterDeathsPage.action = submissionActionFactory(postCharacterDeaths);

export default ManageCharacterDeathsPage;
