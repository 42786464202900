import React from "react";
import { Link, useLoaderData } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import { fetchActionFactory } from "../components/FetchComponent";
import PlayerCharacterEditor from "../components/characterSheets/PlayerCharacterEditor";

import {
  getSkills,
  getCurrentUserCharacter,
  saveCharacterBuild,
  commitCharacterBuild,
} from "../utils/apiInterface";

function UserCharacterEditorPage() {
  const { skills, character } = useLoaderData();

  return (
    <>
      <Link to="/">
        <Button className="back-button dark-button">Home</Button>
      </Link>
      <Container className="px-0 px-sm-3 " fluid>
        <PlayerCharacterEditor character={character} skills={skills} />
      </Container>
    </>
  );
}

UserCharacterEditorPage.loader = async ({ params }) => {
  const skills = await getSkills();

  const character = await getCurrentUserCharacter(params.character_id);

  return { ...character, ...skills };
};

UserCharacterEditorPage.saveAction = fetchActionFactory(saveCharacterBuild);

UserCharacterEditorPage.commitAction = fetchActionFactory(commitCharacterBuild);

export default UserCharacterEditorPage;
