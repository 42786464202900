import { useState } from "react";

import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import SkillSelectionCheckbox from "./SkillSelectionCheckbox";
import SkillSelectionCounter from "./SkillSelectionCounter";

import {
  characterHasSkill,
  getSkillPurchaseCount,
  displaySkillCostForLevel,
  increaseSkillPurchaseAmount,
  decreaseSkillPurchaseAmount,
  skillIsNecessary,
  skillIsGranted,
  skillIsGrantedBy,
  getSkillTotal,
  skillCostForLevel,
} from "character-build-helper";
import SkillGrantSelectionModal from "./SkillGrantSelectionModal";

export default function SkillPurchaseInput({
  characterBuild,
  setCharacterBuild,
  committedBuild,
  characterLevel,
  skill,
  options,
  allSkills,
  maxXp,
  prereqMet,
}) {
  const [openGrantModal, setOpenGrantModal] = useState(false);
  const [openNEXPModal, setOpenNEXPModal] = useState(false);

  const totalAmount = getSkillTotal(characterBuild, skill, options);
  const purchaseLevel = totalAmount + 1;

  const skillCost = displaySkillCostForLevel(
    skill,
    characterBuild,
    purchaseLevel,
    allSkills
  );

  let increaseToolTip;
  let canIncrease = false;
  if (skill.skill_max_purchases && purchaseLevel > skill.skill_max_purchases) {
    increaseToolTip = "Max skill level reached";
  } else if (!skillCost && skillCost !== 0) {
  } else if (!prereqMet) {
    increaseToolTip = skill.skill_prereq_description;
  } else {
    canIncrease = true;
  }

  let decreaseToolTip;
  let canDecrease = false;

  if (totalAmount < 1) {
  } else if (
    committedBuild &&
    getSkillPurchaseCount(characterBuild, skill, options) ===
      getSkillPurchaseCount(committedBuild, skill, options)
  ) {
    decreaseToolTip = "Cannot decrease below the committed amount";
  } else if (skillIsGranted(characterBuild, skill)) {
    decreaseToolTip =
      "This skill was granted by the following other skill(s): " +
      skillIsGrantedBy(characterBuild, skill).join(", ");
  } else if (
    skillIsNecessary(
      skill,
      characterLevel,
      characterBuild,
      options,
      null,
      null,
      allSkills
    )
  ) {
    // Find blocking skills
    decreaseToolTip =
      "This skill is a required prerequisite for one or more of the following: " +
      skill.prereq_of
        .filter((prereqSkill) =>
          characterHasSkill(characterBuild, prereqSkill, options)
        )
        .map((prereqSkill) => prereqSkill.skill_name)
        .join(", ");
  } else {
    canDecrease = true;
  }

  const purchase = () => {
    if (skill.skill_grants?.[0].variety) {
      setOpenGrantModal(true);
      return;
    }
    const updatedBuild = increaseSkillPurchaseAmount(
      characterBuild,
      skill,
      options,
      null,
      null,
      allSkills
    );

    if (updatedBuild.character_build_cost > maxXp) {
      setOpenNEXPModal(true);
    } else {
      setCharacterBuild(updatedBuild);
    }
  };

  const undoPurchase = () => {
    const updatedBuild = decreaseSkillPurchaseAmount(
      characterBuild,
      skill,
      options,
      null,
      null,
      allSkills
    );

    if (updatedBuild.character_build_cost > maxXp) {
      setOpenNEXPModal(true);
    } else {
      setCharacterBuild(updatedBuild);
    }
  };

  const purchaseWithGrantSelections = (selections) => {
    setOpenGrantModal(false);
    const updatedBuild = increaseSkillPurchaseAmount(
      characterBuild,
      skill,
      options,
      null,
      null,
      allSkills,
      [selections]
    );

    if (characterBuild.character_build_cost > maxXp) {
      setOpenNEXPModal(true);
    } else {
      setCharacterBuild(updatedBuild);
    }
  };

  const skillSelector = (selector) => {
    const cost = skillCost === 0 || skillCost ? <>{skillCost} XP</> : null;
    return (
      <>
        <SkillGrantSelectionModal
          characterLevel={characterLevel}
          characterBuild={characterBuild}
          skill={skill}
          options={options}
          setShow={setOpenGrantModal}
          show={openGrantModal}
          confirmSelection={purchaseWithGrantSelections}
        />
        <Modal show={openNEXPModal} onHide={() => setOpenNEXPModal(false)}>
          <Modal.Body>Not enough XP</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setOpenNEXPModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Col xs={3} md={2} xl={1}>
          {cost}
        </Col>
        <Col
          className="d-flex flex-row justify-content-center"
          xs={4}
          md={3}
          lg={1}
        >
          {selector}
        </Col>
      </>
    );
  };

  if (
    !skillCost &&
    skillCost !== 0 &&
    !skillCostForLevel(skill, characterBuild, 1)
  ) {
    return skillSelector(totalAmount);
  } else if (skill.skill_max_purchases === 1) {
    return skillSelector(
      <SkillSelectionCheckbox
        purchaseCount={totalAmount}
        increase={purchase}
        decrease={undoPurchase}
        increaseToolTip={increaseToolTip}
        decreaseToolTip={decreaseToolTip}
        canDecrease={canDecrease}
        canIncrease={canIncrease}
      />
    );
  } else {
    return skillSelector(
      <SkillSelectionCounter
        representation={totalAmount}
        increase={purchase}
        decrease={undoPurchase}
        increaseToolTip={increaseToolTip}
        decreaseToolTip={decreaseToolTip}
        canDecrease={canDecrease}
        canIncrease={canIncrease}
      />
    );
  }
}
