import CharacterSheetFactory from "./factory/CharacterSheetFactory";
import PlayerRuleset from "./rulesets/PlayerRuleset";

export default function PlayerCharacterEditor({ character, skills, children }) {
  return (
    <CharacterSheetFactory
      character={character}
      skills={skills}
      children={children}
      skin="player"
      mode="edit"
      ruleset={PlayerRuleset}
    />
  );
}
