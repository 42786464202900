import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import CharacterBasicsForm from "./CharacterBasicsForm";
import AttributeDropDown from "../inputs/AttributeDropDown";

export default function CharacterAttributesForm({
  committedBuild,
  savedBuild,
  characterName,
  setCharacterName,
  characterRace,
  setCharacterRace,
  characterClass,
  setCharacterClass,
  chosenAttribute,
  setChosenAttribute,
  races,
}) {
  return (
    <CharacterBasicsForm
      races={races}
      savedBuild={savedBuild}
      setCharacterName={setCharacterName}
      setCharacterRace={setCharacterRace}
      setCharacterClass={setCharacterClass}
      characterName={characterName}
      characterRace={characterRace}
      characterClass={characterClass}
      disableName={!!committedBuild?.character_name}
      disableRace={!!committedBuild?.character_race.race}
      disableClass={!!committedBuild?.character_class}
    >
      <Form.Group as={Row} className="mb-3" controlId="formRaceAttributes">
        <Form.Label column sm={2}>
          Racial Attributes
        </Form.Label>
        <Col sm={10}>
          <AttributeDropDown
            race={characterRace}
            savedBuild={savedBuild}
            chosenAttribute={chosenAttribute}
            setChosenAttribute={setChosenAttribute}
          />
        </Col>
      </Form.Group>
    </CharacterBasicsForm>
  );
}
