class InsufficientPermissionsError extends Error {
  constructor(message) {
    super(message);

    this.isOperational = true;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export default InsufficientPermissionsError;
