import { useEffect, useState } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  getCharacterSkill,
  decreaseSkillPurchaseAmount,
  getSkillPurchaseCount,
  increaseSkillPurchaseAmount,
  displaySkillCostForLevel,
  getAlchemySpecialtyOptions,
  setAlchemySpecialty,
  pyramidLevelCanDecrease,
  pyramidLevelCanIncrease,
  checkPrerequisites,
  skillIsNecessary,
  removeLastAlchemySpecialty,
  getSpecialtyPyramid,
  characterHasSkill,
} from "character-build-helper";

import SkillSelectionCounter from "./SkillSelectionCounter";

export default function AlchemySpecialtySelector({
  characterBuild,
  setCharacterBuild,
  committedBuild,
  characterLevel,
  skill,
  options,
  specialtyIndex,
  allSkills,
  maxXp,
}) {
  const characterSkill = getCharacterSkill(characterBuild, skill);

  const [openNEXPModal, setOpenNEXPModal] = useState(false);

  const specialtyOptions = getAlchemySpecialtyOptions(
    characterBuild,
    skill,
    specialtyIndex
  );

  const displaySpecialtySelector =
    getSkillPurchaseCount(characterBuild, skill, null, 3, specialtyIndex) >=
      2 &&
    (specialtyIndex === 0 ||
      getSkillPurchaseCount(
        characterBuild,
        skill,
        null,
        6,
        specialtyIndex - 1
      ) >= 3);

  useEffect(() => {
    if (
      displaySpecialtySelector &&
      specialtyOptions.length === 1 &&
      getSkillPurchaseCount(characterBuild, skill, null, 4, specialtyIndex) >=
        1 &&
      specialtyOptions[0] !== characterSkill.specialties[specialtyIndex]
    ) {
      setSpecialty(specialtyOptions[0]);
    }
  });

  if (
    specialtyIndex !== 0 &&
    getSkillPurchaseCount(characterBuild, skill, null, 3, specialtyIndex - 1) <
      2
  ) {
    return null;
  }

  const setSpecialty = (value) =>
    setCharacterBuild(
      setAlchemySpecialty(characterBuild, skill, specialtyIndex, value)
    );

  const committedSpecialty =
    committedBuild &&
    getCharacterSkill(committedBuild, skill).specialties[specialtyIndex];

  let specialtySelector = () => {
    if (displaySpecialtySelector) {
      return (
        <span>
          <Form.Select
            className="text-capitalize"
            onChange={(event) => setSpecialty(event.target.value)}
            disabled={specialtyIndex === 2 || committedSpecialty}
            style={
              specialtyIndex === 2 || committedSpecialty
                ? { pointerEvents: "none" }
                : {}
            }
            value={characterSkill.specialties[specialtyIndex] || ""}
          >
            {getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              4,
              specialtyIndex
            ) === 0 ? (
              <option value={""}>
                {specialtyIndex === 2 ? "Automatic" : "Not selected"}
              </option>
            ) : null}
            {specialtyOptions?.map((opt) => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
          </Form.Select>
        </span>
      );
    }
  };

  return (
    <>
      <Form.Group className="my-2" as={Row}>
        <Col sm={5} md={3} xxl={2}>
          <Form.Label>Specialty {specialtyIndex + 1}:</Form.Label>
        </Col>
        <Col sm="auto">
          {committedSpecialty ? (
            <OverlayTrigger
              placement={"top"}
              overlay={<Tooltip>Alchemy Specialty committed</Tooltip>}
            >
              {specialtySelector()}
            </OverlayTrigger>
          ) : (
            specialtySelector()
          )}
        </Col>
      </Form.Group>
      <Modal show={openNEXPModal} onHide={() => setOpenNEXPModal(false)}>
        <Modal.Body>Not enough XP</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenNEXPModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {[...Array(6)].map((_, index) => {
        const level = index + 1;

        const skillCost = displaySkillCostForLevel(
          skill,
          characterBuild,
          level,
          allSkills
        );

        const purchaseCount = getSkillPurchaseCount(
          characterBuild,
          skill,
          null,
          level,
          specialtyIndex
        );

        const purchaseSlot = () => {
          let updatedBuild = characterBuild;
          if (
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              1,
              specialtyIndex
            ) === 0
          ) {
            updatedBuild = setAlchemySpecialty(
              updatedBuild,
              skill,
              specialtyIndex,
              null
            );
          }
          updatedBuild = increaseSkillPurchaseAmount(
            updatedBuild,
            skill,
            null,
            level,
            specialtyIndex,
            allSkills
          );

          if (updatedBuild.character_build_cost > maxXp) {
            setOpenNEXPModal(true);
          } else {
            setCharacterBuild(updatedBuild);
          }
        };
        const undoPurchaseSlot = () => {
          let updatedBuild = characterBuild;
          updatedBuild = decreaseSkillPurchaseAmount(
            updatedBuild,
            skill,
            null,
            level,
            specialtyIndex,
            allSkills
          );
          if (
            (level === 4 && purchaseCount === 1) ||
            (level === 3 && purchaseCount === 2)
          ) {
            updatedBuild = setAlchemySpecialty(
              updatedBuild,
              skill,
              specialtyIndex,
              null
            );
          } else if (
            getSkillPurchaseCount(
              updatedBuild,
              skill,
              null,
              1,
              specialtyIndex
            ) === 0
          ) {
            updatedBuild = removeLastAlchemySpecialty(updatedBuild, skill);
          }

          if (updatedBuild.character_build_cost > maxXp) {
            setOpenNEXPModal(true);
          } else {
            setCharacterBuild(updatedBuild);
          }
        };

        let canIncrease = false;
        let increaseToolTip;
        if (!checkPrerequisites(characterLevel, characterBuild, skill, 1)) {
          increaseToolTip = skill.skill_prereq_description;
        } else if (
          !(
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              level,
              specialtyIndex
            ) <= 2 ||
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              4,
              specialtyIndex
            ) >= 1
          )
        ) {
          increaseToolTip =
            "Must purchase a 4th level slot before more than three can be purchased";
        } else if (
          !pyramidLevelCanIncrease(
            getSpecialtyPyramid(characterBuild, skill, specialtyIndex),
            level
          )
        ) {
        } else if (
          !(
            level !== 4 ||
            specialtyIndex === 0 ||
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              6,
              specialtyIndex - 1
            ) >= 3
          )
        ) {
          increaseToolTip =
            "To buy a 4th level slot, the previous specialty must have a complete three-column";
        } else if (
          !(
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              3,
              specialtyIndex
            ) < 2 ||
            characterSkill.specialties[specialtyIndex] ||
            specialtyIndex === 2
          )
        ) {
          increaseToolTip =
            "Must choose a specialty to purchase a 4th level slot";
        } else if (
          !(
            specialtyIndex === 0 ||
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              3,
              specialtyIndex - 1
            ) >= 2
          )
        ) {
          increaseToolTip =
            "Must purchase two 3's of the previous specialty to purchase slots";
        } else {
          canIncrease = true;
        }

        let canDecrease = false;
        let decreaseToolTip = false;
        if (
          getSkillPurchaseCount(
            characterBuild,
            skill,
            null,
            level,
            specialtyIndex
          ) === 0
        ) {
        } else if (
          committedBuild &&
          getSkillPurchaseCount(
            characterBuild,
            skill,
            null,
            level,
            specialtyIndex
          ) ===
            getSkillPurchaseCount(
              committedBuild,
              skill,
              null,
              level,
              specialtyIndex
            )
        ) {
          decreaseToolTip = "Cannot decrease below committed amount";
        } else if (
          !pyramidLevelCanDecrease(
            getSpecialtyPyramid(characterBuild, skill, specialtyIndex),
            level
          )
        ) {
        } else if (
          specialtyIndex !== 2 &&
          characterSkill.specialties[specialtyIndex + 1]
        ) {
          decreaseToolTip =
            "Cannot decrease because a complete 3-column is required for futher specialties";
        } else if (
          !(
            specialtyIndex === 2 ||
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              4,
              specialtyIndex
            ) !== 0 ||
            (getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              3,
              specialtyIndex
            ) <= 2 &&
              getSkillPurchaseCount(
                characterBuild,
                skill,
                null,
                1,
                specialtyIndex + 1
              ) === 0)
          )
        ) {
          decreaseToolTip =
            "Must reduce further specialties to 0 before decreasing below two 3rd level slots";
        } else if (
          skillIsNecessary(
            skill,
            characterLevel,
            characterBuild,
            options,
            level,
            specialtyIndex,
            allSkills
          )
        ) {
          decreaseToolTip =
            "This slot is a required prerequisite for one or more of the following: " +
            skill.prereq_of
              .filter((prereqSkill) =>
                characterHasSkill(characterBuild, prereqSkill, options)
              )
              .map((prereqSkill) => prereqSkill.skill_name)
              .join(", ");
        } else {
          canDecrease = true;
        }

        return (
          <Row className="mb-2" key={index}>
            <Col xs={4} sm={3} md={2} xxl={1}>
              Slot Level: {level}
            </Col>
            <Col xs={2} md={1}>
              {skillCost} XP
            </Col>
            <Col>
              <SkillSelectionCounter
                representation={[...Array(purchaseCount)].map((_, index) => (
                  <span key={index}>
                    <span className="slot-card">{level}</span>
                  </span>
                ))}
                increase={purchaseSlot}
                decrease={undoPurchaseSlot}
                increaseToolTip={increaseToolTip}
                decreaseToolTip={decreaseToolTip}
                canIncrease={canIncrease}
                canDecrease={canDecrease}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
}
