import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  getCharacterSkill,
  getSkillPurchaseCount,
} from "character-build-helper";

export default function SlotPyramidDisplay({
  characterBuild,
  skill,
  specialtyListName,
  specialtyName,
}) {
  return (
    <div className="skill">
      <Row>
        <OverlayTrigger
          placement={"top"}
          overlay={<Tooltip>{skill.skill_description}</Tooltip>}
        >
          <span className="bold-skill-name">{skill.skill_name}</span>
        </OverlayTrigger>
      </Row>
      <Row>
        {[...Array(3)].map((_, specialtyIndex) => {
          const characterSkill = getCharacterSkill(characterBuild, skill);

          if (
            getSkillPurchaseCount(
              characterBuild,
              skill,
              null,
              1,
              specialtyIndex
            ) === 0
          ) {
            return null;
          }

          return (
            <Col
              className="text-capitalize d-flex flex-column align-items-center"
              key={specialtyIndex}
            >
              {specialtyName} {specialtyIndex + 1}:{" "}
              {characterSkill[specialtyListName][specialtyIndex]?.replaceAll(
                "_",
                " "
              ) || "Not selected"}
              <div>
                {[...Array(characterSkill.levels[specialtyIndex].length)].map(
                  (_, index) => {
                    const level = index + 1;

                    const purchaseCount = getSkillPurchaseCount(
                      characterBuild,
                      skill,
                      null,
                      level,
                      specialtyIndex
                    );

                    return (
                      <Row key={level} className="p-1">
                        <Col>
                          {[...Array(purchaseCount)].map((_, index) => (
                            <span className="slot-card" key={index}>
                              {level}
                            </span>
                          ))}
                        </Col>
                      </Row>
                    );
                  }
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
