import { useState } from "react";
import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";

import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../FetchComponent";
import ConfirmationModal from "../ConfirmationModal";

import { publishEvent } from "../../utils/apiInterface";

export default function PublishEventButton({ event }) {
  const [modalOpen, setModalOpen] = useState(false);
  const publishFetcher = useFetcher();

  const publishEvent = submitFetchFactory(
    { event_id: event._id },
    {
      method: "post",
      action: `/staff/manage_events/publish_event`,
    },
    publishFetcher
  );

  const confirmationDialog = (
    <div>
      Are you sure you would like to publish this event? This will allow users
      to see the event and register.
    </div>
  );

  return (
    <FetchComponent
      fetcher={publishFetcher}
      errorMessage="Something went wrong. Please try again later."
      invalidMessage="Something went wrong. Please reload the page and try again."
    >
      <Button
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Publish
      </Button>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onConfirm={publishEvent}
        confirmationButtonText="Publish"
        allowConfirm={true}
      >
        {confirmationDialog}
      </ConfirmationModal>
    </FetchComponent>
  );
}

PublishEventButton.publishEventAction = fetchActionFactory(publishEvent);
