import { useState } from "react";
import { Link, useLoaderData, useSubmit } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
  getSkills,
  getTransformations,
  getUserCharacter,
  patchSecretOptions,
} from "../../utils/apiInterface";
import { getSecretOptions } from "../../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../../components/SubmissionForm";
import ConfirmationModal from "../../components/ConfirmationModal";
import AdminCharacterEditor from "../../components/characterSheets/AdminCharacterEditor";

function CharacterSheetPage() {
  const { skills, transformations, character, secret_options } =
    useLoaderData();

  const [additionalSpellSchools, setAdditionalSpellSchools] = useState(
    character.additional_spell_schools || []
  );
  const [openSubmitModal, setOpenSubmitModal] = useState(false);
  const [transformation, setTransformation] = useState(
    transformations.find(
      (trans) =>
        trans.transformation_name ===
        character.saved_build.character_race.transformation
    )?._id || ""
  );

  const submit = useSubmit();

  const submitSecretOptions = (event) => {
    let data = {
      user_id: character.user._id,
      character_id: character._id,
      additional_spell_schools: additionalSpellSchools,
    };
    if (
      transformation &&
      transformations.find((trans) => trans._id === transformation)
        .transformation_name !==
        character.saved_build.character_race.transformation
    ) {
      data.set_transformation = transformation;
    }
    submitFormFactory(data, submit, { method: "patch" })(event);
  };

  const handleSpellSchoolCheck = (event) => {
    let newList = [...additionalSpellSchools];
    if (event.target.checked) {
      newList = [...newList, event.target.value];
    } else {
      newList.splice(additionalSpellSchools.indexOf(event.target.value), 1);
    }
    setAdditionalSpellSchools(newList);
  };

  return (
    <>
      <Link to="/staff/manage_characters">
        <Button className="back-button dark-button">Back</Button>
      </Link>
      <Container fluid>
        <AdminCharacterEditor character={character} skills={skills}>
          <div className="box" style={{ backgroundColor: "gray" }}>
            <SubmissionForm
              onSubmit={(event) => {
                event.preventDefault();
                setOpenSubmitModal(true);
              }}
              submitLabel="Update"
              pendingMessage="Please wait while your changes are submitted..."
              successMessage="Changes submitted!"
              errorMessage="Error submitting changes. Please try again later."
              invalidMessage="Invalid request. Please try again."
            >
              <div>Additional Spell Schools:</div>
              {secret_options.additional_spell_schools.map((school) => (
                <Form.Group as={Row} controlId="formPrivacy" key={school}>
                  <Col xs="auto">
                    <Form.Check
                      type="checkbox"
                      value={school}
                      checked={additionalSpellSchools.includes(school)}
                      onChange={handleSpellSchoolCheck}
                      disabled={character.additional_spell_schools?.includes(
                        school
                      )}
                    />
                  </Col>
                  <Col>
                    <Form.Label className="text-capitalize">
                      {school.replaceAll("_", " ")}
                    </Form.Label>
                  </Col>
                </Form.Group>
              ))}
              <div>Transform Character:</div>
              <Form.Group as={Row}>
                <Col xs="auto">
                  <Form.Check
                    type="radio"
                    value=""
                    checked={!transformation}
                    disabled={
                      character.saved_build.character_race.transformation
                    }
                    onChange={() => setTransformation("")}
                  />
                </Col>
                <Col>
                  <Form.Label className="text-capitalize">None</Form.Label>
                </Col>
              </Form.Group>
              {transformations.map((trans_type) => (
                <Form.Group as={Row} key={trans_type._id}>
                  <Col xs="auto">
                    <Form.Check
                      type="radio"
                      value={trans_type._id}
                      checked={transformation === trans_type._id}
                      onChange={() => setTransformation(trans_type._id)}
                    />
                  </Col>
                  <Col>
                    <Form.Label className="text-capitalize">
                      {trans_type.transformation_name}
                    </Form.Label>
                  </Col>
                </Form.Group>
              ))}
              <ConfirmationModal
                modalOpen={openSubmitModal}
                setModalOpen={setOpenSubmitModal}
                onConfirm={submitSecretOptions}
                allowConfirm={true}
              >
                <ul>
                  {additionalSpellSchools.length ? (
                    <li>Enabling a spell school cannot be reversed</li>
                  ) : null}
                  {transformation &&
                  transformations.find((trans) => trans._id === transformation)
                    .transformation_name !==
                    character.saved_build.character_race.transformation ? (
                    <li>
                      Transforming a character will result in all their skills
                      being deleted. Even if that character has been committed.
                    </li>
                  ) : null}
                </ul>
              </ConfirmationModal>
            </SubmissionForm>
          </div>
        </AdminCharacterEditor>
      </Container>
    </>
  );
}

CharacterSheetPage.loader = async ({ params }) => {
  const skills = await getSkills();
  const transformations = await getTransformations();

  const character = await getUserCharacter(params.user_id, params.character_id);

  const secret_options = await getSecretOptions();

  return { ...character, ...skills, ...transformations, ...secret_options };
};

CharacterSheetPage.action = submissionActionFactory(patchSecretOptions);

export default CharacterSheetPage;
