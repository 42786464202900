import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function PrintCharacterViewerHeader({
  character,
  displayBuild,
}) {
  const buildAttrs = displayBuild.character_race.race_attributes;

  return (
    <>
      <span className="title">{displayBuild.character_name}:</span>
      <div className="box">
        <Row>
          <Col>
            <div>
              Race:{" "}
              <span className="text-capitalize">
                {[
                  displayBuild.character_race.subculture,
                  displayBuild.character_race.race,
                  displayBuild.character_race.transformation,
                ].join(" ")}
              </span>
            </div>
            <div>
              Class:{" "}
              <span className="text-capitalize">
                {displayBuild.character_class}
              </span>
            </div>
            <div>
              Attributes:{" "}
              <span className="text-capitalize">
                {buildAttrs
                  ? buildAttrs.length === 0
                    ? "None"
                    : buildAttrs.join(", ")
                  : "Not selected"}
              </span>
            </div>
          </Col>
          <Col>
            <div>
              XP Spent: {displayBuild.character_build_cost}/
              {character.character_xp_total} XP
            </div>
            <div>Deaths: {character.character_deaths.death_count}</div>
            <div>
              Reaper Fights: {character.character_deaths.reaper_fight_count}
            </div>
          </Col>
        </Row>
        <div className="mt-2">
          <div>
            {character.committed_build
              ? null
              : "This character has not been committed. Displaying the last saved build."}
          </div>
          <div>
            {character.committed_build ? "Committed" : "Saved"}:{" "}
            {new Date(displayBuild.created_at).toLocaleString()}
          </div>
        </div>
        <Row className="mt-2">
          <Col>
            Player: {character.user.first_name} {character.user.last_name}
          </Col>
          <Col>Shards: {character.user.shard_total}</Col>
        </Row>
      </div>
    </>
  );
}
