import React, { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import FetchComponent, { submitFetchFactory } from "../../FetchComponent";
import { convertToWorkingBuild } from "character-build-helper";

import ConfirmationModal from "../../ConfirmationModal";

import { useFetcher } from "react-router-dom";

import AlchemyPurchaseInput from "../skillInputs/AlchemyPurchaseInput";
import SpellSlotPurchaseInput from "../skillInputs/SpellSlotPurchaseInput";
import SkillPurchaseInput from "../skillInputs/SkillPurchaseInput";
import CharacterSheet from "./CharacterSheet";

export default function EditableCharacterSheet({
  characterSheetStyleClass,
  character,
  skills,
  children,
  mode,
}) {
  const [workingBuild, setWorkingBuild] = useState(
    convertToWorkingBuild(character.saved_build)
  );

  const [commitModalOpen, setCommitModalOpen] = useState();
  const saveFetcher = useFetcher();
  const commitFetcher = useFetcher();

  const saveCharacter = submitFetchFactory(
    {
      charId: character._id,
      character_build: workingBuild,
    },
    { method: "post", action: `/character_editor/${character._id}/save` },
    saveFetcher
  );
  const commitCharacter = submitFetchFactory(
    {
      charId: character._id,
      character_build: workingBuild,
    },
    { method: "post", action: `/character_editor/${character._id}/commit` },
    commitFetcher
  );

  const committedBuild = character.committed_build
    ? convertToWorkingBuild(character.committed_build)
    : null;

  const characterInitialized =
    character.saved_build.character_race.race_attributes;

  return (
    <CharacterSheet
      characterSheetStyleClass={characterSheetStyleClass}
      character={character}
      skills={skills}
      children={children}
      mode={mode}
      characterBuild={workingBuild}
      renderAlchemyPyramidDisplay={(skill) => (
        <Row className="align-items-center skill">
          <Col>
            <AlchemyPurchaseInput
              characterBuild={workingBuild}
              setCharacterBuild={setWorkingBuild}
              committedBuild={committedBuild}
              characterLevel={character.character_level}
              skill={skill}
              allSkills={skills}
              maxXp={character.character_xp_total}
            />
          </Col>
        </Row>
      )}
      renderSpellPyramidDisplay={(skill) => (
        <Row className="align-items-center skill">
          <Col>
            <SpellSlotPurchaseInput
              characterBuild={workingBuild}
              setCharacterBuild={setWorkingBuild}
              committedBuild={committedBuild}
              characterLevel={character.character_level}
              skill={skill}
              allSkills={skills}
              maxXp={character.character_xp_total}
              additionalSchools={character.additional_spell_schools}
            />
          </Col>
        </Row>
      )}
      renderAmountDisplay={(skill, options, prereqMet) => (
        <SkillPurchaseInput
          characterBuild={workingBuild}
          setCharacterBuild={setWorkingBuild}
          committedBuild={committedBuild}
          characterLevel={character.character_level}
          skill={skill}
          options={options}
          allSkills={skills}
          maxXp={character.character_xp_total}
          prereqMet={prereqMet}
        />
      )}
    >
      {characterInitialized ? (
        <div className="top-right-corner-box">
          <div>Character Level: {character.character_level}</div>
          <div>Total XP: {character.character_xp_total}</div>
          <div>Current Build Cost: {workingBuild.character_build_cost}</div>
        </div>
      ) : null}
      {children}
      <Row>
        <Col>
          <FetchComponent
            fetcher={saveFetcher}
            pendingMessage="Saving character..."
            errorMessage="Error submitting character. Please try again later."
            invalidMessage="Invalid data. Please try again."
            fetchedMessage="Successfully saved character."
          >
            <Button className="mb-2" onClick={saveCharacter}>
              Save
            </Button>
          </FetchComponent>
          <FetchComponent
            fetcher={commitFetcher}
            pendingMessage="Committing character..."
            errorMessage="Error comitting character. Please try again later."
            invalidMessage="Invalid data. Please try again."
            fetchedMessage="Successfully committed character."
          >
            <ConfirmationModal
              modalOpen={commitModalOpen}
              setModalOpen={setCommitModalOpen}
              onConfirm={commitCharacter}
              allowConfirm={true}
              confirmationButtonText="Commit"
            >
              Once your build is committed, you cannot go back!
              <br />
              Are you sure you want to proceed?
            </ConfirmationModal>
            <Button className="mb-2" onClick={() => setCommitModalOpen(true)}>
              Commit
            </Button>
          </FetchComponent>
        </Col>
      </Row>
    </CharacterSheet>
  );
}
