import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";

import AuthenticatedPageTemplate from "./pages/AuthenticatedPageTemplate";
import ErrorPage from "./pages/ErrorPage";
import UserHomePage from "./pages/UserHomePage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import NewCharacterPage from "./pages/NewCharacterPage";
import UserCharacterEditorPage from "./pages/UserCharacterEditorPage";
import UpdateCharacterAttributesPage from "./pages/UpdateCharacterAttributesPage";
import StaffPageTemplate from "./pages/adminPages/StaffPageTemplate";
import ManageCharactersPage from "./pages/adminPages/ManageCharactersPage";
import ManageEventsPage from "./pages/adminPages/ManageEventsPage";
import CreateEventPage from "./pages/adminPages/CreateEventPage";
import CreateCharacterForUserPage from "./pages/adminPages/CreateCharacterForUserPage";
import CharacterSheetPage from "./pages/adminPages/CharacterSheetPage";
import ManageCharacterXpPage from "./pages/adminPages/ManageCharacterXpPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AccountPage from "./pages/AccountPage";
import VerifyPage from "./pages/VerifyPage";
import PrintCharacterSheetsPage from "./pages/adminPages/PrintCharacterSheetsPage";
import SelectCharacterSheetsPage from "./pages/adminPages/SelectCharacterSheetsPage";
import ManageUsersPage from "./pages/adminPages/ManageUsersPage";
import ManagePastEventCountsPage from "./pages/adminPages/ManagePastEventCountsPage";
import ManageUserShardsPage from "./pages/adminPages/ManageUserShardsPage";
import ManageUserRolesPage from "./pages/adminPages/ManageUserRolesPage";
import ManageCharacterDeathsPage from "./pages/adminPages/ManageCharacterDeathsPage";
import UpdateDefaultRegistrationQuestionsPage from "./pages/adminPages/UpdateDefaultRegistrationQuestionsPage";
import EditEventPage from "./pages/adminPages/EditEventPage";
import EventsPage from "./pages/EventsPage";
import EventRegistrationPage from "./pages/EventRegistrationPage";
import ManageEventRegistrationsPage from "./pages/adminPages/ManageEventRegistrationsPage";
import EditEventRegistrationPage from "./pages/EditEventRegistrationPage";
import PostEventWorkflowPage from "./pages/adminPages/PostEventWorkflowPage";
import UpdateSurveyQuestionsPage from "./pages/adminPages/UpdateSurveyQuestionsPage";
import SurveyPage from "./pages/SurveyPage";
import EditSurveyPage from "./pages/EditSurveyPage";

import HomeNavBar from "./pages/HomeNavBar";

import AdminLinks from "./components/AdminLinks";
import SendVerifyComponent from "./components/SendVerifyComponent";

import CancelRegistrationButton from "./components/buttons/CancelRegistrationButton";
import DeleteCharacterButton from "./components/buttons/DeleteCharacterButton";
import DeleteEventButton from "./components/buttons/DeleteEventButton";
import UncommitCharacterButton from "./components/buttons/UncommitCharacterButton";
import PublishEventButton from "./components/buttons/PublishEventButton";

import reportWebVitals from "./reportWebVitals";

import "./scss/App.scss";
import "./scss/FormPage.scss";
import "./scss/HomeNavBar.scss";
import "./scss/CharacterSheet.scss";
import "./scss/PrintCharacterSheet.scss";
import "./scss/ManageEventRegistrations.scss";

function elementLoaderActions(Page) {
  const ob = { element: <Page /> };
  if (Page.action) {
    ob["action"] = Page.action;
  }
  if (Page.loader) {
    ob["loader"] = Page.loader;
  }
  return ob;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/login",
        ...elementLoaderActions(LoginPage),
      },
      {
        path: "/forgot_password",
        ...elementLoaderActions(ForgotPasswordPage),
      },
      {
        path: "/reset_password/:token",
        ...elementLoaderActions(ResetPasswordPage),
      },
      {
        path: "/resend_verification",
        action: SendVerifyComponent.sendAction,
      },
      {
        path: "/verify/:token",
        ...elementLoaderActions(VerifyPage),
      },
      {
        path: "/signup",
        ...elementLoaderActions(SignupPage),
      },
      {
        ...elementLoaderActions(AuthenticatedPageTemplate),
        children: [
          {
            ...elementLoaderActions(HomeNavBar),
            children: [
              {
                index: true,
                ...elementLoaderActions(UserHomePage),
              },
              {
                path: "shard_purchase",
                action: UserHomePage.shardPurchaseAction,
              },
              {
                path: "/account",
                ...elementLoaderActions(AccountPage),
                children: [
                  {
                    path: "update_password",
                    action: AccountPage.updatePasswordAction,
                  },
                ],
              },
              { path: "/events", ...elementLoaderActions(EventsPage) },
            ],
          },
          {
            path: "/new_character",
            ...elementLoaderActions(NewCharacterPage),
          },
          {
            path: "/character_editor/:character_id",
            ...elementLoaderActions(UserCharacterEditorPage),
            children: [
              {
                path: "save",
                action: UserCharacterEditorPage.saveAction,
              },
              {
                path: "commit",
                action: UserCharacterEditorPage.commitAction,
              },
            ],
          },
          {
            path: "/update_character/:character_id",
            ...elementLoaderActions(UpdateCharacterAttributesPage),
          },
          {
            path: "/delete_character",
            action: DeleteCharacterButton.deleteCharacterAction,
          },
          {
            path: "/delete_user_character",
            action: DeleteCharacterButton.deleteUserCharacterAction,
          },
          {
            path: "/event_registration/:event_id",
            ...elementLoaderActions(EventRegistrationPage),
          },
          {
            path: "/event_registration/:event_registration_id/edit",
            ...elementLoaderActions(EditEventRegistrationPage),
          },
          {
            path: "/event/:event_id/survey",
            ...elementLoaderActions(SurveyPage),
          },
          {
            path: "/event/:event_id/survey/edit",
            ...elementLoaderActions(EditSurveyPage),
          },
          {
            path: "/cancel_event_registration",
            action: CancelRegistrationButton.cancelEventRegistrationAction,
          },
          {
            path: "/staff/manage_events/:event_id/registrations",
            ...elementLoaderActions(ManageEventRegistrationsPage),
          },
          {
            path: "/staff",
            ...elementLoaderActions(StaffPageTemplate),
            children: [
              { index: true, element: <AdminLinks /> },
              {
                ...elementLoaderActions(HomeNavBar),
                children: [
                  {
                    path: "manage_characters",
                    ...elementLoaderActions(ManageCharactersPage),
                    children: [
                      {
                        path: "uncommit_character",
                        action: UncommitCharacterButton.uncommitCharacterAction,
                      },
                    ],
                  },
                  {
                    path: "manage_users",
                    ...elementLoaderActions(ManageUsersPage),
                  },
                  {
                    path: "manage_events",
                    ...elementLoaderActions(ManageEventsPage),
                    children: [
                      {
                        path: "delete_event",
                        action: DeleteEventButton.deleteEventAction,
                      },
                      {
                        path: "publish_event",
                        action: PublishEventButton.publishEventAction,
                      },
                    ],
                  },
                ],
              },
              {
                path: "initialize_character",
                ...elementLoaderActions(CreateCharacterForUserPage),
              },
              {
                path: "manage_xp",
                ...elementLoaderActions(ManageCharacterXpPage),
              },
              {
                path: "manage_deaths",
                ...elementLoaderActions(ManageCharacterDeathsPage),
              },
              {
                path: "manage_past_event_counts",
                ...elementLoaderActions(ManagePastEventCountsPage),
              },
              {
                path: "select_character_sheets",
                ...elementLoaderActions(SelectCharacterSheetsPage),
              },
              {
                path: "print_character_sheets",
                ...elementLoaderActions(PrintCharacterSheetsPage),
              },
              {
                path: "users/:user_id/character_sheet/:character_id",
                ...elementLoaderActions(CharacterSheetPage),
              },
              {
                path: "manage_shards",
                ...elementLoaderActions(ManageUserShardsPage),
              },
              {
                path: "manage_roles",
                ...elementLoaderActions(ManageUserRolesPage),
              },
              {
                path: "create_event",
                ...elementLoaderActions(CreateEventPage),
              },
              {
                path: "edit_event/:event_id",
                ...elementLoaderActions(EditEventPage),
              },
              {
                path: "update_default_registration_questions",
                ...elementLoaderActions(UpdateDefaultRegistrationQuestionsPage),
              },
              {
                path: "update_survey_questions",
                ...elementLoaderActions(UpdateSurveyQuestionsPage),
              },
              {
                path: "manage_events/:event_id/registrations",
                children: [
                  {
                    path: "get_characters_by_ids",
                    action: ManageEventRegistrationsPage.fetchCharactersAction,
                  },
                  {
                    path: "confirm_registrations",
                    action: ManageEventRegistrationsPage.confirmRegAction,
                  },
                ],
              },
              {
                path: "manage_events/:event_id/post_event",
                ...elementLoaderActions(PostEventWorkflowPage),
              },
            ],
          },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
