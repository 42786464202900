import React, { useState } from "react";
import { Link, useFetcher, useLoaderData } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/esm/Modal";
import Form from "react-bootstrap/Form";

import {
  getCurrentUserCharacters,
  getShardInfo,
  makeShardPurchase,
} from "../utils/apiInterface";
import CharacterTable, {
  CharacterTableColumn,
} from "../components/CharacterTable";
import ConfirmationModal from "../components/ConfirmationModal";
import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../components/FetchComponent";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function UserHomePage() {
  const { characters, shards, xp_token_purchased } = useLoaderData();

  const xpTokenFetcher = useFetcher();

  const [xpTokenModalOpen, setXpTokenModalOpen] = useState(false);
  const [openNESModal, setOpenNESModal] = useState(false);
  const [xpTokenCharacter, setXpTokenCharacter] = useState(null);

  const submitXpTokenPurchase = (event) => {
    setXpTokenCharacter(null);
    return submitFetchFactory(
      { purchase: "xp_token", options: { character: xpTokenCharacter?._id } },
      {
        method: "post",
        action: `/shard_purchase`,
      },
      xpTokenFetcher
    )(event);
  };

  const onClickXpToken = () => {
    if (shards < 3) {
      setOpenNESModal(true);
    } else {
      setXpTokenModalOpen(true);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h1>Characters:</h1>
          {characters.length ? (
            <CharacterTable
              characters={characters}
              rightColumns={[
                new CharacterTableColumn(
                  "Edit",
                  "center-align-column",
                  (char) => (
                    <Link to={`/character_editor/${char._id}`}>
                      <Button>Edit</Button>
                    </Link>
                  )
                ),
              ]}
            />
          ) : null}
          <Link to={`/new_character`}>
            <Button>Create new character</Button>
          </Link>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h1>Shards:</h1>
          <div className="mb-3">Current Balance: {shards} Shards</div>
          <Button onClick={onClickXpToken} disabled={xp_token_purchased}>
            Purchase XP Token (3 Shards)
          </Button>
          <ConfirmationModal
            modalOpen={xpTokenModalOpen}
            setModalOpen={setXpTokenModalOpen}
            allowConfirm={xpTokenCharacter}
            onConfirm={submitXpTokenPurchase}
          >
            <FetchComponent
              fetcher={xpTokenFetcher}
              pendingMessage="Submitting purchase..."
              errorMessage="Error occurred, unable to complete purchase."
              invalidMessage="Invalid request."
            >
              <Form.Group>
                <Form.Label>
                  {characters.length
                    ? "Choose Character"
                    : "No eligible characters"}
                  :
                </Form.Label>
                <Form.Select
                  onChange={(event) =>
                    setXpTokenCharacter(
                      characters.find(({ _id }) => _id === event.target.value)
                    )
                  }
                  value={xpTokenCharacter?._id || ""}
                >
                  <option disabled value={""}>
                    --Choose Character--
                  </option>
                  {characters.map((character) => (
                    <option key={character._id} value={character._id}>
                      {character.saved_build.character_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </FetchComponent>
          </ConfirmationModal>
          <Modal show={openNESModal} onHide={() => setOpenNESModal(false)}>
            <Modal.Body>Not enough Shards</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setOpenNESModal(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

UserHomePage.loader = async () => {
  const { characters } = await getCurrentUserCharacters();
  const shard_info = await getShardInfo();
  return { characters, ...shard_info };
};

UserHomePage.shardPurchaseAction = fetchActionFactory(makeShardPurchase);

export default UserHomePage;
