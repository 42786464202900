import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import CharacterSelectionTable from "../../components/inputs/CharacterSelectionTable";

import { getCharacters, postCharacterXp } from "../../utils/apiInterface";
import FormPage from "../FormPage";
import SubmissionForm, {
  submitFormFactory,
  submissionActionFactory,
} from "../../components/SubmissionForm";

function ManageCharacterXpPage() {
  const submit = useSubmit();
  const { characters } = useLoaderData();

  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [xpAmount, setXpAmount] = useState(0);
  const [reason, setReason] = useState("");

  const handleSubmit = submitFormFactory(
    {
      character_ids: selectedCharacters,
      xp_amount: xpAmount,
      reason,
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_characters">
      <SubmissionForm
        onSubmit={handleSubmit}
        successMessage="XP granted successfully."
        pendingMessage="Please wait while the xp is granted..."
        invalidMessage="There was a problem with your submission. Please try again."
        errorMessage="There was a problem with the server. Please try again later."
      >
        <h2>Character XP:</h2>
        <CharacterSelectionTable
          allCharacters={characters}
          showUsers={true}
          selection={selectedCharacters}
          setSelection={setSelectedCharacters}
        />
        <Form.Group as={Row} className="mt-3 mb-3" controlId="formXpAmount">
          <Form.Label column sm={2}>
            XP Amount
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="number"
              required
              placeholder="XP Amount"
              value={xpAmount}
              onChange={(event) => setXpAmount(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formReason">
          <Form.Label column sm={2}>
            Reason
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              required
              placeholder="Reason for granting the xp"
              value={reason}
              onChange={(event) => setReason(event.target.value)}
            />
          </Col>
        </Form.Group>
      </SubmissionForm>
    </FormPage>
  );
}

ManageCharacterXpPage.loader = async () => await getCharacters();

ManageCharacterXpPage.action = submissionActionFactory(postCharacterXp);

export default ManageCharacterXpPage;
