import Form from "react-bootstrap/Form";

export default function ClassDropDown({
  characterClass,
  setCharacterClass,
  disabled,
}) {
  return (
    <Form.Select
      onChange={(event) => {
        setCharacterClass(event.target.value);
      }}
      value={characterClass}
      disabled={disabled}
    >
      <option value="fighter">Fighter</option>
      <option value="rogue">Rogue</option>
      <option value="adept">Adept</option>
      <option value="templar">Templar</option>
      <option value="shadow">Shadow</option>
    </Form.Select>
  );
}
