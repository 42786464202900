const getCharacterSkill = require("./getCharacterSkill");

function getSkillCount(
  characterBuild,
  skill,
  options,
  row,
  specialtyIndex,
  processReceipt
) {
  const characterSkill = getCharacterSkill(characterBuild, skill);
  if (!skill.skill_options) {
    return processReceipt(characterSkill.amount, skill);
  } else if (skill.skill_options.handed) {
    if (options?.hand) {
      return processReceipt(characterSkill.hand[options.hand], skill);
    } else {
      return Object.entries(characterSkill.hand).reduce(
        (accumulator, [_, receipt]) =>
          accumulator + processReceipt(receipt, skill),
        0
      );
    }
  } else if (skill.skill_options.spell_slot) {
    return characterSkill.levels[specialtyIndex]?.[row - 1] || 0;
  } else if (skill.skill_options.alchemy_slot) {
    return characterSkill.levels[specialtyIndex]?.[row - 1] || 0;
  } else if (skill.skill_options.magic_schools) {
    if (options?.magic_school) {
      return processReceipt(
        characterSkill.schools[options.magic_school],
        skill
      );
    } else {
      return Object.entries(characterSkill.schools).reduce(
        (accumulator, [_, receipt]) =>
          accumulator + processReceipt(receipt, skill),
        0
      );
    }
  } else {
    throw new UnrecognizedSkillFormatError(skill);
  }
}

function getPurchaseCountFromSkillReceipt(receipt) {
  return receipt.purchase_count;
}

function getTotalFromSkillReceipt(receipt, skill) {
  if (skill.skill_max_purchases === 1) {
    return receipt.purchase_count || (receipt.grants.length ? 1 : 0);
  }
  return (
    receipt.purchase_count +
    receipt.grants.reduce((accumulator, { count }) => accumulator + count, 0)
  );
}

function characterHasSkill(characterBuild, skill, options) {
  if (skill.skill_options?.spell_slot || skill.skill_options?.alchemy_slot) {
    return getSkillReceiptTotal(characterBuild, skill, null, 1, 0) > 0;
  }
  return getSkillReceiptTotal(characterBuild, skill, options) > 0;
}

function getSkillPurchaseCount(
  characterBuild,
  skill,
  options,
  row,
  specialtyIndex
) {
  return getSkillCount(
    characterBuild,
    skill,
    options,
    row,
    specialtyIndex,
    getPurchaseCountFromSkillReceipt
  );
}

function getSkillReceiptTotal(
  characterBuild,
  skill,
  options,
  row,
  specialtyIndex
) {
  return getSkillCount(
    characterBuild,
    skill,
    options,
    row,
    specialtyIndex,
    getTotalFromSkillReceipt
  );
}

function skillIsUpgraded(characterBuild, skill, options) {
  if (!skill.next_upgrade) return false;
  return characterHasSkill(characterBuild, skill.next_upgrade, options);
}

function getSkillTotal(characterBuild, skill, options, row, specialtyIndex) {
  if (
    !skill.skill_options?.spell_slot &&
    !skill.skill_options?.alchemy_slot &&
    skillIsUpgraded(characterBuild, skill, options)
  ) {
    return 0;
  }
  let skillCount = getSkillReceiptTotal(
    characterBuild,
    skill,
    options,
    row,
    specialtyIndex
  );
  if (skillCount > 0) {
    let prevUpgrade = skill.prev_upgrade;
    while (prevUpgrade) {
      skillCount += getSkillReceiptTotal(
        characterBuild,
        prevUpgrade,
        options,
        row,
        specialtyIndex
      );
      prevUpgrade = prevUpgrade.prev_upgrade;
    }
  }
  return skillCount;
}

module.exports = {
  characterHasSkill,
  getSkillPurchaseCount,
  getSkillReceiptTotal,
  getSkillTotal,
  skillIsUpgraded,
};
