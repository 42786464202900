import { useState } from "react";
import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";

import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../FetchComponent";
import ConfirmationModal from "../ConfirmationModal";

import { deleteEvent } from "../../utils/apiInterface";

export default function DeleteEventButton({ event }) {
  const [modalOpen, setModalOpen] = useState(false);
  const deleteFetcher = useFetcher();

  const deleteEvent = submitFetchFactory(
    { event_id: event._id },
    {
      method: "post",
      action: `/staff/manage_events/delete_event`,
    },
    deleteFetcher
  );

  return (
    <FetchComponent
      fetcher={deleteFetcher}
      errorMessage="Something went wrong. Please try again later."
      invalidMessage="Something went wrong. Please reload the page and try again."
    >
      <Button
        variant="danger"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Delete
      </Button>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onConfirm={deleteEvent}
        confirmationButtonText="Delete"
        allowConfirm={true}
      >
        <div>Are you sure you would like to delete this event?</div>
        {event.event_published && (
          <div>All event registrations will be lost.</div>
        )}
      </ConfirmationModal>
    </FetchComponent>
  );
}

DeleteEventButton.deleteEventAction = fetchActionFactory(deleteEvent);
