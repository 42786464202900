import { useLoaderData, useSubmit } from "react-router-dom";

import Form from "react-bootstrap/Form";

import { getCharacters, patchPastEventCounts } from "../../utils/apiInterface";
import CharacterTable, {
  CharacterTableColumn,
} from "../../components/CharacterTable";
import FormPage from "../FormPage";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../../components/SubmissionForm";
import { useState } from "react";

function ManagePastEventCountsPage() {
  const submit = useSubmit();

  const { characters } = useLoaderData();

  const [xpSequenceUpdates, setXpSequenceUpdates] = useState({});

  const handleSubmit = (event) => {
    submitFormFactory({ xp_sequences: xpSequenceUpdates }, submit, {
      method: "patch",
    })(event);
  };

  const handleUpdateXpSequence = (newSeqForChar, char) => {
    newSeqForChar = +newSeqForChar;
    const newXpSequenceUpdates = { ...xpSequenceUpdates };
    if (newSeqForChar === char.character_xp_sequence) {
      delete newXpSequenceUpdates[char._id];
    } else {
      newXpSequenceUpdates[char._id] = newSeqForChar;
    }
    setXpSequenceUpdates(newXpSequenceUpdates);
  };

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_characters">
      <SubmissionForm
        onSubmit={handleSubmit}
        successMessage="Character event counts updated successfully."
        pendingMessage="Please wait while the counts are updated..."
        invalidMessage="There was a problem with your submission. Please try again."
        errorMessage="There was a problem with the server. Please try again later."
      >
        <h1>Manage Character Event Counts</h1>
        <CharacterTable
          characters={characters}
          hideDeaths={true}
          rightColumns={[
            new CharacterTableColumn(
              "XP Sequence (Prior events attended)",
              null,
              (char) => (
                <Form.Group controlId={`character-xp-sequence-${char._id}`}>
                  <Form.Control
                    type="number"
                    required
                    value={
                      xpSequenceUpdates[char._id] || char.character_xp_sequence
                    }
                    min={0}
                    onChange={(event) =>
                      handleUpdateXpSequence(event.target.value, char)
                    }
                  />
                </Form.Group>
              )
            ),
          ]}
        />
      </SubmissionForm>
    </FormPage>
  );
}

ManagePastEventCountsPage.loader = getCharacters;

ManagePastEventCountsPage.action =
  submissionActionFactory(patchPastEventCounts);

export default ManagePastEventCountsPage;
