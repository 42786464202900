import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

export default function PlayerCharacterEditorHeader({
  character,
  characterBuild,
}) {
  const attrs = characterBuild.character_race.race_attributes;
  return (
    <div className="box d-flex flex-row flex-wrap align-items-center mb-1">
      <div className="me-3">
        <Table className="table-sm mb-0" borderless>
          <tbody>
            <tr>
              <td>Name: </td>
              <td>{characterBuild.character_name}</td>
            </tr>
            <tr>
              <td>Race:</td>
              <td className="text-capitalize">
                {[
                  characterBuild.character_race.subculture,
                  characterBuild.character_race.race,
                  characterBuild.character_race.transformation,
                ].join(" ")}
              </td>
            </tr>
            <tr>
              <td>Attributes:</td>
              <td className="text-capitalize">
                {attrs
                  ? attrs.length === 0
                    ? "None"
                    : attrs.join(", ")
                  : "Not selected"}
              </td>
            </tr>
            <tr>
              <td>Class:</td>
              <td className="text-capitalize">
                {characterBuild.character_class}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {character.committed_build?.character_race?.race_attributes ? null : (
        <Link to={`/update_character/${character._id}`}>
          <Button className="dark-button">Update this info</Button>
        </Link>
      )}
    </div>
  );
}
