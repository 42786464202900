import React, { useState } from "react";
import { useSubmit } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { postForgotPassword } from "../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import FormPage from "./FormPage";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");

  const submit = useSubmit();
  const handleForgotPassword = submitFormFactory({ email }, submit);

  return (
    <FormPage backLabel="Back" backLink="/login">
      <h1>Please enter your email to recieve a password reset link:</h1>
      <SubmissionForm
        onSubmit={handleForgotPassword}
        submitLabel="Send Email"
        successMessage="An email was sent if the account exists"
        pendingMessage="Sending email..."
        errorMessage="Error sending email. Please try again later."
        invalidMessage="Email not found. Please try again."
      >
        <Form.Group as={Row} className="mb-3" controlId="formEmail">
          <Col sm={10}>
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Col>
        </Form.Group>
      </SubmissionForm>
    </FormPage>
  );
}

ForgotPasswordPage.action = submissionActionFactory(postForgotPassword);

export default ForgotPasswordPage;
