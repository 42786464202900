import { Link } from "react-router-dom";

import BaseCharacterInfo from "./BaseCharacterInfo";

export default function AdminEventRegistrationDisplay({
  event,
  user,
  attendanceFirst,
}) {
  const event_registration = event.event_registrations?.find(
    (reg) => reg.user._id === user._id
  );
  const attendance_record = event.attendance_records?.find(
    (rec) => rec.user._id === user._id
  );
  const survey_response = event.survey_responses?.find(
    (rec) => rec.user._id === user._id
  );

  const eventRegistrationDisplay = (event_registration) => (
    <>
      <h5>Registration Info:</h5>
      <div>
        Registered at:{" "}
        {new Date(event_registration.registered_at).toLocaleString()}
      </div>
      <div className="mt-3">
        Role:{" "}
        <span className="text-uppercase">{event_registration.event_role}</span>
      </div>
      <div>
        Character:{" "}
        <Link
          to={`/staff/users/${user._id}/character_sheet/${event_registration.character._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {event_registration.event_role === "pc" ? (
            <BaseCharacterInfo
              characterBuild={
                event_registration.character.committed_build ||
                event_registration.character.saved_build
              }
            />
          ) : (
            event_registration.character.committed_build?.character_name ||
            event_registration.character.saved_build.character_name
          )}
        </Link>
      </div>
      <div className="mt-3 fw-bold">Question Answers:</div>
      {event_registration.question_answers.map((question) => {
        const foundQuestion = event.event_registration_questions.find(
          (erq) => question.event_registration_question === erq._id
        );
        if (foundQuestion) {
          return (
            <div className="mt-2" key={question._id}>
              <div>Q: {foundQuestion.question_text}</div>
              <div>A: {question.question_answer}</div>
            </div>
          );
        }
        return null;
      })}
    </>
  );

  const attendanceRecordDisplay = (attendance_record) => (
    <>
      <h5>Attendance Info:</h5>
      <div className="mt-3">
        Role:{" "}
        <span className="text-uppercase">{attendance_record.event_role}</span>
      </div>
      <div>
        Character:{" "}
        <Link
          to={`/staff/users/${user._id}/character_sheet/${attendance_record.character._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {attendance_record.event_role === "pc" ? (
            <BaseCharacterInfo
              characterBuild={
                attendance_record.character.committed_build ||
                attendance_record.character.saved_build
              }
            />
          ) : (
            attendance_record.character.committed_build?.character_name ||
            attendance_record.character.saved_build.character_name
          )}
        </Link>
      </div>
      {survey_response && (
        <>
          <div className="mt-3 fw-bold">Survey Response:</div>
          {survey_response.survey_answers.map((question) => {
            const foundQuestion = event.event_survey_questions.find(
              (erq) => question.survey_question === erq._id
            );
            if (foundQuestion) {
              return (
                <div className="mt-2" key={question._id}>
                  <div>Q: {foundQuestion.question_text}</div>
                  <div>A: {question.question_answer}</div>
                </div>
              );
            }
            return null;
          })}
        </>
      )}
    </>
  );

  return (
    <>
      <h4>
        {user.first_name} {user.last_name}
      </h4>
      {attendanceFirst ? (
        <>
          {attendance_record && attendanceRecordDisplay(attendance_record)}
          {event_registration && (
            <>
              <hr />
              {eventRegistrationDisplay(event_registration)}
            </>
          )}
        </>
      ) : (
        <>
          {event_registration && eventRegistrationDisplay(event_registration)}
          {attendance_record && (
            <>
              <hr />
              {attendanceRecordDisplay(attendance_record)}
            </>
          )}
        </>
      )}
    </>
  );
}
