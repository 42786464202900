import React, { useState } from "react";
import { useSubmit, useLoaderData } from "react-router-dom";
import { getRaces, getCurrentUserCharacter } from "../utils/apiInterface";
import { patchUserCharacterBasics } from "../utils/apiInterface";
import CharacterAttributesForm from "../components/forms/CharacterAttributesForm";

import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import { convertToWorkingBuild } from "character-build-helper";
import FormPage from "./FormPage";

function UpdateCharacterBasicsPage() {
  const { character, races } = useLoaderData();
  const submit = useSubmit();
  const [characterName, setCharacterName] = useState(
    character.saved_build.character_name
  );
  const initialRace = races.find(
    (race) =>
      race.race_name === character.saved_build.character_race.race &&
      race.subculture_name === character.saved_build.character_race.subculture
  );
  const [characterRace, setCharacterRace] = useState(initialRace);
  const initialClass = character.saved_build.character_class;
  const [characterClass, setCharacterClass] = useState(initialClass);
  const initialAttribute = characterRace.attribute_choice
    ? character.saved_build.character_race.race_attributes?.find((attribute) =>
        characterRace.attribute_choice.includes(attribute)
      )
    : null;
  const [chosenAttribute, setChosenAttribute] = useState(initialAttribute);

  const deleteSkills =
    initialRace !== characterRace ||
    initialClass !== characterClass ||
    initialAttribute !== chosenAttribute;

  const handleSubmit = (event) => {
    let data = {
      charId: character._id,
      character_name: characterName,
      race: characterRace,
      chosen_attribute: chosenAttribute,
      character_class: characterClass,
      character_skills: deleteSkills
        ? []
        : convertToWorkingBuild(character.saved_build).character_skills,
    };
    submitFormFactory(data, submit, { method: "patch" })(event);
  };

  return (
    <FormPage backLink={`/character_editor/${character._id}`} backLabel="Back">
      <SubmissionForm
        onSubmit={handleSubmit}
        submitLabel="Update"
        navigateOnSubmit={`/character_editor/${character._id}`}
        pendingMessage="Please wait while we submit your changes..."
        errorMessage="Error submitting changes. Please try again later."
        invalidMessage="Incorrect info. Please try again."
      >
        <h2>
          This info can be changed until the first time your character is
          committed
        </h2>
        <CharacterAttributesForm
          races={races}
          handleSubmit={handleSubmit}
          setCharacterName={setCharacterName}
          setCharacterRace={setCharacterRace}
          setCharacterClass={setCharacterClass}
          setChosenAttribute={setChosenAttribute}
          characterName={characterName}
          characterRace={characterRace}
          characterClass={characterClass}
          chosenAttribute={chosenAttribute}
          committedBuild={character.committed_build}
          savedBuild={character.saved_build}
        />
        {deleteSkills &&
        character.saved_build.character_race.race_attributes ? (
          <h3>
            Changing your race, class, or attributes will result in your skills
            being erased.
          </h3>
        ) : null}
      </SubmissionForm>
    </FormPage>
  );
}

UpdateCharacterBasicsPage.loader = async ({ params }) => {
  const { races } = await getRaces();
  const { character } = await getCurrentUserCharacter(params.character_id);
  return { character, races };
};

UpdateCharacterBasicsPage.action = submissionActionFactory(
  patchUserCharacterBasics
);

export default UpdateCharacterBasicsPage;
