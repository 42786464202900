import Form from "react-bootstrap/Form";

export default function QuestionAnswerForm({
  questions,
  questionAnswers,
  setQuestionAnswers,
  frozen,
}) {
  const updateQuestionAnswer = (questionId, newAnswer) => {
    const newQuestionAnswers = { ...questionAnswers };
    newQuestionAnswers[questionId] = newAnswer;
    setQuestionAnswers(newQuestionAnswers);
  };

  return (
    <>
      {questions.map((question) => (
        <Form.Group
          key={question._id}
          className="mb-3"
          controlId="formEventGoals"
        >
          <Form.Label>
            {question.question_text}{" "}
            {question.answer_required && <span className="text-danger">*</span>}
          </Form.Label>
          <Form.Control
            disabled={frozen}
            as="textarea"
            rows={3}
            value={questionAnswers[question._id]}
            onChange={(event) =>
              updateQuestionAnswer(question._id, event.target.value)
            }
          />
        </Form.Group>
      ))}
    </>
  );
}
