import { Link, useLoaderData } from "react-router-dom";

import Button from "react-bootstrap/Button";

import CharacterTable, {
  CharacterTableColumn,
} from "../../components/CharacterTable";
import UncommitCharacterButton from "../../components/buttons/UncommitCharacterButton";

import { getCharacters } from "../../utils/apiInterface";

function ManageCharactersPage() {
  const { characters } = useLoaderData();

  return (
    <>
      <h1>Admin</h1>
      <CharacterTable
        characters={characters}
        showUsers={true}
        showDelete={true}
        rightColumns={[
          new CharacterTableColumn(
            "Character Sheet",
            "center-align-column",
            (char) => (
              <Link
                to={`/staff/users/${char.user._id}/character_sheet/${char._id}`}
              >
                <Button>Edit/View</Button>
              </Link>
            )
          ),
          new CharacterTableColumn(
            "Uncommit",
            "center-align-column",
            (char) => <UncommitCharacterButton character={char} />
          ),
        ]}
      />
      <Link to="/staff/initialize_character">
        <Button className="mt-3">Create Character for User</Button>
      </Link>
      <h2>Admin Tasks</h2>
      <Link to="/staff/manage_xp">
        <Button>Manage Character XP</Button>
      </Link>
      <Link to="/staff/manage_deaths">
        <Button>Manage Character Deaths</Button>
      </Link>
      <Link to="/staff/manage_past_event_counts">
        <Button>Manage Character Event Counts</Button>
      </Link>
      <Link to="/staff/select_character_sheets">
        <Button>Print Character Sheets</Button>
      </Link>
    </>
  );
}

ManageCharactersPage.loader = getCharacters;

export default ManageCharactersPage;
