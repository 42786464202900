const getCharacterSkill = require("./getCharacterSkill");

const {
  characterHasSkill,
  getSkillPurchaseCount,
  getSkillReceiptTotal,
  getSkillTotal,
  skillIsUpgraded,
} = require("./skillCountHelper");

const {
  SkillGrant,
  getAllGrantsFromCharacterSkills,
  grantsEqual,
  attachSkillMetaData,
  getPurchasableSkills,
  displaySkillCostForLevel,
  skillCostForLevel,
  increaseSkillPurchaseAmount,
  decreaseSkillPurchaseAmount,
  skillIsGranted,
  skillIsGrantedBy,
  getSkillGrantOptions,
  getSkillToGrant,
  getDeterminedSkillGrant,
  getAlchemySpecialtyOptions,
  setAlchemySpecialty,
  removeLastAlchemySpecialty,
  getSpellSchoolOptions,
  getAllowedSpellSchoolOptions,
  setSpellSchool,
  removeLastSpellSchool,
  getSpecialtyPyramid,
  validPyramidLevelLocal,
  pyramidLevelCanIncrease,
  pyramidLevelCanDecrease,
  checkPrerequisites,
  checkOnePrerequisite,
  getSkillsFromName,
  getSchoolUmbrella,
  getPrereqSkills,
  skillBreaksBuild,
  skillIsNecessary,
  checkSkillNotRegressed,
  alchemySpecialties,
  magicSchools,
} = require("./skillHelper");

function convertToWorkingBuild(characterBuild) {
  return {
    ...characterBuild,
    character_skills: Object.fromEntries(
      characterBuild.character_skills.map((characterSkill) => [
        characterSkill.skill_id,
        characterSkill,
      ])
    ),
  };
}

function convertFromWorkingBuild(characterBuild) {
  return {
    ...characterBuild,
    character_skills: Object.values(characterBuild.character_skills),
  };
}

function compactWorkingBuild(build, skills) {
  return {
    ...build,
    character_skills: Object.values(build.character_skills).reduce(
      (skillsArray, characterSkill) => {
        const skill = skills.find(({ _id }) => _id == characterSkill.skill_id);
        if (characterHasSkill(build, skill)) {
          skillsArray.push(characterSkill);
        }
        return skillsArray;
      },
      []
    ),
  };
}

async function checkBasicAttributes(build, race) {
  if (
    !["fighter", "rogue", "adept", "shadow", "templar"].includes(
      build.character_class
    )
  ) {
    return false;
  }
  return (
    race &&
    (!build.character_skills || build.character_race.race_attributes) &&
    (!build.character_race.race_attributes ||
      ((!race.default_attributes ||
        build.character_race.race_attributes
          .slice(0, build.character_race.race_attributes.length - 1)
          .every((attr, index) => race.default_attributes[index] === attr)) &&
        (!race.attribute_choice ||
          race.attribute_choice.includes(
            build.character_race.race_attributes.length - 1
          ))))
  );
}

function basicAttributesNotChanged(build, committed) {
  const sameCharacterName = () =>
    !committed.character_name ||
    build.character_name == committed.character_name;
  const sameCharacterRace = () =>
    !committed.character_race.race ||
    build.character_race.race == committed.character_race.race;
  const sameCharacterSubculture = () =>
    !committed.character_race.subculture ||
    build.character_race.subculture == committed.character_race.subculture;
  const sameCharacterAttributes = () =>
    !committed.character_race.race_attributes ||
    (build.character_race.race_attributes.length ===
      committed.character_race.race_attributes.length &&
      committed.character_race.race_attributes.every(
        (attribute, index) =>
          attribute === build.character_race.race_attributes[index]
      ));
  const sameCharacterClass = () =>
    !committed.character_class ||
    build.character_class == committed.character_class;

  return (
    sameCharacterName() &&
    sameCharacterRace() &&
    sameCharacterSubculture() &&
    sameCharacterAttributes() &&
    sameCharacterClass()
  );
}

function checkAgainstCommittedBuild(build, committed, allSkills) {
  return (
    !committed ||
    (basicAttributesNotChanged(build, committed) &&
      (!Object.keys(committed.character_skills).length ||
        Object.entries(committed.character_skills).every(([skill_id, _]) =>
          checkSkillNotRegressed(
            build,
            committed,
            allSkills.find(({ _id }) => _id == skill_id)
          )
        )))
  );
}

function removeFirstFromArray(callback, arr) {
  const i = arr.findIndex(callback);
  if (i === -1) return false;
  arr.splice(i, 1);
  return true;
}

function checkSkillsValid(
  characterLevel,
  build,
  allSkills,
  additionalSpellSchools
) {
  allSkills = getPurchasableSkills(build, allSkills);
  const all_grants = getAllGrantsFromCharacterSkills(build, allSkills);
  if (
    !Object.entries(build.character_skills).every(([skill_id, _]) => {
      const skill = allSkills.find(({ _id }) => _id == skill_id);
      if (
        !skill ||
        (!skill.skill_options?.spell_slot &&
          !checkPrerequisites(characterLevel, build, skill))
      ) {
        return false;
      }
      const characterSkill = getCharacterSkill(build, skill);
      if (skill.skill_options?.spell_slot) {
        if (
          characterSkill.spell_schools.length !==
            new Set(
              characterSkill.spell_schools.filter(
                (school) =>
                  magicSchools.includes(school) ||
                  additionalSpellSchools.includes(school)
              )
            ).size ||
          characterSkill.spell_schools.length !==
            getAllowedSpellSchoolOptions(
              characterSkill.spell_schools,
              characterLevel,
              build,
              skill
            ).length ||
          !characterSkill.levels.every((pyramid) =>
            pyramid.every((_, index) =>
              validPyramidLevelLocal(pyramid[index - 1], pyramid[index])
            )
          )
        ) {
          return false;
        }
      } else if (skill.skill_options?.alchemy_slot) {
        const chosenSpecialties = characterSkill.specialties.filter(
          (specialty) => specialty
        );
        if (
          chosenSpecialties.length !==
            new Set(
              chosenSpecialties.filter((specialty) =>
                alchemySpecialties.includes(specialty)
              )
            ).size ||
          !characterSkill.levels.every((pyramid) =>
            pyramid.every((_, index) =>
              validPyramidLevelLocal(pyramid[index - 1], pyramid[index])
            )
          )
        ) {
          return false;
        }
      }
      // if this skill has grants, remove them from the total grant list
      skill.skill_grants?.forEach((grant, index) => {
        if (grant.variety) {
          const possible_skills = getSkillGrantOptions(skill)[index];
          for (
            let level = 1;
            level <= getSkillPurchaseCount(build, skill);
            level++
          ) {
            let foundSkills = 0;
            let skillInd = 0;
            while (
              foundSkills < grant.variety &&
              skillInd < possible_skills.length
            ) {
              const possible_grants = getAllGrantsFromCharacterSkills(build, [
                possible_skills[skillInd],
              ]);
              const skill_grant = possible_grants.find((g) =>
                grantsEqual(new SkillGrant(1, skill.skill_name, level), g)
              );
              if (skill_grant) {
                removeFirstFromArray(
                  (el) => el === possible_skills[skillInd],
                  possible_skills
                );
                if (
                  !removeFirstFromArray(
                    (elem) => grantsEqual(skill_grant, elem),
                    all_grants
                  )
                ) {
                  return false;
                }
                foundSkills += 1;
              } else {
                skillInd += 1;
              }
            }
            if (foundSkills < grant.variety) {
              return false;
            }
          }
        } else {
          for (
            let level = 1;
            level <= getSkillPurchaseCount(build, skill);
            level++
          ) {
            const possible_grants = getAllGrantsFromCharacterSkills(build, [
              getSkillToGrant(grant, skill),
            ]);
            const skill_grant = possible_grants.find((g) =>
              grantsEqual(getDeterminedSkillGrant(grant, skill, level), g)
            );
            if (
              !skill_grant ||
              !removeFirstFromArray(
                (elem) => grantsEqual(skill_grant, elem),
                all_grants
              )
            ) {
              return false;
            }
          }
        }
      });
      return true;
    })
  ) {
    return false;
  }
  return all_grants.length === 0;
}

function getCostTotalForCharacterSkill(build, skill, options) {
  const level = getSkillPurchaseCount(build, skill, options);
  let totalCost = 0;
  for (let i = 1; i <= level; i++) {
    totalCost += skillCostForLevel(skill, build, i);
  }
  return totalCost;
}

function calculateBuildCost(build, allSkills) {
  return Object.entries(build.character_skills).reduce((sum, [skill_id, _]) => {
    const skill = allSkills.find(({ _id }) => _id == skill_id);
    if (!skill.skill_options) {
      if (skillIsGranted(build, skill)) {
        return sum;
      }
      return sum + getCostTotalForCharacterSkill(build, skill);
    }
    if (skill.skill_options.handed) {
      return (
        sum +
        ["left", "right"].reduce(
          (handSum, hand) =>
            handSum + getCostTotalForCharacterSkill(build, skill, { hand }),
          0
        )
      );
    }
    if (skill.skill_options.spell_slot) {
      const characterSkill = getCharacterSkill(build, skill);
      return (
        sum +
        characterSkill.levels.reduce((pyramidSum, pyramid, schoolIndex) => {
          return (
            pyramidSum +
            pyramid.reduce((rowSum, rowAmount, rowIndex) => {
              let totalCost = 0;
              for (let i = 1; i <= rowAmount; i++) {
                totalCost += skillCostForLevel(
                  skill,
                  build,
                  rowIndex + 1,
                  schoolIndex
                );
              }
              return rowSum + totalCost;
            }, 0)
          );
        }, 0)
      );
    }
    if (skill.skill_options.alchemy_slot) {
      const characterSkill = getCharacterSkill(build, skill);
      return (
        sum +
        characterSkill.levels.reduce((pyramidSum, pyramid, specialtyIndex) => {
          return (
            pyramidSum +
            pyramid.reduce((rowSum, rowAmount, rowIndex) => {
              let totalCost = 0;
              for (let i = 1; i <= rowAmount; i++) {
                totalCost += skillCostForLevel(
                  skill,
                  build,
                  rowIndex + 1,
                  specialtyIndex
                );
              }
              return rowSum + totalCost;
            }, 0)
          );
        }, 0)
      );
    }
    if (skill.skill_options.magic_schools) {
      return (
        sum +
        skill.skill_options.magic_schools.reduce(
          (schoolSum, magic_school) =>
            schoolSum +
            getCostTotalForCharacterSkill(build, skill, { magic_school }),
          0
        )
      );
    }
  }, 0);
}

module.exports = {
  attachSkillMetaData,
  getPurchasableSkills,
  getCharacterSkill,
  getSkillPurchaseCount,
  getSkillReceiptTotal,
  getSkillTotal,
  skillIsUpgraded,
  displaySkillCostForLevel,
  skillCostForLevel,
  increaseSkillPurchaseAmount,
  decreaseSkillPurchaseAmount,
  skillIsGranted,
  skillIsGrantedBy,
  getSkillGrantOptions,
  getAlchemySpecialtyOptions,
  setAlchemySpecialty,
  removeLastAlchemySpecialty,
  getSpellSchoolOptions,
  getAllowedSpellSchoolOptions,
  setSpellSchool,
  removeLastSpellSchool,
  getSpecialtyPyramid,
  pyramidLevelCanIncrease,
  pyramidLevelCanDecrease,
  characterHasSkill,
  checkPrerequisites,
  checkOnePrerequisite,
  getSkillsFromName,
  getSchoolUmbrella,
  getPrereqSkills,
  skillBreaksBuild,
  skillIsNecessary,
  convertToWorkingBuild,
  convertFromWorkingBuild,
  compactWorkingBuild,
  checkAgainstCommittedBuild,
  checkSkillsValid,
  calculateBuildCost,
  checkBasicAttributes,
};
