import { useFetcher } from "react-router-dom";

import Row from "react-bootstrap/Row";

import { useState } from "react";
import FetchComponent, { submitFetchFactory } from "./FetchComponent";
import ConfirmationModal from "./ConfirmationModal";
import AdminEventRegistrationDisplay from "./AdminEventRegistrationDisplay";
import RegistrationAcceptanceTable from "./inputs/RegistrationAcceptanceTable";

export default function ReviewRegistrationModal({
  modalOpen,
  setModalOpen,
  event,
  eventRegistrations,
}) {
  const confirmFetcher = useFetcher();

  const newEventRegistrations = eventRegistrations.filter(
    ({ state }) => !state
  );
  const respondedTo = eventRegistrations.filter(({ state }) =>
    ["accepted", "waitlisted"].includes(state)
  );

  const [selection, setSelection] = useState(null);

  const [acceptanceState, setAcceptanceState] = useState(
    Object.fromEntries(
      eventRegistrations
        .filter(({ state }) => !["cancelled", null].includes(state))
        .map((reg) => [reg._id, reg.state.slice(0, -2)])
    )
  );
  const [waitlistMessages, setWaitlistMessages] = useState(
    Object.fromEntries(
      eventRegistrations.map((reg) => [reg._id, reg.waitlist_note])
    )
  );

  const confirmRegFunction = submitFetchFactory(
    {
      event_id: event._id,
      accept: Object.keys(acceptanceState).filter(
        (id) => acceptanceState[id] === "accept"
      ),
      waitlist: Object.keys(acceptanceState).filter(
        (id) => acceptanceState[id] === "waitlist"
      ),
      waitlist_messages: Object.fromEntries(
        Object.entries(waitlistMessages).filter(
          ([id, _message]) => acceptanceState[id] === "waitlist"
        )
      ),
    },
    {
      method: "post",
      action: `/staff/manage_events/${event._id}/registrations/confirm_registrations`,
    },
    confirmFetcher
  );

  return (
    <ConfirmationModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      onConfirm={confirmRegFunction}
      allowConfirm={true}
      confirmationButtonText="Submit Acceptances"
    >
      {selection && (
        <Row className="viewing-window-modal">
          <AdminEventRegistrationDisplay event={event} user={selection.user} />{" "}
        </Row>
      )}
      <FetchComponent fetcher={confirmFetcher}>
        <Row className="mt-3">
          <h2>Not responded</h2>
          <RegistrationAcceptanceTable
            eventRegistrations={newEventRegistrations}
            acceptanceState={acceptanceState}
            setAcceptanceState={setAcceptanceState}
            waitlistMessages={waitlistMessages}
            setWaitlistMessages={setWaitlistMessages}
            setSelection={setSelection}
          />
          <h2>Responded</h2>
          <RegistrationAcceptanceTable
            eventRegistrations={respondedTo}
            acceptanceState={acceptanceState}
            setAcceptanceState={setAcceptanceState}
            waitlistMessages={waitlistMessages}
            setWaitlistMessages={setWaitlistMessages}
            setSelection={setSelection}
          />
        </Row>
      </FetchComponent>
    </ConfirmationModal>
  );
}
