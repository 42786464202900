import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { isEventPassed } from "event-helper";
import QuestionAnswerForm from "./QuestionAnswerForm";

export default function EventRegistrationForm({
  frozen,
  event,
  eventRoleTypes,
  characters,
  regConfirmed,
  prevRegistrationType,
  registrationType,
  setRegistrationType,
  characterId,
  setCharacterId,
  questionAnswers,
  setQuestionAnswers,
}) {
  const eventPassed = isEventPassed(event);

  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formRegistrationType">
        <Col>
          <Form.Label>Register as:</Form.Label>
          <Form.Select
            disabled={frozen}
            onChange={(event) => setRegistrationType(event.target.value)}
            value={registrationType}
          >
            <option disabled={registrationType}>Choose one</option>
            {Object.entries(eventRoleTypes).map(([role_abbrev, role]) => (
              <option key={role_abbrev} value={role_abbrev}>
                {role_abbrev.toUpperCase()} ({role})
              </option>
            ))}
          </Form.Select>
          {regConfirmed && prevRegistrationType !== registrationType && (
            <div className="text-danger">
              * If you switch your registration type, your registration will
              need to be re-confirmed, and you may be waitlisted if the newly
              selected role has no open spots.
            </div>
          )}
        </Col>
      </Form.Group>
      {registrationType && (
        <>
          <Form.Group as={Row} className="mb-3" controlId="formCharacterSelect">
            <Col>
              <Form.Label>
                {registrationType === "pc"
                  ? "Choose the character you will play:"
                  : "Choose the character you will allocate your xp to:"}
              </Form.Label>
              <Form.Select
                disabled={frozen}
                onChange={(event) => setCharacterId(event.target.value)}
                value={characterId}
              >
                {characters.map((character) => (
                  <option value={character._id} key={character._id}>
                    {character.committed_build?.character_name ||
                      character.saved_build.character_name}
                  </option>
                ))}
              </Form.Select>
              {registrationType === "pc" && !eventPassed && (
                <span className="text-danger">
                  * Remember to commit your build at least 2 weeks before the
                  event so that your character sheet can be printed.
                </span>
              )}
            </Col>
          </Form.Group>
          <QuestionAnswerForm
            questions={event.event_registration_questions.filter((question) =>
              question.roles_to_ask.includes(registrationType)
            )}
            questionAnswers={questionAnswers}
            setQuestionAnswers={setQuestionAnswers}
            frozen={frozen}
          />
        </>
      )}
    </>
  );
}
