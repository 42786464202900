import { useEffect, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";

import SubmissionForm, {
  submitFormFactory,
  submissionActionFactory,
} from "../../components/SubmissionForm";

import {
  getDefaultRegistrationQuestions,
  patchDefaultRegistrationQuestions,
} from "../../utils/apiInterface";
import FormPage from "../FormPage";
import { getEventRoleTypes } from "../../utils/apiInterface";
import RegistrationQuestionInput from "../../components/inputs/RegistrationQuestionInput";

function UpdateDefaultRegistrationQuestionsPage() {
  const submit = useSubmit();
  const actionData = useActionData();
  const { default_registration_questions, event_role_types } = useLoaderData();

  const mapWorkingQuestions = (questions) => {
    return questions.map((question) =>
      Object.assign({}, question, {
        selected: true,
        original_text: question.question_text,
      })
    );
  };

  const [defaultRegistrationQuestions, setDefaultRegistrationQuestions] =
    useState(mapWorkingQuestions(default_registration_questions));

  useEffect(() => {
    if (actionData) {
      setDefaultRegistrationQuestions(
        mapWorkingQuestions(actionData.data.default_registration_questions)
      );
    }
  }, [actionData]);

  const handleSubmit = submitFormFactory(
    {
      default_registration_questions: RegistrationQuestionInput.questionsToSend(
        defaultRegistrationQuestions,
        event_role_types
      ),
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_events">
      <SubmissionForm
        onSubmit={handleSubmit}
        successMessage="Update was successful."
        pendingMessage="Please wait while the default questions are updated."
        invalidMessage="There was a problem with your submission. Please try again."
        errorMessage="There was a problem with the server. Please try again later."
      >
        <h2>Update Default Event Registration Questions:</h2>
        <RegistrationQuestionInput
          questions={defaultRegistrationQuestions}
          setQuestions={setDefaultRegistrationQuestions}
          eventRoleTypes={event_role_types}
        />
      </SubmissionForm>
    </FormPage>
  );
}

UpdateDefaultRegistrationQuestionsPage.loader = async () => {
  const { default_registration_questions } =
    await getDefaultRegistrationQuestions();
  const { event_role_types } = await getEventRoleTypes();

  return { default_registration_questions, event_role_types };
};

UpdateDefaultRegistrationQuestionsPage.action = submissionActionFactory(
  patchDefaultRegistrationQuestions
);

export default UpdateDefaultRegistrationQuestionsPage;
