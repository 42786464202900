import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SkillList from "../skillList/SkillList";

import PrintCharacterViewerHeader from "../headers/PrintCharacterViewerHeader";
import PlayerCharacterEditorHeader from "../headers/PlayerCharacterEditorHeader";

export default function CharacterSheet({
  characterSheetStyleClass,
  character,
  characterBuild,
  skills,
  children,
  mode,
  renderAlchemyPyramidDisplay,
  renderSpellPyramidDisplay,
  renderAmountDisplay,
}) {
  const characterInitialized =
    character.saved_build.character_race.race_attributes;

  return (
    <Row
      style={{ pageBreakAfter: "always" }}
      className={`${characterSheetStyleClass} mt-2`}
    >
      <Col>
        {mode === "view" ? (
          <PrintCharacterViewerHeader
            character={character}
            displayBuild={characterBuild}
          />
        ) : mode === "edit" ? (
          <PlayerCharacterEditorHeader
            character={character}
            characterBuild={characterBuild}
          />
        ) : null}
        <div className="mb-3">{children}</div>
        {mode === "edit" && !characterInitialized ? (
          <Row>
            <Col>
              <h1>
                Please select your character's attributes in order to begin
                purchasing skills
              </h1>
            </Col>
          </Row>
        ) : mode === "view" && !skills.length ? (
          "No skills purchased"
        ) : (
          <SkillList
            skills={skills}
            character={character}
            characterBuild={characterBuild}
            mode={mode}
            renderAlchemyPyramidDisplay={renderAlchemyPyramidDisplay}
            renderSpellPyramidDisplay={renderSpellPyramidDisplay}
            renderAmountDisplay={renderAmountDisplay}
          />
        )}
      </Col>
    </Row>
  );
}
