import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import FormPage from "./FormPage";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";

import {
  getCurrentUserCharacters,
  getCurrentUserEventRegistration,
  getEventRoleTypes,
  patchCurrentUserEventRegistration,
} from "../utils/apiInterface";
import EventRegistrationForm from "../components/forms/EventRegistrationForm";
import CancelRegistrationButton from "../components/buttons/CancelRegistrationButton";
import { isEventStarted, isPreRegActive } from "event-helper";

function EditEventRegistrationPage() {
  const submit = useSubmit();
  const { event_registration, characters, event_role_types } = useLoaderData();
  const event = event_registration.event;
  const eventStarted = isEventStarted(event);
  const [registrationType, setRegistrationType] = useState(
    event_registration.event_role
  );
  const [characterId, setCharacterId] = useState(event_registration.character);
  const [questionAnswers, setQuestionAnswers] = useState(
    Object.fromEntries(
      event_registration.question_answers.map((question_answer) => [
        question_answer.event_registration_question,
        question_answer.question_answer,
      ])
    )
  );

  const preRegActive = isPreRegActive(event);
  const frozen =
    eventStarted || (!preRegActive && event_registration.state !== "cancelled");

  const handleSubmit = submitFormFactory(
    {
      event_registration_id: event_registration._id,
      character: characterId,
      event_role: registrationType,
      question_answers: event.event_registration_questions
        .filter(
          (question) =>
            question.roles_to_ask.includes(registrationType) &&
            questionAnswers[question._id]
        )
        .map((question) => {
          return {
            event_registration_question: question._id,
            question_answer: questionAnswers[question._id],
          };
        }),
    },
    submit,
    { method: "patch" }
  );

  const form = (
    <EventRegistrationForm
      frozen={frozen}
      event={event}
      eventRoleTypes={event_role_types}
      characters={characters}
      regConfirmed={event_registration.state === "accepted"}
      prevRegistrationType={event_registration.event_role}
      registrationType={registrationType}
      setRegistrationType={setRegistrationType}
      characterId={characterId}
      setCharacterId={setCharacterId}
      questionAnswers={questionAnswers}
      setQuestionAnswers={setQuestionAnswers}
    />
  );

  return (
    <FormPage backLabel="Back" backLink="/events">
      <h1>
        {eventStarted ? "View" : "Edit"} Event Registration: {event.event_title}
      </h1>
      <div className="mb-3">
        {preRegActive ? (
          <span className="text-danger">
            * Edits can be made until the pre-registration cutoff two weeks
            prior to the event.
          </span>
        ) : (
          <>
            This registration can no longer be edited as{" "}
            {eventStarted
              ? "the event has started"
              : "it is past the pre-registration cuttoff for the event"}
            . If you have any concerns, please contact a staff member.
          </>
        )}
      </div>
      {frozen ? (
        form
      ) : (
        <SubmissionForm
          onSubmit={handleSubmit}
          navigateOnSubmit="/events"
          submitLabel={
            event_registration.state === "cancelled" ? "Register" : "Update"
          }
          pendingMessage="Please wait while your registration is submitted..."
          errorMessage="There was a problem with the server. Please try again later."
          invalidMessage="There was a problem with your submission. Please try again."
        >
          {form}
        </SubmissionForm>
      )}
      {!eventStarted && event_registration.state !== "cancelled" && (
        <CancelRegistrationButton event_registration={event_registration} />
      )}
    </FormPage>
  );
}

EditEventRegistrationPage.loader = async ({ params }) => {
  const { event_registration } = await getCurrentUserEventRegistration({
    event_registration_id: params.event_registration_id,
  });
  const { characters } = await getCurrentUserCharacters();
  const { event_role_types } = await getEventRoleTypes();
  return { event_registration, characters, event_role_types };
};

EditEventRegistrationPage.action = submissionActionFactory(
  patchCurrentUserEventRegistration
);

export default EditEventRegistrationPage;
