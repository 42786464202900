import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import InsufficientPermissionsError from "../../utils/errors/InsufficientPermissionsError";
import { RolesContext } from "../../context/RolesContext";

function StaffPageTemplate() {
  const roles = useContext(RolesContext);

  if (!roles.includes("admin")) {
    throw new InsufficientPermissionsError("Not Found");
  }

  return <Outlet />;
}

export default StaffPageTemplate;
