import React from "react";

import CharacterSheet from "./CharacterSheet";
import SlotPyramidDisplay from "../skillDisplays/SlotPyramidDisplay";
import {
  characterHasSkill,
  convertToWorkingBuild,
  getSkillTotal,
} from "character-build-helper";

export default function ViewableCharacterSheet({
  characterSheetStyleClass,
  character,
  skills,
  children,
  mode,
}) {
  const characterBuild = convertToWorkingBuild(
    character.committed_build || character.saved_build
  );

  return (
    <CharacterSheet
      characterSheetStyleClass={characterSheetStyleClass}
      character={character}
      children={children}
      mode={mode}
      skills={skills.filter((skill) =>
        characterHasSkill(characterBuild, skill)
      )}
      characterBuild={characterBuild}
      renderAlchemyPyramidDisplay={(skill) => (
        <SlotPyramidDisplay
          characterBuild={characterBuild}
          skill={skill}
          specialtyListName="specialties"
          specialtyName="Specialty"
        />
      )}
      renderSpellPyramidDisplay={(skill) => (
        <SlotPyramidDisplay
          characterBuild={characterBuild}
          skill={skill}
          specialtyListName="spell_schools"
          specialtyName="School"
        />
      )}
      renderAmountDisplay={(skill, options) =>
        getSkillTotal(characterBuild, skill, options)
      }
    />
  );
}
