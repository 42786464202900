import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function SkillSelectionCheckbox({
  canIncrease,
  increase,
  canDecrease,
  increaseToolTip,
  decreaseToolTip,
  decrease,
  purchaseCount,
}) {
  const setPurchased = (event) => {
    if (event.target.checked) {
      increase();
    } else {
      decrease();
    }
  };
  const tooltipVisible = purchaseCount ? decreaseToolTip : increaseToolTip;

  const checkBox = (
    <span>
      <Form.Check
        type="checkbox"
        onChange={setPurchased}
        checked={purchaseCount}
        disabled={purchaseCount ? !canDecrease : !canIncrease}
      />
    </span>
  );

  return tooltipVisible ? (
    <OverlayTrigger
      placement={"top"}
      overlay={
        <Tooltip>{purchaseCount ? decreaseToolTip : increaseToolTip}</Tooltip>
      }
    >
      {checkBox}
    </OverlayTrigger>
  ) : (
    checkBox
  );
}
