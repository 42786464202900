import React from "react";
import { Outlet, useLoaderData } from "react-router-dom";
import { RolesContext } from "../context/RolesContext";
import { getRoles } from "../utils/apiInterface";

function AuthenticatedPageTemplate() {
  const { roles } = useLoaderData();

  return (
    <RolesContext.Provider value={roles}>
      <Outlet />
    </RolesContext.Provider>
  );
}

AuthenticatedPageTemplate.loader = getRoles;

export default AuthenticatedPageTemplate;
