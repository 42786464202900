import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import ClassDropDown from "../inputs/ClassDropDown";
import RaceDropDown from "../inputs/RaceDropDown";

export default function CharacterBasicsForm({
  characterName,
  savedBuild,
  setCharacterName,
  disableName,
  characterRace,
  setCharacterRace,
  disableRace,
  characterClass,
  setCharacterClass,
  disableClass,
  races,
  children,
}) {
  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formCharName">
        <Form.Label column sm={2}>
          Character Name
        </Form.Label>
        <Col sm={10}>
          <Form.Control
            type="text"
            placeholder="Character Name"
            required
            value={characterName}
            onChange={(event) => {
              setCharacterName(event.target.value);
            }}
            disabled={disableName}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formRace">
        <Form.Label column sm={2}>
          Race
        </Form.Label>
        <Col sm={10}>
          <RaceDropDown
            races={races}
            setRace={setCharacterRace}
            race={characterRace}
            disabled={disableRace}
          />
          {savedBuild?.character_race.transformation ? (
            <div className="text-capitalize">
              Transformation: {savedBuild.character_race.transformation}
            </div>
          ) : null}
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formClass">
        <Form.Label column sm={2}>
          Character Class
        </Form.Label>
        <Col sm={10}>
          <ClassDropDown
            id="class-input"
            setCharacterClass={setCharacterClass}
            characterClass={characterClass}
            disabled={disableClass}
          />
        </Col>
      </Form.Group>
      {children}
    </>
  );
}
