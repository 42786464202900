import BasicSkill from "./BasicSkill";

export default function Skill({
  characterBuild,
  characterLevel,
  skill,
  displayOption,
  renderAlchemyPyramidDisplay,
  renderSpellPyramidDisplay,
  renderAmountDisplay,
}) {
  if (skill.skill_options?.spell_slot) {
    return renderSpellPyramidDisplay(skill);
  }
  if (skill.skill_options?.alchemy_slot) {
    return renderAlchemyPyramidDisplay(skill);
  } else {
    return (
      <BasicSkill
        skill={skill}
        characterBuild={characterBuild}
        characterLevel={characterLevel}
        displayOption={displayOption}
        renderAmountDisplay={renderAmountDisplay}
      />
    );
  }
}
