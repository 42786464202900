function RegistrationSummary({ event_registration, full }) {
  const waitlistNote =
    event_registration.waitlist_note ||
    `Unfortunately we are unable to accept more ${event_registration.event_role.toUpperCase()}s at this time. You may switch your registration type or wait for more ${event_registration.event_role.toUpperCase()} spots to open up.`;

  return event_registration.state === "cancelled" ? (
    <div>Registration Cancelled</div>
  ) : (
    <>
      {event_registration.state === "accepted" && "✅ "}
      Registered as: {event_registration.event_role !== "pc" && "NPC ("}
      {event_registration.character.committed_build?.character_name ||
        event_registration.character.saved_build.character_name}
      {event_registration.event_role !== "pc" && ")"}{" "}
      {!event_registration.state === "accepted" &&
        (event_registration.state === "waitlisted"
          ? "❗ Attention needed"
          : "⚠️ Awaiting confirmation")}
      {full && (
        <>
          {event_registration.state === "waitlisted" && (
            <div className="text-danger">* {waitlistNote}</div>
          )}
        </>
      )}
    </>
  );
}

export default RegistrationSummary;
