import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import { isEventPassed } from "event-helper";

import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";

import EventCardList from "../components/eventCards/EventCardList";
import EditableEventRegistrationCard from "../components/eventCards/EditableEventRegistrationCard";

import {
  getCurrentUserCharacters,
  getPublishedEvents,
  getRoles,
} from "../utils/apiInterface";

function EventsPage() {
  const { events, characters } = useLoaderData();

  const futureEvents = events.filter((event) => !isEventPassed(event));
  const pastEvents = events.filter((event) => isEventPassed(event)).reverse();

  const [openEvent, setOpenEvent] = useState(null);

  const openRegistration = openEvent?.event_registrations?.[0];

  return (
    <>
      <h1>Events</h1>
      {characters.length ? null : (
        <>
          You must have created a character in order to register for an event.
          <br />
          Even if npcing, a character is needed to allocate your xp to.
        </>
      )}
      {futureEvents.length ? (
        <EventCardList
          event_card_type={EditableEventRegistrationCard}
          events={futureEvents}
          setSelectedEvent={setOpenEvent}
          characters={characters}
        />
      ) : (
        "No events to display."
      )}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Past Events</Accordion.Header>
          <Accordion.Body>
            {pastEvents.length ? (
              <EventCardList
                event_card_type={EditableEventRegistrationCard}
                events={pastEvents}
                setSelectedEvent={setOpenEvent}
                characters={characters}
              />
            ) : (
              "No events to display."
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {openEvent ? (
        <Modal show={openEvent} onHide={() => setOpenEvent(null)}>
          <EditableEventRegistrationCard
            event={openEvent}
            event_registration={openRegistration}
            setSelectedEvent={setOpenEvent}
            characters={characters}
            full={true}
          />
        </Modal>
      ) : null}
    </>
  );
}

EventsPage.loader = async () => {
  const { roles } = await getRoles();
  const events = await getPublishedEvents({
    extra_includes: roles.includes("staff") ? "survey_response_count" : "",
  });
  const characters = await getCurrentUserCharacters();
  return { ...events, ...characters };
};

export default EventsPage;
