class SkillReceipt {
  constructor() {
    this.purchase_count = 0;
    this.grants = [];
  }
}

function createNewCharacterSkill(skill) {
  if (!skill.skill_options) {
    return { skill_id: skill._id, amount: new SkillReceipt() };
  }
  if (skill.skill_options.handed) {
    return {
      skill_id: skill._id,
      hand: { left: new SkillReceipt(), right: new SkillReceipt() },
    };
  }
  if (skill.skill_options.spell_slot) {
    return {
      skill_id: skill._id,
      levels: [],
      spell_schools: [],
    };
  }
  if (skill.skill_options.alchemy_slot) {
    return {
      skill_id: skill._id,
      levels: [],
      specialties: [],
    };
  }
  if (skill.skill_options.magic_schools) {
    return {
      skill_id: skill._id,
      schools: Object.fromEntries(
        skill.skill_options.magic_schools.map((school) => [
          school,
          new SkillReceipt(),
        ])
      ),
    };
  }
  throw new UnrecognizedSkillFormatError(skill);
}

module.exports = (characterBuild, skill) => {
  return (
    characterBuild.character_skills[skill._id] || createNewCharacterSkill(skill)
  );
};
