import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function SkillSelectionCounter({
  representation,
  canIncrease,
  increase,
  increaseToolTip,
  decreaseToolTip,
  canDecrease,
  decrease,
}) {
  const minusButton = (
    <span>
      <Button
        onClick={decrease}
        disabled={!canDecrease}
        className="counter-button dark-button me-1"
      >
        -
      </Button>
    </span>
  );
  const plusButton = (
    <span>
      <Button
        onClick={increase}
        disabled={!canIncrease}
        className="counter-button dark-button ms-1"
      >
        +
      </Button>
    </span>
  );

  return (
    <>
      {canDecrease || !decreaseToolTip ? (
        minusButton
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{decreaseToolTip}</Tooltip>}
        >
          {minusButton}
        </OverlayTrigger>
      )}
      {representation}
      {canIncrease || !increaseToolTip ? (
        plusButton
      ) : (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{increaseToolTip}</Tooltip>}
        >
          {plusButton}
        </OverlayTrigger>
      )}
    </>
  );
}
