import Form from "react-bootstrap/Form";

export default function UserDropDown({ userId, setUserId, users }) {
  return (
    <Form.Select
      onChange={(event) => {
        setUserId(event.target.value);
      }}
      value={userId}
    >
      {users.map((user) => {
        return (
          <option key={user._id} value={user._id}>
            {user.first_name + " " + user.last_name}
          </option>
        );
      })}
    </Form.Select>
  );
}
