import { useSubmit } from "react-router-dom";
import SubmissionForm, { submitFormFactory } from "../SubmissionForm";
import QuestionAnswerForm from "./QuestionAnswerForm";

export default function SurveyForm({
  event,
  questionAnswers,
  setQuestionAnswers,
}) {
  const submit = useSubmit();

  const handleSubmit = submitFormFactory(
    {
      event: event._id,
      survey_answers: event.event_survey_questions
        .filter((question) => questionAnswers[question._id]?.length)
        .map((question) => {
          return {
            survey_question: question._id,
            question_answer: questionAnswers[question._id],
          };
        }),
    },
    submit
  );

  return (
    <SubmissionForm
      onSubmit={handleSubmit}
      navigateOnSubmit="/events"
      pendingMessage="Please wait while your survey is submitted..."
      errorMessage="There was a problem with the server. Please try again later."
      invalidMessage="There was a problem with your submission. Please try again."
    >
      <QuestionAnswerForm
        questions={event.event_survey_questions}
        questionAnswers={questionAnswers}
        setQuestionAnswers={setQuestionAnswers}
      />
    </SubmissionForm>
  );
}
