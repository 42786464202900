import UnauthorizedError from "./errors/UnauthorizedError";
import DisplayError from "./errors/DisplayError";
import { attachSkillMetaData } from "character-build-helper";

const basicOptions = {
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

async function makeRequest(endpoint, options) {
  if (options.headers?.["Content-Type"] === "application/json") {
    options.body = JSON.stringify(options.body);
  }
  const res = await fetch(endpoint, options);
  if (res.status === 401) throw new UnauthorizedError();
  return res;
}

async function get(endpoint) {
  return (await (await makeRequest(endpoint, basicOptions)).json()).data;
}

async function deleteResource(endpoint) {
  return await makeRequest(endpoint, { method: "DELETE", ...basicOptions });
}

async function post(endpoint, data, overwriteOptions) {
  return await makeRequest(endpoint, {
    method: "POST",
    ...basicOptions,
    ...overwriteOptions,
    body: data,
  });
}

async function patch(endpoint, data, overwriteOptions) {
  return await makeRequest(endpoint, {
    method: "PATCH",
    ...basicOptions,
    ...overwriteOptions,
    body: data,
  });
}

export const postLogin = async ({ email, password }) => {
  if (email?.length && password?.length) {
    return await post("/api/v1/login", { email, password });
  }
  throw new DisplayError("Please enter your username and password");
};

export const postLogout = async () => await post("/api/v1/logout");

export const postSignup = async ({
  first_name,
  last_name,
  email,
  password,
  password_confirm,
  privacy_statement_accepted,
  email_consent,
}) =>
  await post("/api/v1/signup", {
    first_name,
    last_name,
    email,
    password,
    password_confirm,
    privacy_statement_accepted,
    email_consent,
  });

export const postResendVerification = async ({ email }) =>
  await post("/api/v1/resend_verification", { email });

export const postForgotPassword = async ({ email }) =>
  await post("/api/v1/forgot_password", { email });

export const patchResetPassword = async ({
  token,
  password,
  password_confirm,
}) => {
  if (!password || !password_confirm) {
    throw new DisplayError(
      "password and password confirm must both be present and matching"
    );
  }
  return await patch(`/api/v1/reset_password/${token}`, {
    password,
    password_confirm,
  });
};

export const patchVerifyEmail = async ({ token, privacy_statement_accepted }) =>
  await patch(`/api/v1/verify/${token}`, { privacy_statement_accepted });

export const patchUpdatePassword = async ({
  password_current,
  password,
  password_confirm,
}) => {
  if (!password_current || !password || !password_confirm) {
    throw new DisplayError("Please fill out all fields");
  }
  if (password !== password_confirm) {
    throw new DisplayError("Password and password confirm must match");
  }
  return await patch("/api/v1/user/update_password", {
    password_current,
    password,
    password_confirm,
  });
};

export const getRoles = async () => await get("/api/v1/user/roles");

export const postUserRoles = async ({ users }) =>
  await post("/api/v1/users/roles", { users });

export const getShardInfo = async () => await get("/api/v1/user/shards");

export const makeShardPurchase = async ({ purchase, options }) =>
  await post("/api/v1/user/shards/spend", { purchase, options });

export const getSecretOptions = async () =>
  await get("/api/v1/characters/secret_options");

export const patchSecretOptions = async ({
  user_id,
  character_id,
  additional_spell_schools,
  set_transformation,
}) =>
  await patch(`/api/v1/users/${user_id}/characters/${character_id}`, {
    additional_spell_schools,
    set_transformation,
  });

export const patchUncommitUserCharacter = async ({ user_id, character_id }) =>
  await patch(`/api/v1/users/${user_id}/characters/${character_id}`, {
    committed_build: null,
  });

export const deleteCharacter = async ({ user_id, character_id }) =>
  await deleteResource(`/api/v1/users/${user_id}/characters/${character_id}`);

export const deleteCurrentUserCharacter = async ({ character_id }) =>
  await deleteResource(`/api/v1/user/characters/${character_id}`);

export const postInitializeCharacter = async ({
  character_name,
  race,
  character_class,
  character_xp_total,
  death_count,
  reaper_fight_count,
  user_id,
}) => {
  const race_attributes = race.attribute_choice
    ? null
    : race.default_attributes || [];
  return await post(`/api/v1/users/${user_id}/characters`, {
    character_name,
    character_race: {
      race: race.race_name,
      subculture: race.subculture_name,
      race_attributes,
    },
    character_class,
    character_xp_total,
    character_deaths: {
      death_count: Number(death_count),
      reaper_fight_count: Number(reaper_fight_count),
    },
    commit: true,
  });
};

function getAttributeList(race, choice) {
  const attrArray = [];
  if (race.default_attributes) {
    attrArray.push(...race.default_attributes);
  }
  if (race.attribute_choice?.includes(choice)) {
    attrArray.push(choice);
  }
  return attrArray;
}

export const postNewCharacter = async ({
  character_name,
  race,
  chosen_attribute,
  character_class,
}) =>
  await post("/api/v1/user/characters", {
    character_name,
    character_race: {
      race: race.race_name,
      subculture: race.subculture_name,
      race_attributes: getAttributeList(race, chosen_attribute),
    },
    character_class,
  });

export const patchUserCharacterBasics = async ({
  charId,
  character_name,
  race,
  chosen_attribute,
  character_class,
  character_skills,
}) =>
  await saveCharacterBuild({
    charId,
    character_build: {
      character_name,
      character_race: {
        race: race.race_name,
        subculture: race.subculture_name,
        race_attributes: getAttributeList(race, chosen_attribute),
      },
      character_class,
      character_skills,
    },
  });

export const saveCharacterBuild = async ({ charId, character_build }) =>
  await patch(`/api/v1/user/characters/${charId}/build`, { character_build });

export const commitCharacterBuild = async ({ charId, character_build }) =>
  await patch(`/api/v1/user/characters/${charId}/build`, {
    character_build,
    commit: true,
  });

export const getCurrentUserCharacters = async () =>
  await get("/api/v1/user/characters");

export const getUnplayedUserCharacters = async () =>
  await get("/api/v1/user/characters?character_xp_total=10");

export const getCharacters = async () => await get("/api/v1/characters");

export const getCommittedCharacters = async () =>
  await get("/api/v1/characters?committed=true");

export const getCurrentUserCharacter = async (charId) =>
  await get(`/api/v1/user/characters/${charId}`);

export const getUserCharacter = async (userId, charId) =>
  await get(`/api/v1/users/${userId}/characters/${charId}`);

export const getCharactersByIds = async ({ charIds }) =>
  await get(`/api/v1/characters?id=${charIds.join(",")}`);

export const postCharacterXp = async ({ character_ids, xp_amount, reason }) => {
  if (character_ids.length === 0) {
    throw new DisplayError("Please select at least one character");
  }
  if (xp_amount === 0) {
    throw new DisplayError("Xp amount cannot be 0");
  }
  return await post(`/api/v1/characters/xp`, {
    character_ids,
    xp_amount,
    reason,
  });
};

export const postCharacterDeaths = async ({
  character_id,
  death_count,
  reaper_fight_count,
  event,
}) => {
  if (death_count === 0 && reaper_fight_count === 0) {
    throw new DisplayError(
      "One of death count or reaper fight count must not be 0"
    );
  }
  if (!character_id?.length) {
    throw new DisplayError("Must select a character");
  }
  if (!event?.length) {
    throw new DisplayError("Must provide an event to record deaths");
  }
  return await post(`/api/v1/characters/${character_id}/deaths`, {
    death_count,
    reaper_fight_count,
    event,
  });
};

export const postUserShards = async ({ user_ids, shard_amount, reason }) => {
  if (user_ids.length === 0) {
    throw new DisplayError("Please select at least one user");
  }
  if (shard_amount === 0) {
    throw new DisplayError("Shard amount cannot be 0");
  }
  return await post(`/api/v1/users/shards`, { user_ids, shard_amount, reason });
};

export const patchPastEventCounts = async ({ xp_sequences }) => {
  if (!Object.keys(xp_sequences).length) {
    throw new DisplayError("No changes to submit.");
  }
  return await patch("/api/v1/characters/bulk_update_past_event_counts", {
    xp_sequences,
  });
};

export const getUsers = async () => await get("/api/v1/users");

export const getPublishedEvents = async ({ extra_includes }) => {
  return await get(
    `/api/v1/events?include=event_registrations.character,attendance_records.character,survey_responses,event_registration_count,attendance_record_count,${extra_includes}&user=me&event_published=true`
  );
};

export const getEvents = async () =>
  await get(
    `/api/v1/events?include=event_registration_count,attendance_record_count,survey_response_count`
  );

export const getEvent = async ({ event_id }) =>
  await get(`/api/v1/events/${event_id}`);

export const getEventWithUserSurvey = async ({ event_id }) =>
  await get(`/api/v1/events/${event_id}?include=survey_responses&user=me`);

export const getEventAttendanceInfo = async ({ event_id }) =>
  await get(
    `/api/v1/events/${event_id}?include=event_registrations.character,event_registrations.user,attendance_records.character,attendance_records.user,survey_responses.user`
  );

export const getEventAttendanceForReview = async ({ event_id }) =>
  await get(
    `/api/v1/events/${event_id}?include=event_registrations,attendance_records`
  );

const clientSideEventValidation = (formData) => {
  if (
    new Date(formData.get("event_start_time")) >=
    new Date(formData.get("event_end_time"))
  ) {
    throw new DisplayError("Event end time must be after start time");
  }
};

export const postEvent = async (formData) => {
  clientSideEventValidation(formData);
  return await post("/api/v1/events", formData, {
    headers: undefined,
  });
};

export const updateEvent = async (formData) => {
  clientSideEventValidation(formData);
  return await patch(`/api/v1/events/${formData.get("event_id")}`, formData, {
    headers: undefined,
  });
};

export const publishEvent = async ({ event_id }) =>
  await patch(`/api/v1/events/${event_id}`, { event_published: true });

export const deleteEvent = async ({ event_id }) =>
  await deleteResource(`/api/v1/events/${event_id}`);

export const finalizeEvent = async ({ event_id, attendance_records }) =>
  await post(`/api/v1/events/${event_id}/finalize`, {
    attendance_records,
  });

export const submitEventRegistration = async ({
  event,
  character,
  event_role,
  question_answers,
}) =>
  await post("/api/v1/user/event_registrations", {
    event,
    character,
    event_role,
    question_answers,
  });

export const getCurrentUserEventRegistrations = async () =>
  await get("/api/v1/user/event_registrations");

export const getCurrentUserEventRegistration = async ({
  event_registration_id,
}) => await get(`/api/v1/user/event_registrations/${event_registration_id}`);

export const patchCurrentUserEventRegistration = async ({
  event_registration_id,
  character,
  event_role,
  question_answers,
}) =>
  await patch(`/api/v1/user/event_registrations/${event_registration_id}`, {
    character,
    event_role,
    question_answers,
  });

export const cancelCurrentUserEventRegistration = async ({
  event_registration_id,
}) =>
  await post(
    `/api/v1/user/event_registrations/${event_registration_id}/cancel`
  );

export const confirmEventRegistrations = async ({
  event_id,
  accept,
  waitlist,
  waitlist_messages,
}) =>
  await post(
    `/api/v1/events/${event_id}/event_registrations/confirm_registrations`,
    {
      accept,
      waitlist,
      waitlist_messages,
    }
  );

export const getDefaultRegistrationQuestions = async () =>
  await get("/api/v1/default_registration_questions");

export const patchDefaultRegistrationQuestions = async ({
  default_registration_questions,
}) =>
  await patch("/api/v1/default_registration_questions", {
    default_registration_questions,
  });

export const getSurveyQuestions = async () =>
  await get("/api/v1/default_survey_questions");

export const patchSurveyQuestions = async ({ default_survey_questions }) =>
  await patch("/api/v1/default_survey_questions", {
    default_survey_questions,
  });

export const getSurveyResponse = async ({ event }) =>
  await get(`/api/v1/user/survey/${event}`);

export const postSurveyResponse = async ({ event, survey_answers }) =>
  await post(`/api/v1/user/survey/${event}`, { survey_answers });

export const patchSurveyResponse = async ({ event, survey_answers }) =>
  await patch(`/api/v1/user/survey/${event}`, { survey_answers });

const getCachedItem = async (key, endpoint, forceUpdate) => {
  let cachedItem = forceUpdate ? null : localStorage.getItem(key);
  if (cachedItem && cachedItem !== "null") {
    cachedItem = JSON.parse(cachedItem);
  } else {
    const res = await get(endpoint);
    localStorage.setItem(key, JSON.stringify(res));
    cachedItem = res;
  }
  return cachedItem;
};

export const getSkills = async (forceUpdate) => {
  const skills = await getCachedItem("skills", "/api/v1/skills", forceUpdate);
  attachSkillMetaData(skills.skills);
  return skills;
};

export const getRaces = (forceUpdate) =>
  getCachedItem("races", "/api/v1/races", forceUpdate);

export const getTransformations = async (forceUpdate) =>
  getCachedItem("transformations", "/api/v1/transformations", forceUpdate);

export const getEventRoleTypes = async (forceUpdate) =>
  getCachedItem("event_role_types", "/api/v1/event_role_types", forceUpdate);

export const saveGameData = async () => {
  const { skills, races, transformations, event_role_types } = await get(
    "/api/v1/game_data"
  );
  localStorage.setItem("skills", JSON.stringify({ skills }));
  localStorage.setItem("races", JSON.stringify({ races }));
  localStorage.setItem("transformations", JSON.stringify({ transformations }));
  localStorage.setItem(
    "event_role_types",
    JSON.stringify({ event_role_types })
  );
};
