import { Link, useLoaderData } from "react-router-dom";

import Button from "react-bootstrap/Button";

import { getUsers } from "../../utils/apiInterface";
import UserTable from "../../components/UserTable";

function ManageUsersPage() {
  const { users } = useLoaderData();

  return (
    <>
      <h1>Admin</h1>
      <UserTable users={users} />
      <h2>Admin Tasks</h2>
      <Link to="/staff/manage_shards">
        <Button>Manage User Shards</Button>
      </Link>
      <Link to="/staff/manage_roles">
        <Button>Manage User Roles</Button>
      </Link>
    </>
  );
}

ManageUsersPage.loader = getUsers;

export default ManageUsersPage;
