import React, { useState } from "react";
import { useSubmit, useActionData } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { postSignup } from "../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import SendVerifyComponent from "../components/SendVerifyComponent";
import FormPage from "./FormPage";

function SignupPage() {
  const actionData = useActionData();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [privacyAck, setPrivacyAck] = useState(false);
  const [emailConsent, setEmailConsent] = useState(false);

  const submit = useSubmit();
  const handleSignup = submitFormFactory(
    {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      password_confirm: passwordConfirm,
      privacy_statement_accepted: privacyAck,
      email_consent: emailConsent,
    },
    submit
  );

  let form;

  if (actionData?.submitted === "true")
    form = <h2>Success! A verification email has been sent.</h2>;
  else if (actionData?.submitted === "error" && actionData.data?.user?.email)
    form = (
      <h2>
        You have been registered but the verification email was unable to be
        sent. Click here to attempt to resend the email:{" "}
        <SendVerifyComponent email={actionData.data.user.email} />
      </h2>
    );
  else if (
    actionData?.submitted === "invalid" &&
    actionData?.data?.error_type !== "validation"
  ) {
    form = (
      <>
        <div className="mb-2">
          Something went wrong during account creation. You may already be
          signed up, or another problem may have occurred. To verify your
          account, check your email for the verification link. If you would like
          the verification email re-sent, click the button below.
        </div>
        <SendVerifyComponent email={email} />
      </>
    );
  } else {
    form = (
      <SubmissionForm
        submitLabel="Sign Up"
        onSubmit={handleSignup}
        disableSubmit={!privacyAck}
        pendingMessage="Please wait while we sign you up..."
        errorMessage="Error signing up. Please try again later."
      >
        <h1>Sign up:</h1>
        <Form.Group as={Row} className="mb-3" controlId="formFirstName">
          <Form.Label column sm={2}>
            First Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formLastName">
          <Form.Label column sm={2}>
            Last Name
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formEmail">
          <Form.Label column sm={2}>
            Email
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPassword">
          <Form.Label column sm={2}>
            Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formConfirmPassword">
          <Form.Label column sm={2}>
            Confirm Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={passwordConfirm}
              onChange={(event) => setPasswordConfirm(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPrivacy">
          <Col sm={1}>
            <Form.Check
              type="checkbox"
              checked={privacyAck}
              onChange={() => {
                setPrivacyAck(!privacyAck);
              }}
            />
          </Col>
          <Col sm={11}>
            <Form.Label>
              I acknowledge and accept that Forgotten Empires staff and database
              admins will be able to view my user information and character
              sheets. Any questions can be directed to info@forgottenempires.ca
            </Form.Label>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formEmailConsent">
          <Col sm={1}>
            <Form.Check
              type="checkbox"
              value={emailConsent}
              onChange={() => {
                setEmailConsent(!emailConsent);
              }}
            />
          </Col>
          <Col sm={11}>
            <Form.Label>
              I would like to recieve email notifications regarding updates to
              my account/characters, such as when my characters recieve XP.{" "}
            </Form.Label>
          </Col>
        </Form.Group>
      </SubmissionForm>
    );
  }

  return (
    <FormPage backLabel="Back to login" backLink="/login">
      {form}
    </FormPage>
  );
}

SignupPage.action = submissionActionFactory(postSignup);

export default SignupPage;
