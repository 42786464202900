import Card from "react-bootstrap/Card";
import RegistrationSummary from "./RegistrationSummary";
import EventCard from "./EventCard";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";
import {
  isEventPassed,
  isEventStarted,
  isPreRegActive,
  isSurveyActive,
  preRegPeriodRemaining,
  surveyPeriodRemaining,
} from "event-helper";
import AttendanceSummary from "./AttendanceSummary";
import VisibleForRoles from "../VisibleForRoles";

function EditableEventRegistrationCard({
  event,
  full,
  setSelectedEvent,
  characters,
}) {
  const event_registration = event.event_registrations?.[0];
  const attendance_record = event.attendance_records?.[0];
  const survey = event.survey_responses?.[0];

  const passed = isEventPassed(event);
  const started = isEventStarted(event);
  const showRegistration =
    event_registration &&
    (!passed || event_registration?.state === "accepted") &&
    !event.event_finalized;
  const registrationCancelled = event_registration?.state === "cancelled";

  const preRegActive = isPreRegActive(event);
  const preRegRemaining = preRegPeriodRemaining(event);

  const displaySurvey =
    event.event_survey_questions?.length > 0 &&
    attendance_record &&
    isSurveyActive(event);
  const surveyRemaining = surveyPeriodRemaining(event);

  return (
    <EventCard event={event} full={full}>
      {showRegistration && (
        <Card.Text className={`${full ? "border-top " : ""}mb-2`} as="div">
          <RegistrationSummary
            event_registration={event_registration}
            full={full}
          />
        </Card.Text>
      )}
      {attendance_record && (
        <Card.Text className={`${full ? "border-top " : ""}mb-2`} as="div">
          <AttendanceSummary attendance_record={attendance_record} />
        </Card.Text>
      )}
      {preRegActive && !event.event_finalized && (
        <div>
          {event_registration && event_registration.state !== "cancelled"
            ? "Days left to edit"
            : "Days left to pre-register"}
          : {preRegRemaining}
        </div>
      )}
      {displaySurvey && <div>📋 Survey closes in: {surveyRemaining} days</div>}
      <Card.Text>
        {full ? (
          (showRegistration || !started) &&
          !event.event_finalized && (
            <Link
              to={
                event_registration
                  ? `/event_registration/${event_registration._id}/edit`
                  : `/event_registration/${event._id}`
              }
              style={characters.length ? null : { "pointer-events": "none" }}
            >
              <Button disabled={!characters.length}>
                {!event_registration || (registrationCancelled && !started) ? (
                  "Register"
                ) : (
                  <>View{preRegActive && "/Edit"} Registration Details</>
                )}
              </Button>
            </Link>
          )
        ) : (
          <Button className="me-2" onClick={() => setSelectedEvent(event)}>
            View
          </Button>
        )}
        <VisibleForRoles roles={["staff"]}>
          <Link to={`/staff/manage_events/${event._id}/registrations`}>
            <Button>Review Attendance</Button>
          </Link>
        </VisibleForRoles>
        {displaySurvey && (
          <Link to={`/event/${event._id}/survey${survey ? "/edit" : ""}`}>
            <Button>Take Survey</Button>
          </Link>
        )}
      </Card.Text>
    </EventCard>
  );
}

export default EditableEventRegistrationCard;
