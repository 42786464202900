import Table from "react-bootstrap/Table";

export class UserTableColumn {
  constructor(header, className, dataFunction) {
    this.header = header;
    this.className = className;
    this.getData = dataFunction;
  }
}

export default function UserTable({
  users,
  leftColumns,
  rightColumns,
  hideShards,
}) {
  return (
    <Table responsive>
      <thead>
        <tr>
          {leftColumns?.map((tableColumn, index) => (
            <td key={index}>{tableColumn.header}</td>
          ))}
          <th>User</th>
          {!hideShards && <th>Shards</th>}
          {rightColumns?.map((tableColumn, index) => (
            <th key={index}>{tableColumn.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <tr key={user._id}>
              {leftColumns?.map((tableColumn, index) => (
                <td className={tableColumn.className} key={index}>
                  {tableColumn.getData(user)}
                </td>
              ))}
              <td>{user.first_name + " " + user.last_name}</td>
              {!hideShards && <td>{user.shard_total} Shards</td>}
              {rightColumns?.map((rightColumns, index) => (
                <td className={rightColumns.className} key={index}>
                  {rightColumns.getData(user)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
