import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  decreaseSkillPurchaseAmount,
  getCharacterSkill,
  getSpecialtyPyramid,
  getSpellSchoolOptions,
  increaseSkillPurchaseAmount,
  pyramidLevelCanDecrease,
  pyramidLevelCanIncrease,
  setSpellSchool,
  displaySkillCostForLevel,
  removeLastSpellSchool,
  getAllowedSpellSchoolOptions,
  skillIsNecessary,
  skillBreaksBuild,
  getSkillPurchaseCount,
  characterHasSkill,
} from "character-build-helper";
import SkillSelectionCounter from "./SkillSelectionCounter";
import { useState } from "react";

export default function SpellSchoolSelector({
  characterBuild,
  setCharacterBuild,
  committedBuild,
  characterLevel,
  skill,
  schoolIndex,
  options,
  allSkills,
  additionalSchools,
  maxXp,
}) {
  const characterSkill = getCharacterSkill(characterBuild, skill);

  const [openNEXPModal, setOpenNEXPModal] = useState(false);

  const schoolOptions = getSpellSchoolOptions(
    characterBuild,
    skill,
    schoolIndex,
    additionalSchools
  );

  if (
    schoolIndex !== 0 &&
    (!characterSkill.levels[schoolIndex - 1] ||
      characterSkill.levels[schoolIndex - 1][0] === 0)
  ) {
    return null;
  }

  const handleSpellSchoolChange = (event) =>
    setCharacterBuild(
      event.target.value === ""
        ? removeLastSpellSchool(characterBuild, skill)
        : setSpellSchool(characterBuild, skill, schoolIndex, event.target.value)
    );

  let allowedSchools = getAllowedSpellSchoolOptions(
    schoolOptions,
    characterLevel,
    characterBuild,
    skill,
    skill.prereq_skills
  );

  if (characterSkill.spell_schools[schoolIndex]) {
    allowedSchools = allowedSchools.filter((spellSchool) => {
      const buildWithSchool = setSpellSchool(
        characterBuild,
        skill,
        schoolIndex,
        spellSchool
      );
      // check if changing the school from what it currently is would break the build
      if (skillBreaksBuild(skill, buildWithSchool, characterLevel, options)) {
        return false;
      }
      return true;
    });
  }

  const committedSpellSchool =
    committedBuild &&
    getCharacterSkill(committedBuild, skill).spell_schools[schoolIndex];

  const schoolSelector = (
    <span>
      <Form.Select
        className="text-capitalize"
        onChange={handleSpellSchoolChange}
        value={characterSkill.spell_schools[schoolIndex] || ""}
        disabled={committedSpellSchool}
        style={committedSpellSchool ? { pointerEvents: "none" } : {}}
      >
        {!characterSkill.levels[schoolIndex] ||
        characterSkill.levels[schoolIndex][0] === 0 ? (
          <option value={""}>Not selected</option>
        ) : null}
        {schoolOptions?.map((opt) => (
          <option
            key={opt}
            value={opt}
            disabled={!allowedSchools.includes(opt)}
          >
            {opt.replaceAll("_", " ")}
          </option>
        ))}
      </Form.Select>
    </span>
  );

  return (
    <>
      <Form.Group className="my-2" as={Row}>
        <Col sm={5} md={3} xxl={2}>
          <Form.Label>School {schoolIndex + 1}:</Form.Label>
        </Col>
        <Col>
          {committedSpellSchool ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Spell school committed</Tooltip>}
            >
              {schoolSelector}
            </OverlayTrigger>
          ) : (
            schoolSelector
          )}
        </Col>
      </Form.Group>
      <Modal show={openNEXPModal} onHide={() => setOpenNEXPModal(false)}>
        <Modal.Body>Not enough XP</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenNEXPModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {[...Array(9)].map((_, index) => {
        const level = index + 1;

        const skillCost = displaySkillCostForLevel(
          skill,
          characterBuild,
          level,
          allSkills,
          schoolIndex
        );

        const purchaseCount = getSkillPurchaseCount(
          characterBuild,
          skill,
          null,
          level,
          schoolIndex
        );

        const purchaseSlot = () => {
          const updatedBuild = increaseSkillPurchaseAmount(
            characterBuild,
            skill,
            null,
            level,
            schoolIndex,
            allSkills
          );

          if (updatedBuild.character_build_cost > maxXp) {
            setOpenNEXPModal(true);
          } else {
            setCharacterBuild(updatedBuild);
          }
        };
        const undoPurchaseSlot = () => {
          let updatedBuild = decreaseSkillPurchaseAmount(
            characterBuild,
            skill,
            null,
            level,
            schoolIndex,
            allSkills
          );
          if (level === 1 && purchaseCount === 1) {
            updatedBuild = removeLastSpellSchool(updatedBuild, skill);
          }

          if (updatedBuild.character_build_cost > maxXp) {
            setOpenNEXPModal(true);
          } else {
            setCharacterBuild(updatedBuild);
          }
        };

        let canIncrease = false;
        let increaseToolTip;
        if (!characterSkill.spell_schools[schoolIndex]) {
          increaseToolTip = "Select a shool before purchasing spell slots";
        } else if (
          !pyramidLevelCanIncrease(
            getSpecialtyPyramid(characterBuild, skill, schoolIndex),
            level
          )
        ) {
        } else {
          canIncrease = true;
        }

        let canDecrease = false;
        let decreaseToolTip;
        if (!characterSkill.spell_schools[schoolIndex]) {
        } else if (purchaseCount === 0) {
        } else if (
          committedBuild &&
          getSkillPurchaseCount(
            characterBuild,
            skill,
            null,
            level,
            schoolIndex
          ) ===
            getSkillPurchaseCount(
              committedBuild,
              skill,
              null,
              level,
              schoolIndex
            )
        ) {
          decreaseToolTip = "Cannot decrease below committed amount";
        } else if (
          level === 1 &&
          purchaseCount === 1 &&
          schoolIndex !== 2 &&
          characterSkill.spell_schools[schoolIndex + 1]
        ) {
          decreaseToolTip = "Cannot decrease to 0 while having further schools";
        } else if (
          !pyramidLevelCanDecrease(
            getSpecialtyPyramid(characterBuild, skill, schoolIndex),
            level
          )
        ) {
        } else if (
          skillIsNecessary(
            skill,
            characterLevel,
            characterBuild,
            null,
            level,
            schoolIndex,
            allSkills
          )
        ) {
          // Find blocking skills
          decreaseToolTip =
            "This slot is a required prerequisite for one or more of the following: " +
            skill.prereq_of
              .filter((prereqSkill) =>
                characterHasSkill(characterBuild, prereqSkill, options)
              )
              .map((prereqSkill) => prereqSkill.skill_name)
              .join(", ");
        } else {
          canDecrease = true;
        }

        return (
          <Row className="mb-2" key={level}>
            <Col xs={4} sm={3} md={2} xxl={1}>
              Slot Level: {level}
            </Col>
            <Col xs={2} md={1}>
              {skillCost} XP
            </Col>
            <Col>
              <SkillSelectionCounter
                representation={[...Array(purchaseCount)].map((_, index) => (
                  <span className="slot-card" key={index}>
                    {level}
                  </span>
                ))}
                increase={purchaseSlot}
                decrease={undoPurchaseSlot}
                increaseToolTip={increaseToolTip}
                decreaseToolTip={decreaseToolTip}
                canIncrease={canIncrease}
                canDecrease={canDecrease}
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
}
