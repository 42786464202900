import EditableCharacterSheet from "./EditableCharacterSheet";
import ViewableCharacterSheet from "./ViewableCharacterSheet";

export default function CharacterSheetFactory({
  character,
  skills,
  children,
  skin,
  mode,
}) {
  const CharacterSheetClass =
    mode === "edit"
      ? EditableCharacterSheet
      : mode === "view"
      ? ViewableCharacterSheet
      : null;
  const characterSheetStyleClass =
    skin === "print"
      ? "print-character-sheet"
      : skin === "player" || skin === "admin"
      ? "character-sheet"
      : null;
  return (
    <CharacterSheetClass
      characterSheetStyleClass={characterSheetStyleClass}
      character={character}
      skills={skills}
      children={children}
      mode={mode}
    />
  );
}
