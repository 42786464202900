import { useEffect, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";

import SubmissionForm, {
  submitFormFactory,
  submissionActionFactory,
} from "../../components/SubmissionForm";

import {
  getSurveyQuestions,
  patchSurveyQuestions,
} from "../../utils/apiInterface";
import FormPage from "../FormPage";
import RegistrationQuestionInput from "../../components/inputs/RegistrationQuestionInput";

function UpdateSurveyQuestionsPage() {
  const submit = useSubmit();
  const actionData = useActionData();
  const { survey_questions } = useLoaderData();

  const mapWorkingQuestions = (questions) => {
    return questions.map((question) =>
      Object.assign({}, question, {
        selected: true,
        original_text: question.question_text,
        answer_required: question.answer_required,
      })
    );
  };

  const [surveyQuestions, setSurveyQuestions] = useState(
    mapWorkingQuestions(survey_questions)
  );

  useEffect(() => {
    if (actionData) {
      setSurveyQuestions(
        mapWorkingQuestions(actionData.data.default_survey_questions)
      );
    }
  }, [actionData]);

  const handleSubmit = submitFormFactory(
    {
      default_survey_questions: RegistrationQuestionInput.questionsToSend(
        surveyQuestions,
        null,
        true
      ),
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_events">
      <SubmissionForm
        onSubmit={handleSubmit}
        successMessage="Update was successful."
        pendingMessage="Please wait while the survey questions are updated."
        invalidMessage="There was a problem with your submission. Please try again."
        errorMessage="There was a problem with the server. Please try again later."
      >
        <h2>Update Survey Questions:</h2>
        <RegistrationQuestionInput
          questions={surveyQuestions}
          setQuestions={setSurveyQuestions}
          displayRequiredCheckbox={true}
        />
      </SubmissionForm>
    </FormPage>
  );
}

UpdateSurveyQuestionsPage.loader = async () => {
  const { default_survey_questions } = await getSurveyQuestions();

  return { survey_questions: default_survey_questions };
};

UpdateSurveyQuestionsPage.action =
  submissionActionFactory(patchSurveyQuestions);

export default UpdateSurveyQuestionsPage;
