import { useEffect } from "react";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default function AttributeDropDown({
  race,
  chosenAttribute,
  setChosenAttribute,
  savedBuild,
}) {
  const default_attrs = race.default_attributes;
  const choice_attrs = race.attribute_choice;

  const transformed = !!savedBuild?.character_race.transformation;

  useEffect(() => {
    if (choice_attrs && !choice_attrs.includes(chosenAttribute)) {
      setChosenAttribute(choice_attrs[0]);
    }
  });

  if (!default_attrs && !choice_attrs) {
    return <>None</>;
  }
  return (
    <>
      {(transformed
        ? savedBuild.character_race.race_attributes
        : default_attrs
      )?.map((attr) => (
        <div key={attr}>
          <Button className="text-capitalize disabled btn-badge mb-2">
            {attr}
          </Button>
        </div>
      ))}
      {!transformed && choice_attrs ? (
        <Form.Select
          className="text-capitalize"
          onChange={(e) => {
            setChosenAttribute(e.target.value);
          }}
          value={chosenAttribute}
        >
          {choice_attrs.map((attr) => (
            <option key={attr}>{attr}</option>
          ))}
        </Form.Select>
      ) : null}
    </>
  );
}
