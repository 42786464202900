import React from "react";
import { Navigate, useActionData, useNavigation } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import UnauthorizedError from "../utils/errors/UnauthorizedError";
import DisplayError from "../utils/errors/DisplayError";

export function submissionActionFactory(submitFunction) {
  return async ({ request }) => {
    try {
      let body;
      if (/multipart\/form-data/.test(request.headers.get("content-type"))) {
        body = await request.formData();
      } else {
        body = await request.json();
      }
      const res = await submitFunction(body);
      let resBody = {};
      if (resBody !== 204) {
        resBody = await res.json();
      }
      if (res.status < 300 && res.status >= 200) {
        return { submitted: "true", data: resBody.data };
      } else if (res.status < 600 && res.status >= 500) {
        return {
          submitted: "error",
          data: resBody.data,
          errorMessage: resBody.message,
        };
      } else if (res.status < 500 && res.status >= 400) {
        return {
          submitted: "invalid",
          data: resBody.data,
          errorMessage: resBody.message,
        };
      }
    } catch (err) {
      if (err instanceof UnauthorizedError) {
        throw err;
      } else if (err instanceof DisplayError) {
        return { submitted: "error", errorMessage: err.message };
      } else {
        return { submitted: "error" };
      }
    }
  };
}

export function submitFormFactory(data, submit, options) {
  return (event) => {
    event.preventDefault();
    options ||= {};
    options.method ||= "post";
    options.encType ||= "application/json";
    if (options.encType === "multipart/form-data") {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      data = formData;
    }
    submit(data, options);
  };
}

export default function SubmissionForm({
  onSubmit,
  navigateOnSubmit,
  submitLabel,
  disableSubmit,
  successMessage,
  pendingMessage,
  errorMessage,
  invalidMessage,
  children,
}) {
  const actionData = useActionData();
  const navigation = useNavigation();

  if (actionData?.submitted === "true" && navigateOnSubmit) {
    return <Navigate to={navigateOnSubmit} />;
  }

  return (
    <Form onSubmit={onSubmit}>
      {children}
      <Form.Group as={Row}>
        <Col>
          <Button type="submit" disabled={disableSubmit}>
            {submitLabel || "Submit"}
          </Button>
          {navigation.state === "submitting" ? (
            pendingMessage
          ) : (
            <>
              {actionData?.submitted === "true"
                ? successMessage
                : actionData?.submitted === "error"
                ? actionData?.errorMessage || errorMessage
                : actionData?.submitted === "invalid"
                ? actionData?.errorMessage || invalidMessage
                : null}
            </>
          )}
        </Col>
      </Form.Group>
    </Form>
  );
}
