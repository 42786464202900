import {
  Navigate,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { getSkills } from "../../utils/apiInterface";

import Eye from "../../assets/eye-background.svg";
import PrintCharacterViewer from "../../components/characterSheets/PrintCharacterViewer";

function PrintCharacterSheetsPage() {
  const { skills } = useLoaderData();
  const { state } = useLocation();
  const navigate = useNavigate();
  let characters;

  try {
    characters = state.characters;
  } catch (err) {
    if (err.name === "TypeError") {
      return <Navigate to="/staff/select_character_sheets" />;
    } else {
      throw err;
    }
  }

  return (
    <>
      <Button className="back-button dark-button" onClick={() => navigate(-1)}>
        Back
      </Button>
      <Container fluid id="print-character-sheet-page">
        <img className="background-image" src={Eye} alt="logo"></img>
        <Row>
          <Col>
            <h1 className="dont-print">
              Press Ctrl+P to print character sheets
            </h1>
          </Col>
        </Row>
        {characters.map((character) => (
          <PrintCharacterViewer
            key={character._id}
            character={character}
            skills={skills}
          />
        ))}
      </Container>
    </>
  );
}

PrintCharacterSheetsPage.loader = getSkills;

export default PrintCharacterSheetsPage;
