import { Link } from "react-router-dom";

export default function AdminLinks() {
  return (
    <>
      <span className="behave-like-link">Admin Tasks:</span>
      <Link to="/staff/manage_characters">Manage Characters</Link>
      <Link to="/staff/manage_users">Manage Users</Link>
      <Link to="/staff/manage_events">Manage Events</Link>
    </>
  );
}
