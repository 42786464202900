function AttendanceSummary({ attendance_record }) {
  return (
    <>
      ✅ Attended as: {attendance_record.event_role !== "pc" && "NPC ("}
      {attendance_record.character.committed_build?.character_name ||
        attendance_record.character.saved_build.character_name}
      {attendance_record.event_role !== "pc" && ")"}
    </>
  );
}

export default AttendanceSummary;
