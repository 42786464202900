import { useState } from "react";
import { Navigate, useFetcher } from "react-router-dom";

import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../FetchComponent";
import ConfirmationModal from "../ConfirmationModal";

import { cancelCurrentUserEventRegistration } from "../../utils/apiInterface";
import Button from "react-bootstrap/esm/Button";

export default function CancelRegistrationButton({ event_registration }) {
  const [modalOpen, setModalOpen] = useState(false);
  const cancelEventRegistrationFetcher = useFetcher();

  const cancelEventRegistration = submitFetchFactory(
    { event_registration_id: event_registration._id },
    {
      method: "post",
      action: `/cancel_event_registration`,
    },
    cancelEventRegistrationFetcher
  );

  return cancelEventRegistrationFetcher.data?.fetched === "true" ? (
    <Navigate to="/events"></Navigate>
  ) : (
    <FetchComponent
      fetcher={cancelEventRegistrationFetcher}
      errorMessage="Something went wrong. Please try again later."
      invalidMessage="Something went wrong. Please reload the page and try again."
    >
      <Button className="btn-danger mt-3" onClick={() => setModalOpen(true)}>
        Cancel Registration
      </Button>
      <ConfirmationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onConfirm={cancelEventRegistration}
        confirmationButtonText="Confirm"
        allowConfirm={true}
      >
        Warning: Are you sure you want to cancel your registration?
      </ConfirmationModal>
    </FetchComponent>
  );
}

CancelRegistrationButton.cancelEventRegistrationAction = fetchActionFactory(
  cancelCurrentUserEventRegistration
);
