import React, { useState } from "react";
import { Link, useSubmit, useActionData, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { patchResetPassword } from "../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import FormPage from "./FormPage";

function ResetPasswordPage() {
  const { token } = useParams();
  const actionData = useActionData();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const submit = useSubmit();
  const handleReset = submitFormFactory(
    {
      password,
      password_confirm: passwordConfirm,
      token,
    },
    submit
  );

  let page;

  if (actionData?.submitted === "true") {
    page = (
      <>
        <h2>Password successfully changed</h2>
        <Link to="/login">
          <Button>Return to login</Button>
        </Link>
      </>
    );
  } else {
    page = (
      <SubmissionForm
        onSubmit={handleReset}
        submitLabel="Submit"
        pendingMessage="Please wait while we reset your password..."
        errorMessage="Error resetting password. Please try again later."
        invalidMessage="Invalid submission. Please try again."
      >
        <h1>Enter your new password:</h1>
        <Form.Group as={Row} className="mb-3" controlId="formPassword">
          <Form.Label column sm={2}>
            Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formPasswordConfirm">
          <Form.Label column sm={2}>
            Confirm Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              value={passwordConfirm}
              onChange={(event) => setPasswordConfirm(event.target.value)}
            />
          </Col>
        </Form.Group>
      </SubmissionForm>
    );
  }

  return <FormPage>{page}</FormPage>;
}

ResetPasswordPage.action = submissionActionFactory(patchResetPassword);

export default ResetPasswordPage;
