import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import Form from "react-bootstrap/Form";

import SubmissionForm, {
  submitFormFactory,
  submissionActionFactory,
} from "../../components/SubmissionForm";

import { getUsers, postUserRoles } from "../../utils/apiInterface";
import FormPage from "../FormPage";
import UserTable, { UserTableColumn } from "../../components/UserTable";

function ManageUserShardsPage() {
  const submit = useSubmit();
  const { users } = useLoaderData();

  const [updatedUsers, setUpdatedUsers] = useState(
    Object.fromEntries(users.map((user) => [user._id, { ...user }]))
  );

  const toggleRole = (role) => {
    return (event) => {
      const user = updatedUsers[event.target.value];
      if (user.roles.includes(role)) {
        setUpdatedUsers({
          ...updatedUsers,
          [event.target.value]: {
            ...user,
            roles: user.roles.filter((userRole) => userRole !== role),
          },
        });
      } else {
        setUpdatedUsers({
          ...updatedUsers,
          [event.target.value]: {
            ...user,
            roles: [...user.roles, role],
          },
        });
      }
    };
  };

  const handleSubmit = submitFormFactory(
    {
      users: Object.fromEntries(
        Object.values(updatedUsers)
          .filter((user) => {
            const existingRoles = users.find(
              ({ _id }) => _id === user._id
            ).roles;
            return (
              existingRoles.length !== user.roles.length ||
              existingRoles.some((role) => !user.roles.includes(role))
            );
          })
          .map((user) => [user._id, user.roles])
      ),
    },
    submit
  );

  return (
    <>
      <FormPage backLabel="Back" backLink="/staff/manage_users">
        <SubmissionForm
          onSubmit={handleSubmit}
          successMessage="Roles granted successfully."
          pendingMessage="Please wait while the roles are granted..."
          invalidMessage="There was a problem with your submission. Please try again."
          errorMessage="There was a problem with the server. Please try again later."
        >
          <h2>User Roles:</h2>
          <UserTable
            users={Object.values(updatedUsers)}
            hideShards={true}
            rightColumns={[
              new UserTableColumn("staff", null, (user) => (
                <Form.Group>
                  <Form.Check
                    checked={user.roles.includes("staff")}
                    value={user._id}
                    onChange={toggleRole("staff")}
                    disabled={user.roles.includes("admin")}
                  />
                </Form.Group>
              )),
              new UserTableColumn("admin", null, (user) => (
                <Form.Group>
                  <Form.Check
                    checked={user.roles.includes("admin")}
                    value={user._id}
                    disabled={true}
                  />
                </Form.Group>
              )),
            ]}
          />
        </SubmissionForm>
      </FormPage>
    </>
  );
}

ManageUserShardsPage.loader = getUsers;

ManageUserShardsPage.action = submissionActionFactory(postUserRoles);

export default ManageUserShardsPage;
