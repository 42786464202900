import { Link, useLoaderData } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";

import EventCardList from "../../components/eventCards/EventCardList";
import ManageEventCard from "../../components/eventCards/ManageEventCard";

import { getEvents } from "../../utils/apiInterface";
import { isEventPassed } from "event-helper";

function ManageEventsPage() {
  const { events } = useLoaderData();

  const futureEvents = events.filter((event) => !isEventPassed(event));
  const pastEvents = events.filter((event) => isEventPassed(event)).reverse();

  return (
    <>
      <h1>Admin</h1>
      <Link to="/staff/create_event">
        <Button>Create New Event</Button>
      </Link>
      <Link to="/staff/update_default_registration_questions">
        <Button>Update Default Registration Questions</Button>
      </Link>
      <Link to="/staff/update_survey_questions">
        <Button>Update Survey Questions</Button>
      </Link>
      <EventCardList event_card_type={ManageEventCard} events={futureEvents} />
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Past Events</Accordion.Header>
          <Accordion.Body>
            {pastEvents.length ? (
              <EventCardList
                event_card_type={ManageEventCard}
                events={pastEvents}
              />
            ) : (
              "No events to display."
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

ManageEventsPage.loader = getEvents;

export default ManageEventsPage;
