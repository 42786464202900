import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import FormPage from "./FormPage";
import { submissionActionFactory } from "../components/SubmissionForm";

import {
  getEventWithUserSurvey,
  patchSurveyResponse,
} from "../utils/apiInterface";
import SurveyForm from "../components/forms/SurveyForm";

function EditSurveyPage() {
  const { event } = useLoaderData();
  const survey_response = event.survey_responses[0];

  const [questionAnswers, setQuestionAnswers] = useState(
    Object.fromEntries(
      survey_response?.survey_answers.map((question_answer) => [
        question_answer.survey_question,
        question_answer.question_answer,
      ]) || []
    )
  );

  return (
    <FormPage backLabel="Back" backLink="/events">
      <h1>Event Survey: {event.event_title}</h1>
      <SurveyForm
        event={event}
        questionAnswers={questionAnswers}
        setQuestionAnswers={setQuestionAnswers}
      />
    </FormPage>
  );
}

EditSurveyPage.loader = async ({ params }) => {
  const { event } = await getEventWithUserSurvey({
    event_id: params.event_id,
  });
  return { event };
};

EditSurveyPage.action = submissionActionFactory(patchSurveyResponse);

export default EditSurveyPage;
