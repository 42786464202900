import React from "react";
import { useRouteError, Navigate } from "react-router-dom";
import UnauthorizedError from "../utils/errors/UnauthorizedError";

export default function ErrorPage() {
  const error = useRouteError();

  if (error instanceof UnauthorizedError) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
