import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import FormPage from "../FormPage";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../../components/SubmissionForm";

import {
  getDefaultRegistrationQuestions,
  getEventRoleTypes,
  postEvent,
} from "../../utils/apiInterface";
import EditEventForm from "../../components/forms/EditEventForm";
import RegistrationQuestionInput from "../../components/inputs/RegistrationQuestionInput";

function CreateEventPage() {
  const { default_registration_questions, event_role_types } = useLoaderData();

  const submit = useSubmit();
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [eventAddress, setEventAddress] = useState("");
  const [eventImage, setEventImage] = useState(null);

  const mapWorkingQuestions = (questions) => {
    return questions.map((question) =>
      Object.assign({}, question, {
        selected: true,
        original_text: question.question_text,
      })
    );
  };

  const [registrationQuestions, setRegistrationQuestions] = useState(
    mapWorkingQuestions(default_registration_questions)
  );

  const handleSubmit = submitFormFactory(
    {
      event_title: eventTitle,
      event_description: eventDescription,
      event_address: eventAddress,
      event_start_time: startTime,
      event_end_time: endTime,
      event_image: eventImage,
      event_registration_questions: JSON.stringify(
        RegistrationQuestionInput.questionsToSend(
          registrationQuestions,
          event_role_types
        ).map((question) => {
          return {
            question_text: question.question_text,
            roles_to_ask: question.roles_to_ask,
          };
        })
      ),
    },
    submit,
    { encType: "multipart/form-data" }
  );

  const imageSelector = (
    <Form.Group as={Row} className="mb-3" controlId="formImage">
      <Form.Label column sm={4}>
        Image
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          type="file"
          onChange={(event) => setEventImage(event.target.files[0])}
        />
      </Col>
    </Form.Group>
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_events">
      <h1>Create Event</h1>
      <SubmissionForm
        onSubmit={handleSubmit}
        navigateOnSubmit="/staff/manage_events"
        submitLabel="Create"
        pendingMessage="Please wait while the event is created..."
        errorMessage="There was a problem with the server. Please try again later."
        invalidMessage="There was a problem with your submission. Please try again."
      >
        <EditEventForm
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          eventDescription={eventDescription}
          setEventDescription={setEventDescription}
          eventAddress={eventAddress}
          setEventAddress={setEventAddress}
          imageSelector={imageSelector}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          questions={registrationQuestions}
          setQuestions={setRegistrationQuestions}
          eventRoleTypes={event_role_types}
        />
      </SubmissionForm>
    </FormPage>
  );
}

CreateEventPage.loader = async () => {
  const { default_registration_questions } =
    await getDefaultRegistrationQuestions();
  const { event_role_types } = await getEventRoleTypes();

  return { default_registration_questions, event_role_types };
};

CreateEventPage.action = submissionActionFactory(postEvent);

export default CreateEventPage;
