import AdminRuleset from "./rulesets/AdminRuleset";
import CharacterSheetFactory from "./factory/CharacterSheetFactory";

export default function AdminCharacterEditor({ character, skills, children }) {
  return (
    <CharacterSheetFactory
      character={character}
      skills={skills}
      children={children}
      skin="admin"
      mode="view"
      ruleset={AdminRuleset}
    />
  );
}
