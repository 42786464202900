import { useState } from "react";
import { Link, useLoaderData, useParams, useSubmit } from "react-router-dom";

import Accordion from "react-bootstrap/esm/Accordion";
import Button from "react-bootstrap/esm/Button";
import ListGroup from "react-bootstrap/esm/ListGroup";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/esm/Form";
import Table from "react-bootstrap/esm/Table";

import {
  finalizeEvent,
  getCharacters,
  getEventAttendanceForReview,
  getEventRoleTypes,
  getUsers,
} from "../../utils/apiInterface";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../../components/SubmissionForm";
import { xpFunction } from "event-helper";
import ConfirmationModal from "../../components/ConfirmationModal";

export default function PostEventWorkflowPage() {
  const { event, users, characters, event_role_types } = useLoaderData();

  const { event_registrations, attendance_records } = event;

  const [attendanceList, setAttendanceList] = useState(
    Object.fromEntries(
      attendance_records.map((attendance) => [
        attendance.user._id,
        {
          ...attendance,
        },
      ])
    )
  );
  const attendedUserIds = Object.keys(attendanceList);

  const registeredUsers = Object.values(event_registrations).map(
    ({ user }) => user._id
  );
  const unregisteredList = users.filter(
    ({ _id }) => !registeredUsers.includes(_id)
  );

  const submit = useSubmit();

  const [modalOpen, setModalOpen] = useState(false);

  const params = useParams();

  const handleSubmit = submitFormFactory(
    {
      event_id: params.event_id,
      attendance_records: Object.fromEntries(
        Object.entries(attendanceList)
          .map(([key, attendance]) => [
            key,
            Object.fromEntries([
              ...Object.entries(attendance).filter(([key]) =>
                [
                  "event_role",
                  "deaths",
                  "reaper_fights",
                  "xp",
                  "xp_sequence",
                  "npc_shards",
                  "donation_shards",
                  "donation_items",
                ].includes(key)
              ),
              ["character", attendance.character._id],
            ]),
          ])
          .map(([key, attendance]) => {
            const donation_items = !!attendance.donation_shards
              ? attendance.donation_items
              : "";
            return [key, { ...attendance, donation_items }];
          })
      ),
    },
    submit
  );

  const updateAttendanceList = (htmlEvent) => {
    const user_id = htmlEvent.target.value;
    const attendanceRecord = attendance_records.find(
      ({ user }) => user._id === user_id
    );

    if (
      Object.keys(attendanceList).find(
        (attendanceUserId) => attendanceUserId === user_id
      )
    ) {
      setAttendanceList(
        Object.fromEntries(
          Object.entries(attendanceList).filter(
            ([attendanceUserId]) => user_id !== attendanceUserId
          )
        )
      );
    } else {
      let newRecord;
      if (attendanceRecord) {
        newRecord = attendanceRecord;
      } else {
        const character = characters.find(({ user }) => user._id === user_id);
        newRecord = {
          character,
          event_role: "pc",
          deaths: 0,
          reaper_fights: 0,
          xp: xpFunction(character.character_xp_sequence, event),
          xp_sequence: character.character_xp_sequence + 1,
          npc_shards: 0,
          donation_shards: 0,
          donation_items: "",
        };
      }

      setAttendanceList({
        ...attendanceList,
        [user_id]: newRecord,
      });
    }
  };

  const updateEventRole = (event) => {
    const newUpdatedRegistrations = { ...attendanceList };
    const updatedAttendance = attendanceList[event.target.dataset.user];
    updatedAttendance.event_role = event.target.value;
    if (updatedAttendance.event_role === "npc") {
      updatedAttendance.npc_shards = event_registrations.find(
        ({ user }) => user._id === event.target.dataset.user
      )
        ? 30
        : 20;
    } else {
      updatedAttendance.npc_shards = 0;
    }
    setAttendanceList(newUpdatedRegistrations);
  };

  const updateCharacter = (htmlEvent) => {
    const newAttendanceList = { ...attendanceList };
    const updatedAttendance = attendanceList[htmlEvent.target.dataset.user];
    const newCharacter = characters.find(
      ({ _id }) => _id === htmlEvent.target.value
    );
    const existingAttendance = attendance_records.find(
      ({ user, character }) => {
        return (
          user._id === htmlEvent.target.dataset.user &&
          character._id === newCharacter._id
        );
      }
    );

    updatedAttendance.character = newCharacter;
    updatedAttendance.xp_sequence = existingAttendance
      ? newCharacter.character_xp_sequence
      : newCharacter.character_xp_sequence + 1;
    updatedAttendance.xp =
      existingAttendance?.xp ||
      xpFunction(newCharacter.character_xp_sequence, event);
    updatedAttendance.deaths = existingAttendance?.deaths || 0;
    updatedAttendance.reaper_fights = existingAttendance?.reaper_fights || 0;

    setAttendanceList(newAttendanceList);
  };

  const updateValue = (attribute) => {
    return (user_id, value) => {
      const newAttendanceList = {
        ...attendanceList,
      };
      newAttendanceList[user_id][attribute] = +value;
      setAttendanceList(newAttendanceList);
    };
  };

  const updateXpSequence = updateValue("xp_sequence");

  const updateXp = updateValue("xp");

  const updateDeaths = updateValue("deaths");

  const updateReaperFights = updateValue("reaper_fights");

  const updateNpcShards = updateValue("npc_shards");

  const updateDonationShards = updateValue("donation_shards");

  const updateDonationItems = (user_id, value) => {
    const newAttendanceList = {
      ...attendanceList,
    };
    newAttendanceList[user_id]["donation_items"] = value;
    setAttendanceList(newAttendanceList);
  };

  return (
    <>
      <Link to="/staff/manage_events">
        <Button className="back-button dark-button">Back</Button>
      </Link>
      <Row className="full-page">
        <Col>
          <Row className="mb-3">
            <h1 className="ms-5">Review Attendance for {event.event_title}</h1>
          </Row>
          <ConfirmationModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onConfirm={handleSubmit}
            allowConfirm={true}
          >
            Have you ensured that the info you are submitting is correct?
          </ConfirmationModal>
          <SubmissionForm
            onSubmit={(event) => {
              event.preventDefault();
              setModalOpen(true);
            }}
            navigateOnSubmit="/staff/manage_events"
            pendingMessage="Please wait while your updates are submitted..."
            errorMessage="There was a problem with the server. Please try again later."
            invalidMessage="There was a problem with your submission. Please try again."
            submitLabel="Submit and Publish Survey"
          >
            <Row className="mb-3">
              <Col xs={3}>
                <Accordion defaultActiveKey="registrations">
                  <Accordion.Item
                    eventKey={"registrations"}
                    disabled={!event_registrations.length}
                  >
                    <Accordion.Header>
                      Registered Users ({event_registrations.length})
                    </Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                      <ListGroup variant="flush">
                        {event_registrations.map((reg) => (
                          <ListGroup.Item
                            key={reg._id}
                            style={{ textAlign: "left" }}
                          >
                            <div className="d-flex justify-content-between">
                              {reg.user.first_name} {reg.user.last_name}
                              {reg.event_role === "pc" && (
                                <>
                                  :{" "}
                                  {reg.character.committed_build
                                    ?.character_name ||
                                    reg.character.saved_build.character_name}
                                </>
                              )}
                            </div>
                            <Form.Check
                              value={reg.user._id}
                              checked={attendedUserIds.includes(reg.user._id)}
                              onChange={updateAttendanceList}
                            />
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    eventKey={"unregistered"}
                    disabled={!unregisteredList.length}
                  >
                    <Accordion.Header>
                      Unregistered Users ({unregisteredList.length})
                    </Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                      <ListGroup variant="flush">
                        {unregisteredList.map((user) => (
                          <ListGroup.Item
                            key={user._id}
                            style={{ textAlign: "left" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              {user.first_name} {user.last_name}
                              <Form.Check
                                value={user._id}
                                checked={attendedUserIds.includes(user._id)}
                                onChange={updateAttendanceList}
                              />
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col xs={9} className="viewing-window">
                <Table>
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Character</th>
                      <th>Role</th>
                      <th>
                        XP Sequence
                        <br />
                        (Events attended)
                      </th>
                      <th>XP</th>
                      <th>Deaths</th>
                      <th>Reaper Fights</th>
                      <th>NPC Shards</th>
                      <th>Donation Shards</th>
                      <th>Donation Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(attendanceList).map(
                      ([user_id, attendance]) => {
                        const user = users.find(({ _id }) => _id === user_id);
                        const userCharacters = characters.filter(
                          ({ user }) => user._id === user_id
                        );
                        return (
                          <tr key={user._id}>
                            <td>{user.first_name + " " + user.last_name}</td>
                            <td>
                              <Form.Group>
                                <Form.Select
                                  value={attendance.character._id}
                                  onChange={updateCharacter}
                                  data-user={user_id}
                                >
                                  {userCharacters.map((character) => (
                                    <option
                                      value={character._id}
                                      key={character._id}
                                    >
                                      {character.committed_build
                                        ?.character_name ||
                                        character.saved_build.character_name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </td>
                            <td className="text-uppercase">
                              <Form.Group>
                                <Form.Select
                                  className={`text-uppercase`}
                                  value={attendance.event_role}
                                  onChange={updateEventRole}
                                  data-user={user_id}
                                >
                                  {Object.keys(event_role_types).map((type) => (
                                    <option
                                      className="text-uppercase"
                                      value={type}
                                      key={type}
                                    >
                                      {type}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </td>
                            <td className="text-center">
                              <div
                                className="d-flex text-nowrap"
                                style={{ gap: 8 }}
                              >
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    value={attendance.xp_sequence}
                                    onChange={(event) =>
                                      updateXpSequence(
                                        user._id,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: 8 }}>
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    value={attendance.xp}
                                    onChange={(event) =>
                                      updateXp(user._id, event.target.value)
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: 8 }}>
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    value={attendance.deaths}
                                    onChange={(event) =>
                                      updateDeaths(user._id, event.target.value)
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: 8 }}>
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    value={attendance.reaper_fights}
                                    onChange={(event) =>
                                      updateReaperFights(
                                        user._id,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: 8 }}>
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    value={attendance.npc_shards}
                                    onChange={(event) =>
                                      updateNpcShards(
                                        user._id,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: 8 }}>
                                <Form.Group>
                                  <Form.Control
                                    type="number"
                                    value={attendance.donation_shards}
                                    onChange={(event) =>
                                      updateDonationShards(
                                        user._id,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex" style={{ gap: 8 }}>
                                <Form.Group>
                                  <Form.Control
                                    style={
                                      !attendance.donation_shards
                                        ? { textDecoration: "line-through" }
                                        : {}
                                    }
                                    disabled={!attendance.donation_shards}
                                    value={attendance.donation_items}
                                    onChange={(event) =>
                                      updateDonationItems(
                                        user._id,
                                        event.target.value
                                      )
                                    }
                                  />
                                </Form.Group>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </SubmissionForm>
        </Col>
      </Row>
    </>
  );
}

PostEventWorkflowPage.loader = async ({ params }) => {
  const { event_id } = params;

  const { users } = await getUsers();
  const { characters } = await getCharacters();
  const { event } = await getEventAttendanceForReview({ event_id });
  event.event_registrations = event.event_registrations.map((reg) => {
    return {
      ...reg,
      character: characters.find((char) => char._id === reg.character),
      user: users.find((user) => user._id === reg.user),
    };
  });
  event.attendance_records = event.attendance_records.map((attendance) => {
    return {
      ...attendance,
      character: characters.find((char) => char._id === attendance.character),
      user: users.find((user) => user._id === attendance.user),
    };
  });
  const { event_role_types } = await getEventRoleTypes();

  return {
    event,
    users,
    characters,
    event_role_types,
  };
};

PostEventWorkflowPage.action = submissionActionFactory(finalizeEvent);
