import Card from "react-bootstrap/Card";
import { displayEndTime, displayStartTime } from "event-helper";

function EventCard({ event, full, children }) {
  return (
    <Card>
      {event.event_image && (
        <Card.Img
          variant="top"
          src={`/api/v1/images/${event.event_image.filename}`}
        />
      )}
      <Card.Body>
        <Card.Title>{event.event_title}</Card.Title>
        <Card.Text>
          👥{" "}
          {event.attendance_record_count > 0
            ? ` ${event.attendance_record_count} Attended${
                Object.keys(event).includes("survey_response_count")
                  ? `, ${event.survey_response_count} Surveys Completed `
                  : ""
              }`
            : Object.keys(event).includes("pc_registration_count")
            ? `${event.pc_registration_count} PCs, ${event.npc_registration_count} NPCs Registered`
            : `${event.event_registration_count} Registered`}
        </Card.Text>
        <Card.Text className="d-flex mb-2" as="div">
          <div className="me-2">📅</div>
          {displayStartTime(event)} -
          <br />
          {displayEndTime(event)}
        </Card.Text>
        {full && (
          <>
            <Card.Text className="d-flex">📍 {event.event_address}</Card.Text>
            <Card.Text
              style={{
                whiteSpace: "pre-line",
                maxHeight: "300px",
                overflowY: "scroll",
              }}
            >
              {event.event_description}
            </Card.Text>
          </>
        )}
        {children}
      </Card.Body>
    </Card>
  );
}

export default EventCard;
