import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import RegistrationQuestionInput from "../inputs/RegistrationQuestionInput";

export default function EditEventForm({
  eventTitle,
  setEventTitle,
  eventDescription,
  setEventDescription,
  eventAddress,
  setEventAddress,
  imageSelector,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  questions,
  setQuestions,
  eventRoleTypes,
  published,
}) {
  return (
    <>
      <Form.Group as={Row} className="mb-3" controlId="formTitle">
        <Form.Label column sm={4}>
          Title
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Event Title"
            required
            value={eventTitle}
            onChange={(event) => setEventTitle(event.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formDescription">
        <Form.Label column sm={4}>
          Description
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="textarea"
            cols="75"
            rows="10"
            placeholder="Event Description"
            required
            value={eventDescription}
            onChange={(event) => setEventDescription(event.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formAddress">
        <Form.Label column sm={4}>
          Address
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="Event Address"
            required
            value={eventAddress}
            onChange={(event) => setEventAddress(event.target.value)}
          />
        </Col>
      </Form.Group>
      {imageSelector}
      <Form.Group as={Row} className="mb-3" controlId="formStartTime">
        <Form.Label column sm={4}>
          Start Time
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="datetime-local"
            required
            value={startTime}
            onChange={(event) => setStartTime(event.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formEndTime">
        <Form.Label column sm={4}>
          End Time
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="datetime-local"
            required
            value={endTime}
            onChange={(event) => setEndTime(event.target.value)}
          />
        </Col>
      </Form.Group>
      <h3 className="mb-3">Questions</h3>
      <RegistrationQuestionInput
        questions={questions}
        setQuestions={setQuestions}
        eventRoleTypes={eventRoleTypes}
        published={published}
      />
    </>
  );
}
