import { useContext, useEffect, useState } from "react";
import { useLoaderData, Link, useFetcher, useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";

import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../../components/FetchComponent";
import ReviewRegistrationModal from "../../components/ReviewRegistrationModal";
import AdminEventRegistrationDisplay from "../../components/AdminEventRegistrationDisplay";

import {
  confirmEventRegistrations,
  getEventAttendanceInfo,
  getCharactersByIds,
} from "../../utils/apiInterface";
import Tabs from "react-bootstrap/esm/Tabs";
import { isEventPassed } from "event-helper";
import VisibleForRoles from "../../components/VisibleForRoles";
import { RolesContext } from "../../context/RolesContext";
import InsufficientPermissionsError from "../../utils/errors/InsufficientPermissionsError";

function ManageEventRegistrationsPage() {
  const roles = useContext(RolesContext);

  if (!roles.includes("admin") && !roles.includes("staff")) {
    throw new InsufficientPermissionsError("Not Found");
  }

  const { event } = useLoaderData();
  const { event_registrations, attendance_records, survey_responses } = event;
  const eventPassed = isEventPassed(event);
  const characterSheetFetcher = useFetcher();
  const navigate = useNavigate();

  const accepted_pcs = event_registrations.filter(
    ({ event_role, state }) => state === "accepted" && event_role === "pc"
  );
  const accepted_npcs = event_registrations.filter(
    ({ event_role, state }) => state === "accepted" && event_role === "npc"
  );
  const waitlisted = event_registrations.filter(
    ({ state }) => state === "waitlisted"
  );
  const unreviewed = event_registrations.filter(({ state }) => state === null);
  const cancelled = event_registrations.filter(
    ({ state }) => state === "cancelled"
  );

  const attended_pcs = attendance_records.filter(
    ({ event_role }) => event_role === "pc"
  );
  const attended_npcs = attendance_records.filter(
    ({ event_role }) => event_role === "npc"
  );

  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [selectedSurveyQuestion, setSelectedSurveyQuestion] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);

  const getAcceptedCharacters = submitFetchFactory(
    { charIds: accepted_pcs.map(({ character }) => character._id) },
    {
      method: "post",
      action: `/staff/manage_events/${event._id}/registrations/get_characters_by_ids`,
    },
    characterSheetFetcher
  );

  const registrationListAccordionItem = (eventKey, title, registrationList) => {
    return (
      <Accordion.Item eventKey={eventKey} disabled={!registrationList.length}>
        <Accordion.Header>
          {title} ({registrationList.length})
        </Accordion.Header>
        <Accordion.Body style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {registrationList.map((reg) => (
              <ListGroup.Item
                key={reg._id}
                onClick={() => setSelectedRegistration(reg)}
                as="button"
                style={{ textAlign: "left" }}
              >
                {reg.user.first_name} {reg.user.last_name}
                {reg.event_role === "pc" && (
                  <>
                    :{" "}
                    {reg.character.committed_build?.character_name ||
                      reg.character.saved_build.character_name}
                  </>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const attendanceRecordAccordianItem = (eventKey, title, attendanceRecord) => {
    return (
      <Accordion.Item eventKey={eventKey} disabled={!attendanceRecord.length}>
        <Accordion.Header>
          {title} ({attendanceRecord.length})
        </Accordion.Header>
        <Accordion.Body style={{ padding: 0 }}>
          <ListGroup variant="flush">
            {attendanceRecord.map((attendance) => (
              <ListGroup.Item
                key={attendance._id}
                onClick={() => setSelectedAttendance(attendance)}
                as="button"
                style={{ textAlign: "left" }}
              >
                {attendance.user.first_name} {attendance.user.last_name}
                {attendance.event_role === "pc" && (
                  <>
                    :{" "}
                    {attendance.character.committed_build?.character_name ||
                      attendance.character.saved_build.character_name}
                  </>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  const questionAnswerDisplay = (event_registrations, question) => {
    const question_answers = [];
    event_registrations.forEach((event_registration) => {
      const question_answer = event_registration.question_answers.find(
        (qa) => qa.event_registration_question === question._id
      )?.question_answer;

      if (question_answer) {
        question_answers.push({ event_registration, question_answer });
      }
    });

    return (
      <>
        <div>
          Question: <strong>{question.question_text}</strong>
        </div>
        {question_answers.length ? (
          question_answers.map(({ event_registration, question_answer }) => (
            <div className="mt-3" key={event_registration.user._id}>
              {event_registration.user.first_name}{" "}
              {event_registration.user.last_name}:{" "}
              {event_registration.event_role === "pc"
                ? event_registration.character.committed_build
                    ?.character_name ||
                  event_registration.character.saved_build.character_name
                : "NPC"}
              <br />- {question_answer}
            </div>
          ))
        ) : (
          <div className="mt-3">
            This question has not been answered by any user
          </div>
        )}
      </>
    );
  };

  const surveyResponseDisplay = (survey_responses, question) => {
    const question_answers = [];
    survey_responses.forEach((survey_response) => {
      const question_answer = survey_response.survey_answers.find(
        (qa) => qa.survey_question === question._id
      )?.question_answer;

      if (question_answer) {
        question_answers.push({ survey_response, question_answer });
      }
    });

    return (
      <>
        <div>
          Question: <strong>{question.question_text}</strong>
        </div>
        {question_answers.length ? (
          question_answers.map(({ survey_response, question_answer }) => (
            <div className="mt-3" key={survey_response.user._id}>
              {survey_response.user.first_name} {survey_response.user.last_name}
              <br />- {question_answer}
            </div>
          ))
        ) : (
          <div className="mt-3">
            This question has not been answered by any user
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (
      characterSheetFetcher.data?.fetched === "true" &&
      characterSheetFetcher.data?.data?.characters?.length
    ) {
      navigate("/staff/print_character_sheets", {
        state: {
          characters: characterSheetFetcher.data?.data?.characters,
        },
      });
    }
  }, [characterSheetFetcher, navigate]);

  return (
    <>
      <Link to={-1}>
        <Button className="back-button dark-button">Back</Button>
      </Link>
      <Row className="full-page">
        <Col>
          <Row className="mb-3">
            <h1 className="ms-5">Review Attendance for {event.event_title}</h1>
          </Row>
          <Tabs
            defaultActiveKey={
              attendance_records.length ? "attendance" : "registrations"
            }
          >
            <Tab
              eventKey="registrations"
              title={`Registrations (${
                event_registrations.length - cancelled.length
              })`}
            >
              <Row>
                <Col xs={3}>
                  <Accordion defaultActiveKey="pcs">
                    {registrationListAccordionItem(
                      "pcs",
                      "Accepted PCs",
                      accepted_pcs
                    )}
                    {registrationListAccordionItem(
                      "npcs",
                      "Accepted NPCs",
                      accepted_npcs
                    )}
                    {registrationListAccordionItem(
                      "waitlist",
                      "Waitlisted",
                      waitlisted
                    )}
                    {registrationListAccordionItem(
                      "unreviewed",
                      "Unreviewed",
                      unreviewed
                    )}
                    {registrationListAccordionItem(
                      "cancelled",
                      "Cancelled",
                      cancelled
                    )}
                  </Accordion>
                </Col>
                <Col xs={9} className="viewing-window">
                  {selectedRegistration && (
                    <AdminEventRegistrationDisplay
                      event={event}
                      user={selectedRegistration.user}
                    />
                  )}
                </Col>
              </Row>
              <VisibleForRoles roles={["admin"]}>
                {!eventPassed && (
                  <Row className="mt-3">
                    <Col className="d-flex flex-column align-items-start button-column">
                      <Button onClick={() => setModalOpen(true)}>
                        Review Registrations (
                        {
                          event_registrations.filter(
                            ({ state }) =>
                              !["accepted", "waitlisted", "cancelled"].includes(
                                state
                              )
                          ).length
                        }
                        )
                      </Button>
                      <ReviewRegistrationModal
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        event={event}
                        eventRegistrations={event_registrations.filter(
                          (reg) => reg.state !== "cancelled"
                        )}
                      />
                      <FetchComponent
                        fetcher={characterSheetFetcher}
                        pendingMessage="Loading..."
                        invalidMessage="Invalid request."
                        errorMessage="Unable to fetch characters. Please try again later."
                      >
                        <Button
                          disabled={!accepted_pcs.length}
                          onClick={getAcceptedCharacters}
                        >
                          Print Characters
                        </Button>
                      </FetchComponent>
                    </Col>
                  </Row>
                )}
              </VisibleForRoles>
            </Tab>
            {!!event.event_registration_questions.length && (
              <Tab
                eventKey="registration-questions"
                title="Registration Answers"
              >
                <Row>
                  <Col xs={3}>
                    <ListGroup variant="flush" className="me-3">
                      {event.event_registration_questions.map((question) => (
                        <ListGroup.Item
                          key={question._id}
                          onClick={() => setSelectedQuestion(question)}
                          as="button"
                          style={{ textAlign: "left" }}
                        >
                          {question.question_text}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col className="viewing-window" xs={9}>
                    {selectedQuestion &&
                      questionAnswerDisplay(
                        event_registrations,
                        selectedQuestion
                      )}
                  </Col>
                </Row>
              </Tab>
            )}
            {!!attendance_records.length && (
              <Tab
                eventKey="attendance"
                title={`Attendance (${attendance_records.length})`}
              >
                <Row>
                  <Col xs={3}>
                    <Accordion defaultActiveKey="pcs">
                      {attendanceRecordAccordianItem(
                        "pcs",
                        "PCs",
                        attended_pcs
                      )}
                      {attendanceRecordAccordianItem(
                        "npcs",
                        "NPCs",
                        attended_npcs
                      )}
                    </Accordion>
                  </Col>
                  <Col xs={9} className="viewing-window">
                    {selectedAttendance && (
                      <AdminEventRegistrationDisplay
                        event={event}
                        user={selectedAttendance.user}
                        attendanceFirst={true}
                      />
                    )}
                  </Col>
                </Row>
              </Tab>
            )}
            {!!attendance_records.length && (
              <Tab
                eventKey="survey"
                title={`Surveys (${survey_responses.length})`}
              >
                <Row>
                  <Col xs={3}>
                    <ListGroup variant="flush" className="me-3">
                      {event.event_survey_questions.map((question) => (
                        <ListGroup.Item
                          key={question._id}
                          onClick={() => setSelectedSurveyQuestion(question)}
                          as="button"
                          style={{ textAlign: "left" }}
                        >
                          {question.question_text}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                  <Col className="viewing-window" xs={9}>
                    {selectedSurveyQuestion &&
                      surveyResponseDisplay(
                        survey_responses,
                        selectedSurveyQuestion
                      )}
                  </Col>
                </Row>
              </Tab>
            )}
          </Tabs>
        </Col>
      </Row>
    </>
  );
}

ManageEventRegistrationsPage.loader = async ({ params }) => {
  const { event_id } = params;
  const event = await getEventAttendanceInfo({
    event_id,
  });

  return { ...event };
};

ManageEventRegistrationsPage.fetchCharactersAction =
  fetchActionFactory(getCharactersByIds);

ManageEventRegistrationsPage.confirmRegAction = fetchActionFactory(
  confirmEventRegistrations
);

export default ManageEventRegistrationsPage;
