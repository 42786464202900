import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import UserSelectionTable from "../../components/inputs/UserSelectionTable";
import SubmissionForm, {
  submitFormFactory,
  submissionActionFactory,
} from "../../components/SubmissionForm";

import { getUsers, postUserShards } from "../../utils/apiInterface";
import FormPage from "../FormPage";

function ManageUserShardsPage() {
  const submit = useSubmit();
  const { users } = useLoaderData();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [shardAmount, setShardAmount] = useState(0);
  const [reason, setReason] = useState("");

  const handleSubmit = submitFormFactory(
    {
      user_ids: selectedUsers,
      shard_amount: shardAmount,
      reason,
    },
    submit
  );

  return (
    <>
      <FormPage backLabel="Back" backLink="/staff/manage_users">
        <SubmissionForm
          onSubmit={handleSubmit}
          successMessage="Shards granted successfully."
          pendingMessage="Please wait while the shards are granted..."
          invalidMessage="There was a problem with your submission. Please try again."
          errorMessage="There was a problem with the server. Please try again later."
        >
          <h2>User Shards:</h2>
          <UserSelectionTable
            allUsers={users}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
          <Form.Group as={Row} className="mb-3" controlId="formShardAmount">
            <Form.Label column sm={2}>
              Shard Amount
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="number"
                required
                placeholder="Number of Shards"
                value={shardAmount}
                onChange={(event) => setShardAmount(event.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formReason">
            <Form.Label column sm={2}>
              Reason
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                required
                placeholder="Reason for granting the shards"
                value={reason}
                onChange={(event) => setReason(event.target.value)}
              />
            </Col>
          </Form.Group>
        </SubmissionForm>
      </FormPage>
    </>
  );
}

ManageUserShardsPage.loader = getUsers;

ManageUserShardsPage.action = submissionActionFactory(postUserShards);

export default ManageUserShardsPage;
