export default function BaseCharacterInfo({ characterBuild }) {
  return (
    <>
      {characterBuild.character_name},{" "}
      <span className="text-capitalize">
        {[
          characterBuild.character_race.subculture,
          characterBuild.character_race.race,
          characterBuild.character_race.transformation,
        ].join(" ")}{" "}
        {characterBuild.character_class}
      </span>
    </>
  );
}
