import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AlchemySpecialtySelector from "./AlchemySpecialtySelector";

export default function AlchemyPurchaseInput({
  characterBuild,
  setCharacterBuild,
  committedBuild,
  characterLevel,
  skill,
  allSkills,
  maxXp,
}) {
  return (
    <>
      <Row>
        <Col>
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{skill.skill_description}</Tooltip>}
          >
            <span className="bold-skill-name">{skill.skill_name}</span>
          </OverlayTrigger>
        </Col>
      </Row>
      {[...Array(3)].map((_, specialtyIndex) => (
        <AlchemySpecialtySelector
          key={specialtyIndex}
          allSkills={allSkills}
          skill={skill}
          characterBuild={characterBuild}
          setCharacterBuild={setCharacterBuild}
          committedBuild={committedBuild}
          characterLevel={characterLevel}
          specialtyIndex={specialtyIndex}
          maxXp={maxXp}
        />
      ))}
    </>
  );
}
