import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/esm/Button";

function RegistrationQuestionInput({
  questions,
  setQuestions,
  eventRoleTypes,
  published,
  displayRequiredCheckbox,
}) {
  const isQuestionUpdated = (question) => {
    return (
      question.original_text &&
      question.original_text.trim() !== question.question_text.trim()
    );
  };

  const updateQuestionText = (question, question_text) => {
    const newQuestions = [...questions];
    const questionIndex = newQuestions.indexOf(question);
    newQuestions[questionIndex] = {
      ...question,
      question_text,
    };
    setQuestions(newQuestions);
  };

  const setAnswerRequired = (question) => {
    const newQuestions = [...questions];
    const questionIndex = newQuestions.indexOf(question);
    newQuestions[questionIndex] = {
      ...question,
      answer_required: !newQuestions[questionIndex].answer_required,
    };
    setQuestions(newQuestions);
  };

  const selectQuestion = (question) => {
    const newQuestions = [...questions];
    const questionIndex = questions.indexOf(question);

    newQuestions[questionIndex] = {
      ...question,
      selected: !question.selected,
    };
    setQuestions(newQuestions);
  };

  const deleteQuestion = (question) => {
    setQuestions(questions.filter((q) => q !== question));
  };

  const flipRole = (question, role) => {
    const newQuestions = [...questions];
    const questionIndex = newQuestions.indexOf(question);
    const newQuestion = {
      ...question,
      roles_to_ask: [...question.roles_to_ask],
    };
    if (newQuestion.roles_to_ask.includes(role)) {
      newQuestion.roles_to_ask = newQuestion.roles_to_ask.filter(
        (r) => r !== role
      );
    } else {
      newQuestion.roles_to_ask.push(role);
    }
    newQuestions[questionIndex] = newQuestion;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { question_text: "", roles_to_ask: [], selected: true },
    ]);
  };

  const resetQuestion = (question) => {
    const newQuestions = [...questions];
    const questionIndex = newQuestions.indexOf(question);
    newQuestions[questionIndex] = {
      ...question,
      question_text: question.original_text,
    };
    setQuestions(newQuestions);
  };

  return (
    <>
      <Row>
        <Col xs={1}>Select:</Col>
        <Col xs={11} sm={7}></Col>
        {displayRequiredCheckbox && (
          <Col sm={1} className="d-none d-sm-block">
            Required:
          </Col>
        )}
        {eventRoleTypes && (
          <Col sm={2} className="d-none d-sm-block">
            Roles:
          </Col>
        )}
      </Row>
      {questions
        .filter((question) => question._id)
        .map((question) => {
          return (
            <div key={question._id}>
              <Row>
                <Col xs={11} sm={7} className="offset-1">
                  {isQuestionUpdated(question)
                    ? question.original_text + " ->"
                    : null}
                </Col>
              </Row>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  className="d-flex justify-content-center align-items-center"
                  xs={1}
                  controlId={`select-${question._id}`}
                >
                  <Form.Check
                    disabled={published}
                    checked={question.selected}
                    onChange={() => selectQuestion(question)}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  xs={11}
                  sm={7}
                  className="d-flex align-items-center"
                  controlId={`question-${question._id}`}
                >
                  <Form.Control
                    type="text"
                    required
                    style={{
                      textDecorationLine: question.selected
                        ? "none"
                        : "line-through",
                    }}
                    disabled={!question.selected}
                    placeholder="Question text"
                    value={question.question_text}
                    onChange={(event) =>
                      updateQuestionText(question, event.target.value)
                    }
                  />
                </Form.Group>
                {displayRequiredCheckbox && (
                  <Form.Group
                    as={Col}
                    className="d-flex justify-content-center align-items-center"
                    xs={1}
                    controlId={`required-${question._id}`}
                  >
                    <Form.Check
                      checked={question.answer_required}
                      onChange={() => setAnswerRequired(question)}
                    />
                  </Form.Group>
                )}
                {eventRoleTypes && (
                  <Form.Group
                    as={Col}
                    sm={2}
                    className="offset-1 offset-sm-0"
                    controlId={`question-roles-${question._id}`}
                  >
                    {Object.keys(eventRoleTypes).map((role_abbrev) => (
                      <Form.Check
                        key={`${role_abbrev}-${question._id}`}
                        disabled={!question.selected}
                        label={role_abbrev.toUpperCase()}
                        checked={question.roles_to_ask.includes(role_abbrev)}
                        onChange={() => flipRole(question, role_abbrev)}
                      />
                    ))}
                  </Form.Group>
                )}
                {isQuestionUpdated(question) ? (
                  <Col sm={1}>
                    <Button onClick={() => resetQuestion(question)}>
                      Reset
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </div>
          );
        })}
      {questions.some((question) => !question._id) ? (
        <h4>New Questions</h4>
      ) : null}
      {questions
        .filter((question) => !question._id)
        .map((question, index) => {
          return (
            <Row key={index} className="mb-3">
              <Col xs={1} className="d-flex align-items-center">
                <Button onClick={() => deleteQuestion(question)}>🗙</Button>
              </Col>
              <Form.Group
                as={Col}
                xs={11}
                sm={7}
                className="d-flex align-items-center"
                controlId={`question-${index}`}
              >
                <Form.Control
                  type="text"
                  required
                  style={{
                    textDecorationLine: question.selected
                      ? "none"
                      : "line-through",
                  }}
                  disabled={!question.selected}
                  placeholder="Question text"
                  value={question.question_text}
                  onChange={(event) =>
                    updateQuestionText(question, event.target.value)
                  }
                />
              </Form.Group>
              {displayRequiredCheckbox && (
                <Form.Group
                  as={Col}
                  className="d-flex justify-content-center align-items-center"
                  xs={1}
                  controlId={`required-${question._id}`}
                >
                  <Form.Check
                    checked={question.answer_required}
                    onChange={() => setAnswerRequired(question)}
                  />
                </Form.Group>
              )}
              {eventRoleTypes && (
                <Form.Group
                  as={Col}
                  sm={2}
                  className="offset-1 offset-sm-0"
                  controlId={`question-roles-${index}`}
                >
                  {Object.keys(eventRoleTypes).map((role_abbrev) => (
                    <Form.Check
                      key={`${role_abbrev}-${question._id}`}
                      label={role_abbrev.toUpperCase()}
                      checked={question.roles_to_ask.includes(role_abbrev)}
                      onChange={() => flipRole(question, role_abbrev)}
                    />
                  ))}
                </Form.Group>
              )}
            </Row>
          );
        })}
      <Col className="mb-3">
        <Button onClick={addQuestion}>Add Question</Button>
      </Col>
    </>
  );
}

RegistrationQuestionInput.questionsToSend = (
  questions,
  eventRoleTypes,
  someAnswersRequired
) => {
  return questions
    .filter((question) => question.selected)
    .map((question) => {
      const questionToSend = {
        _id: question._id,
        question_text: question.question_text,
      };
      if (eventRoleTypes) {
        questionToSend.roles_to_ask = Array.from(
          new Set(
            question.roles_to_ask.filter((role) =>
              Object.keys(eventRoleTypes).includes(role)
            )
          )
        );
      }
      if (someAnswersRequired) {
        questionToSend.answer_required = question.answer_required;
      }
      return questionToSend;
    });
};

export default RegistrationQuestionInput;
