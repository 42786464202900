import { Link } from "react-router-dom";
import EventCard from "./EventCard";
import Button from "react-bootstrap/esm/Button";
import PublishEventButton from "../buttons/PublishEventButton";
import DeleteEventButton from "../buttons/DeleteEventButton";
import { isEventPassed, isEventStarted } from "event-helper";

function ManageEventCard({ event }) {
  const eventStarted = isEventStarted(event);
  const eventEnded = isEventPassed(event);

  return (
    <EventCard event={event}>
      <div className="d-flex" style={{ gap: 8 }}>
        <Link to={`/staff/edit_event/${event._id}`}>
          <Button>Edit</Button>
        </Link>
        {event.event_published && (
          <Link to={`/staff/manage_events/${event._id}/registrations`}>
            <Button>Review Attendance</Button>
          </Link>
        )}
        {!eventStarted && !event.event_published && (
          <PublishEventButton event={event} />
        )}
        {eventEnded && event.event_published && (
          <Link to={`/staff/manage_events/${event._id}/post_event`}>
            <Button>Post-Event Workflow</Button>
          </Link>
        )}
        {!event.event_finalized && <DeleteEventButton event={event} />}
      </div>
    </EventCard>
  );
}

export default ManageEventCard;
