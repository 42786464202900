import { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";

import FormPage from "../FormPage";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../../components/SubmissionForm";

import {
  getDefaultRegistrationQuestions,
  getEvent,
  getEventRoleTypes,
  updateEvent,
} from "../../utils/apiInterface";
import EditEventForm from "../../components/forms/EditEventForm";
import RegistrationQuestionInput from "../../components/inputs/RegistrationQuestionInput";

const datetimeLocalString = (datetime) => {
  const dt = new Date(datetime);
  dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
  return dt.toISOString().slice(0, -1);
};

function EditEventPage() {
  const { event, event_role_types } = useLoaderData();

  const submit = useSubmit();
  const [eventTitle, setEventTitle] = useState(event.event_title);
  const [eventDescription, setEventDescription] = useState(
    event.event_description
  );
  const [startTime, setStartTime] = useState(
    datetimeLocalString(event.event_start_time)
  );
  const [endTime, setEndTime] = useState(
    datetimeLocalString(event.event_end_time)
  );
  const [eventAddress, setEventAddress] = useState(event.event_address);
  const [eventImage, setEventImage] = useState(null);
  const [newImage, setNewImage] = useState(false);

  const mapWorkingQuestions = (questions) => {
    return questions.map((question) =>
      Object.assign({}, question, {
        selected: true,
        original_text: question.question_text,
      })
    );
  };

  const [registrationQuestions, setRegistrationQuestions] = useState(
    mapWorkingQuestions(event.event_registration_questions)
  );

  const handleSubmit = submitFormFactory(
    {
      event_id: event._id,
      event_title: eventTitle,
      event_description: eventDescription,
      event_address: eventAddress,
      event_start_time: startTime,
      event_end_time: endTime,
      event_image: eventImage,
      set_event_image: newImage,
      event_registration_questions: JSON.stringify(
        RegistrationQuestionInput.questionsToSend(
          registrationQuestions,
          event_role_types
        )
      ),
    },
    submit,
    { encType: "multipart/form-data", method: "patch" }
  );

  const imageSelector = (
    <Form.Group as={Row} className="mb-3" controlId="formImage">
      <Form.Label column sm={4}>
        Image
      </Form.Label>
      <Col sm={8}>
        <Form.Check
          inline
          name="image-select"
          type="radio"
          id="current-image-check"
          label="Use current"
          checked={!newImage}
          onChange={() => {
            setNewImage(false);
            setEventImage(null);
          }}
        />
        <Form.Check
          inline
          name="image-select"
          type="radio"
          label="New image"
          id="new-image-check"
          checked={newImage}
          onChange={() => {
            setNewImage(true);
          }}
        />
        {newImage ? (
          <Form.Control
            type="file"
            onChange={(event) => setEventImage(event.target.files[0])}
          />
        ) : (
          <div>
            Current Image:{" "}
            {event.event_image ? (
              <div>
                <Image
                  src={`/api/v1/images/${event.event_image.filename}`}
                  width="100%"
                  style={{ maxWidth: "400px" }}
                />
              </div>
            ) : (
              "No Image"
            )}
          </div>
        )}
      </Col>
    </Form.Group>
  );

  return (
    <FormPage backLabel="Back" backLink="/staff/manage_events">
      <h1>Edit Event</h1>
      <SubmissionForm
        onSubmit={handleSubmit}
        navigateOnSubmit="/staff/manage_events"
        submitLabel="Update"
        pendingMessage="Please wait while the event is created..."
        errorMessage="There was a problem with the server. Please try again later."
        invalidMessage="There was a problem with your submission. Please try again."
      >
        <EditEventForm
          eventTitle={eventTitle}
          setEventTitle={setEventTitle}
          eventDescription={eventDescription}
          setEventDescription={setEventDescription}
          eventAddress={eventAddress}
          setEventAddress={setEventAddress}
          imageSelector={imageSelector}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          questions={registrationQuestions}
          setQuestions={setRegistrationQuestions}
          eventRoleTypes={event_role_types}
          published={event.event_published}
        />
      </SubmissionForm>
    </FormPage>
  );
}

EditEventPage.loader = async ({ params }) => {
  const { event } = await getEvent({ event_id: params.event_id });

  const { default_registration_questions } =
    await getDefaultRegistrationQuestions();
  const { event_role_types } = await getEventRoleTypes();

  return { event, default_registration_questions, event_role_types };
};

EditEventPage.action = submissionActionFactory(updateEvent);

export default EditEventPage;
