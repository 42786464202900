import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Skill from "./Skill";

export default function SkillCategory({
  characterBuild,
  character,
  skill_type,
  skills,
  displayOption,
  renderAlchemyPyramidDisplay,
  renderSpellPyramidDisplay,
  renderAmountDisplay,
}) {
  if (
    !Object.keys(skills).length ||
    Object.keys(skills).every((subtype) => !skills[subtype].length)
  ) {
    return null;
  }
  return (
    <Row>
      <Col>
        <div className="box">
          <Row>
            <Col className="text-capitalize skill-category-title">
              {skill_type.split("_").join(" ")}
            </Col>
          </Row>
          {Object.keys(skills).map((skill_subtype) => {
            if (!skills[skill_subtype].length) {
              return null;
            }
            return (
              <div key={skill_subtype}>
                {skill_subtype === "default" ? null : (
                  <Row key={skill_subtype}>
                    <Col className="subcategory-title text-capitalize">
                      {skill_subtype.split("_").join(" ").split(",").join(", ")}
                    </Col>
                  </Row>
                )}
                {skills[skill_subtype].map((skill) => (
                  <Skill
                    key={skill._id}
                    characterBuild={characterBuild}
                    characterLevel={character.character_level}
                    skill={skill}
                    displayOption={displayOption}
                    renderAlchemyPyramidDisplay={renderAlchemyPyramidDisplay}
                    renderSpellPyramidDisplay={renderSpellPyramidDisplay}
                    renderAmountDisplay={renderAmountDisplay}
                  />
                ))}
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}
