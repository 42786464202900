import React from "react";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function FormPage({ backLabel, backLink, children }) {
  return (
    <Container className="form-page">
      {backLabel ? (
        <Link to={backLink}>
          <Button className="back-button">{backLabel}</Button>
        </Link>
      ) : null}
      <Row>
        <Col xs="auto">{children}</Col>
      </Row>
    </Container>
  );
}

export default FormPage;
