import CharacterSheetFactory from "./factory/CharacterSheetFactory";

export default function PrintCharacterViewer({ character, skills, children }) {
  return (
    <CharacterSheetFactory
      character={character}
      skills={skills}
      children={children}
      skin="print"
      mode="view"
    />
  );
}
