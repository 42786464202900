import Form from "react-bootstrap/Form";

import UserTable, { UserTableColumn } from "../UserTable";

export default function UserSelectionTable({
  selectedUsers,
  setSelectedUsers,
  allUsers,
}) {
  const handleCheck = (event) => {
    let newList = [...selectedUsers];
    if (event.target.checked) {
      newList = [...newList, event.target.value];
    } else {
      newList.splice(selectedUsers.indexOf(event.target.value), 1);
    }
    setSelectedUsers(newList);
  };

  return (
    <UserTable
      users={allUsers}
      leftColumns={[
        new UserTableColumn("Select", "center-align-column", (user) => (
          <Form.Check
            value={user._id}
            type="checkbox"
            onChange={handleCheck}
            checked={selectedUsers.includes(user._id)}
          />
        )),
      ]}
    />
  );
}
