import Form from "react-bootstrap/Form";

function optionForRace(race) {
  return (
    <option key={race._id} value={String(race._id)}>
      {[race.subculture_name, race.race_name].join(" ")}
    </option>
  );
}

export default function RaceDropDown({ setRace, races, race, disabled }) {
  const single_races = [];
  const subculture_races = {};
  races.forEach((race) => {
    if (race.subculture_name) {
      subculture_races[race.race_name] ||= [];
      subculture_races[race.race_name].push(race);
    } else {
      single_races.push(race);
    }
  });
  return (
    <Form.Select
      className="text-capitalize"
      onChange={(event) => {
        setRace(races.find((race) => race._id === event.target.value));
      }}
      value={String(race._id)}
      disabled={disabled}
    >
      {single_races.map((race) => optionForRace(race))}
      {Object.keys(subculture_races).map((key) => {
        return (
          <optgroup key={key} label={key}>
            {subculture_races[key].map((race) => optionForRace(race))}
          </optgroup>
        );
      })}
    </Form.Select>
  );
}
