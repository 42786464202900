import React, { useState } from "react";
import { useFetcher, useLoaderData } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import {
  getUnplayedUserCharacters,
  patchUpdatePassword,
} from "../utils/apiInterface";
import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "../components/FetchComponent";
import DeleteCharacterButton from "../components/buttons/DeleteCharacterButton";

function AccountPage() {
  const { characters } = useLoaderData();

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [charSelection, setCharSelection] = useState("");
  const charToDelete = characters.find((char) => char._id === charSelection);

  const fetcher = useFetcher();
  const handleUpdatePassword = async (event) => {
    submitFetchFactory(
      {
        password_current: currentPassword,
        password,
        password_confirm: passwordConfirm,
      },
      { method: "post", action: "/account/update_password" },
      fetcher
    )(event);
    setCurrentPassword("");
    setPassword("");
    setPasswordConfirm("");
  };

  return (
    <>
      <div className="mb-3">
        <FetchComponent
          pendingMessage="Updating your password..."
          fetchedMessage="Password updated."
          errorMessage="Error updating password."
          invalidMessage="Invalid data submitted."
          fetcher={fetcher}
        >
          <h2>Change Password:</h2>
          <Form onSubmit={handleUpdatePassword}>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formCurrentPassword"
            >
              <Form.Label column sm={2}>
                Input current password
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={(event) => setCurrentPassword(event.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formNewPassword">
              <Form.Label column sm={2}>
                New password
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formConfirmPassword"
            >
              <Form.Label column sm={2}>
                Confirm new password
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  value={passwordConfirm}
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                />
              </Col>
            </Form.Group>
            <Button type="submit">Change Password</Button>
          </Form>
        </FetchComponent>
      </div>
      <h2>Delete A Character:</h2>
      Only characters at 10 XP can be deleted. If you would like to delete a
      character who has attended an event, please contact the staff.
      {characters.length ? (
        <div className="d-flex flex-wrap">
          <Form.Select
            className="mb-2 me-2"
            onChange={(event) => setCharSelection(event.target.value)}
            value={charSelection}
            disabled={!characters.length}
          >
            <option value={""}>--Select Character--</option>
            {characters.map((char) => (
              <option key={char._id} value={char._id}>
                {char.saved_build.character_name}
              </option>
            ))}
          </Form.Select>
          {charToDelete ? (
            <DeleteCharacterButton
              character={charToDelete}
              deleteUserCharacter={true}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
}

AccountPage.loader = getUnplayedUserCharacters;

AccountPage.updatePasswordAction = fetchActionFactory(patchUpdatePassword);

export default AccountPage;
