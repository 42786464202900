import { useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import {
  checkPrerequisites,
  getSkillGrantOptions,
  skillIsGranted,
} from "character-build-helper";
import ConfirmationModal from "../../ConfirmationModal";

export default function SkillGrantSelectionModal({
  characterBuild,
  characterLevel,
  skill,
  options,
  setShow,
  show,
  confirmSelection,
}) {
  const [selectionList, setSelectionList] = useState([]);

  if (!skill.skill_grants) {
    return null;
  }

  const grant = skill.skill_grants[0];
  const selections = getSkillGrantOptions(skill)[0];

  const handleCheck = (event) => {
    let newList = [...selectionList];
    if (event.target.checked) {
      if (selectionList.length < grant.variety) {
        newList = [...newList, event.target.value];
      } else {
        event.target.checked = false;
      }
    } else {
      newList.splice(selectionList.indexOf(event.target.value), 1);
    }
    setSelectionList(newList);
  };

  return (
    <ConfirmationModal
      modalOpen={show}
      setModalOpen={(bool) => {
        setShow(bool);
        setSelectionList([]);
      }}
      onConfirm={() => {
        confirmSelection(selectionList);
      }}
      allowConfirm={selectionList.length === grant.variety}
      confirmationButtonText="Purchase"
    >
      <Row>
        <Col>Select {grant.variety}:</Col>
      </Row>
      {selections.map((skill) =>
        skillIsGranted(characterBuild, skill) ? null : (
          <Form.Group as={Row} className="mb-3" key={skill._id}>
            <Col sm="auto">
              <Form.Check
                onChange={handleCheck}
                value={skill.skill_name}
                disabled={
                  !checkPrerequisites(
                    characterLevel,
                    characterBuild,
                    skill,
                    1,
                    options
                  )
                }
              />
            </Col>
            <Col>
              <Form.Label>{skill.skill_name}</Form.Label>
            </Col>
          </Form.Group>
        )
      )}
    </ConfirmationModal>
  );
}
