const getCharacterSkill = require("./getCharacterSkill");

const {
  characterHasSkill,
  getSkillPurchaseCount,
  getSkillTotal,
} = require("./skillCountHelper");

class SkillError extends Error {
  constructor(message) {
    super(message);

    this.isOperational = true;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

class UnrecognizedPrerequisiteFormatError extends SkillError {
  constructor(prereqModel, skill) {
    super(
      `Cannot interpret prereq: ${JSON.stringify(prereqModel)} for skill: ${
        skill.name
      } ${skill._id}`
    );
  }
}

class UnrecognizedGrantFormatError extends UnrecognizedPrerequisiteFormatError {
  constructor(grantModel, skill) {
    super(grantModel, skill);
  }
}

class UnrecognizedSkillFormatError extends SkillError {
  constructor(skill) {
    super(`Cannot interpret skill: ${skill.skill_name} ${skill._id}`);
  }
}

class SkillGrant {
  constructor(count, granted_by, at_level) {
    this.count = count;
    this.granted_by = granted_by;
    this.at_level = at_level;
  }
}

function buildWithUpdatedSkill(characterBuild, skillId, characterSkill) {
  characterBuild = { ...characterBuild };
  characterBuild.character_skills = { ...characterBuild.character_skills };
  characterBuild.character_skills[skillId] = characterSkill;
  return characterBuild;
}

function getAllGrantsFromCharacterSkills(build, skills) {
  return skills
    .map((skill) => {
      const characterSkill = getCharacterSkill(build, skill);
      if (characterSkill) {
        if (!skill.skill_options) {
          return characterSkill.amount.grants;
        } else if (skill.skill_options.handed) {
          return ["left", "right"]
            .map((hand) => characterSkill.hand[hand].grants)
            .flat();
        } else if (skill.skill_options.magic_schools) {
          return skill.skill_options.magic_schools
            .map((school) => characterSkill.schools[school].grants)
            .flat();
        }
      }
      return [];
    })
    .flat();
}

function grantsEqual(g1, g2) {
  return ["count", "granted_by", "at_level"].every(
    (attr) => g1[attr] === g2[attr]
  );
}

function attachSkillMetaData(allSkills) {
  allSkills.forEach((skill) => {
    const prereqSkillNames = getPrereqSkills(
      skill.skill_prereq_model,
      allSkills
    ).map((sk) => sk.skill_name);
    // Also need to get skills with any matching names
    skill.prereq_skills = allSkills.filter((sk) =>
      prereqSkillNames.includes(sk.skill_name)
    );
    skill.prereq_skills.forEach((prereqSkill) => {
      prereqSkill.prereq_of ||= [];
      prereqSkill.prereq_of.push(skill);
    });
    const grantSkillNames =
      skill.skill_grants
        ?.reduce(
          (accumulator, grant) => [
            ...accumulator,
            ...getPrereqSkills({ skill: grant }, allSkills),
          ],
          []
        )
        .map((sk) => sk.skill_name) || [];
    // Also need to get skills with any matching names
    skill.skills_to_grant = allSkills.filter((sk) =>
      grantSkillNames.includes(sk.skill_name)
    );
    if (skill.skill_upgrade_list) {
      upgradeIndex = skill.skill_upgrade_list.findIndex(
        (skillName) => skill.skill_name === skillName
      );
      skill.prev_upgrade = allSkills.find(
        (upgradeSkill) =>
          upgradeSkill.skill_name === skill.skill_upgrade_list[upgradeIndex - 1]
      );
      skill.next_upgrade = allSkills.find(
        (upgradeSkill) =>
          upgradeSkill.skill_name === skill.skill_upgrade_list[upgradeIndex + 1]
      );
    }
  });
  allSkills.map((skill) => {
    return {
      name: skill.skill_name,
      prereqs: skill.skill_prereq_model,
      prereq_skills: skill.prereq_skills,
      prereq_of: skill.prereq_of,
      skills_to_grant: skill.skills_to_grant,
      prev_upgrade: skill.prev_upgrade,
      next_upgrade: skill.next_upgrade,
    };
  });
}

function classCanPurchase(characterBuild, skill) {
  const skillCostModel = skill.skill_cost_model;
  const costModel = Array.isArray(skillCostModel[0])
    ? skillCostModel[0][0]
    : skillCostModel[0];
  if (!costModel) return null;
  return (
    costModel.hasOwnProperty(characterBuild.character_class) ||
    costModel.hasOwnProperty("all")
  );
}

function checkTraitGroupPrereq(characterBuild, prereqModel) {
  const traitGroup = prereqModel?.trait_group;
  return (
    !traitGroup ||
    characterBuild.character_race.race_attributes.includes(traitGroup)
  );
}

function getPurchasableSkills(characterBuild, skills) {
  return skills.filter(
    (skill) =>
      checkTraitGroupPrereq(characterBuild, skill.skill_prereq_model) &&
      classCanPurchase(characterBuild, skill)
  );
}

function getSpecialtyPyramid(characterBuild, skill, specialtyIndex) {
  if (skill.skill_options.spell_slot) {
    return (
      getCharacterSkill(characterBuild, skill).levels?.[specialtyIndex] || [
        0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]
    );
  } else if (skill.skill_options.alchemy_slot) {
    return (
      getCharacterSkill(characterBuild, skill).levels?.[specialtyIndex] || [
        0, 0, 0, 0, 0, 0,
      ]
    );
  } else {
    throw new UnrecognizedSkillFormatError(skill);
  }
}

function skillCostForLevel(skill, characterBuild, level, schoolRank) {
  if (level < 1) return null;
  const skillCostModel = skill.skill_cost_model;
  let costModelForLevel;
  if (skill.skill_options?.spell_slot) {
    costModelForLevel =
      skillCostModel[schoolRank > 2 ? 2 : schoolRank][level - 1];
  } else {
    costModelForLevel =
      skillCostModel[skillCostModel.length > 1 ? level - 1 : 0];
  }
  let cost =
    costModelForLevel?.[characterBuild.character_class] ||
    costModelForLevel?.all;
  return cost;
}

function displaySkillCostForLevel(
  skill,
  characterBuild,
  level,
  allSkills,
  schoolRank
) {
  const cost = skillCostForLevel(skill, characterBuild, level, schoolRank);
  if (!cost || !skill.skill_grants || skill.skill_max_purchases !== 1) {
    return cost;
  }
  const subtractSkills = allSkills.filter((sk) =>
    skill.skill_grants.includes(sk.skill_name)
  );
  let amountToSubtract = 0;
  subtractSkills.forEach((skill) => {
    const skillLevel = getCharacterSkill(characterBuild, skill).amount
      .purchase_count;
    if (skillLevel > 0) {
      amountToSubtract += displaySkillCostForLevel(
        skill,
        characterBuild,
        skillLevel,
        allSkills
      );
    }
  });
  return cost - amountToSubtract;
}

function increaseSkillPurchaseAmount(
  characterBuild,
  skill,
  options,
  row,
  specialty_index,
  allSkills,
  grantOptions
) {
  let updatedBuild = adjustSkillPurchaseAmount(
    1,
    characterBuild,
    skill,
    options,
    row,
    specialty_index
  );
  const skillCost = skillCostForLevel(
    skill,
    updatedBuild,
    row || getSkillTotal(updatedBuild, skill, options, row, specialty_index),
    specialty_index
  );
  skill.skill_grants?.forEach((grant, index) => {
    updatedBuild = addGrant(
      updatedBuild,
      skill,
      grant,
      grantOptions?.[index],
      allSkills
    );
  });
  updatedBuild.character_build_cost += skillCost;
  return updatedBuild;
}

function decreaseSkillPurchaseAmount(
  characterBuild,
  skill,
  options,
  row,
  specialty_index,
  allSkills
) {
  let updatedBuild = characterBuild;
  skill.skill_grants?.forEach((grant) => {
    updatedBuild = removeGrant(updatedBuild, skill, grant, allSkills);
  });
  const skillCost = skillCostForLevel(
    skill,
    updatedBuild,
    row
      ? row
      : getSkillTotal(updatedBuild, skill, options, row, specialty_index),
    specialty_index
  );
  updatedBuild = adjustSkillPurchaseAmount(
    -1,
    updatedBuild,
    skill,
    options,
    row,
    specialty_index
  );
  updatedBuild.character_build_cost -= skillCost;
  return updatedBuild;
}

function adjustSkillPurchaseAmount(
  amount,
  characterBuild,
  skill,
  options,
  row,
  specialty_index
) {
  const characterSkill = { ...getCharacterSkill(characterBuild, skill) };
  if (!skill.skill_options) {
    characterSkill.amount = { ...characterSkill.amount };
    characterSkill.amount.purchase_count += amount;
  } else if (skill.skill_options.handed) {
    characterSkill.hand = { ...characterSkill.hand };
    characterSkill.hand[options.hand] = {
      ...characterSkill.hand[options.hand],
    };
    characterSkill.hand[options.hand].purchase_count += amount;
  } else if (
    skill.skill_options.spell_slot ||
    skill.skill_options.alchemy_slot
  ) {
    characterSkill.levels = characterSkill.levels.slice();
    characterSkill.levels[specialty_index] =
      characterSkill.levels[specialty_index].slice();
    characterSkill.levels[specialty_index][row - 1] += amount;
  } else if (skill.skill_options.magic_schools) {
    characterSkill.schools = { ...characterSkill.schools };
    characterSkill.schools[options.magic_school] = {
      ...characterSkill.schools[options.magic_school],
    };
    characterSkill.schools[options.magic_school].purchase_count += amount;
  } else {
    throw new UnrecognizedSkillFormatError(skill);
  }
  return buildWithUpdatedSkill(characterBuild, skill._id, characterSkill);
}

function addGrant(
  characterBuild,
  grantedBySkill,
  grant,
  grantOptions,
  allSkills
) {
  if (grant.variety) {
    grantOptions.forEach(
      (grantOption) =>
        (characterBuild = addGrant(
          characterBuild,
          grantedBySkill,
          grantOption,
          allSkills
        ))
    );
    return characterBuild;
  }

  const skillToGrant = getSkillToGrant(grant, grantedBySkill);
  const skillGrant = getDeterminedSkillGrant(
    grant,
    grantedBySkill,
    getSkillTotal(characterBuild, grantedBySkill)
  );

  const characterSkill = { ...getCharacterSkill(characterBuild, skillToGrant) };
  let costAlreadyPaid = 0;
  if (!skillIsGranted(characterBuild, skillToGrant)) {
    costAlreadyPaid = skillCostForLevel(
      skillToGrant,
      characterBuild,
      getSkillTotal(characterBuild, skillToGrant)
    );
  }
  characterSkill.amount = { ...characterSkill.amount };
  characterSkill.amount.grants = [...characterSkill.amount.grants, skillGrant];
  characterBuild = buildWithUpdatedSkill(
    characterBuild,
    skillToGrant._id,
    characterSkill
  );
  characterBuild.character_build_cost -= costAlreadyPaid;
  return characterBuild;
}

function getSkillToGrant(grant, grantedBySkill) {
  let skillToGrant;
  if (typeof grant === "string") {
    skillToGrant = getSkillsFromName(grant, grantedBySkill.skills_to_grant)[0];
  } else if (grant.name) {
    skillToGrant = getSkillsFromName(
      grant.name,
      grantedBySkill.skills_to_grant
    )[0];
  } else {
    throw new UnrecognizedGrantFormatError(grant, grantedBySkill);
  }
  if (skillToGrant.skill_options) {
    throw new UnrecognizedSkillFormatError(skillToGrant);
  } else if (grantedBySkill.skill_options) {
    throw new UnrecognizedSkillFormatError(grantedBySkill);
  }
  return skillToGrant;
}

function getDeterminedSkillGrant(grant, grantedBySkill, grantedBySkillLevel) {
  let count;
  if (typeof grant === "string") {
    count = 1;
  } else if (grant.name) {
    count = grant.count || 1;
  } else {
    throw new UnrecognizedGrantFormatError(grant, grantedBySkill);
  }
  return new SkillGrant(count, grantedBySkill.skill_name, grantedBySkillLevel);
}

function removeGrant(characterBuild, grantedBySkill, grant, allSkills) {
  const skillsToRemove = getPrereqSkills(
    { skill: grant },
    grantedBySkill.skills_to_grant
  );
  const count = grant.count || 1;
  skillsToRemove.forEach((skillToRemove) => {
    if (skillToRemove.skill_options) {
      throw new UnrecognizedSkillFormatError(skillToRemove);
    }

    const characterSkill = {
      ...getCharacterSkill(characterBuild, skillToRemove),
    };
    if (characterSkill.skill_options) {
      throw new UnrecognizedSkillFormatError(characterSkill);
    }

    const indexToRemove = characterSkill.amount.grants.findLastIndex(
      (prevGrant) =>
        grantsEqual(
          prevGrant,
          new SkillGrant(
            count,
            grantedBySkill.skill_name,
            getSkillTotal(characterBuild, grantedBySkill)
          )
        )
    );

    if (indexToRemove !== -1) {
      characterSkill.amount = { ...characterSkill.amount };
      characterSkill.amount.grants = characterSkill.amount.grants.slice();
      characterSkill.amount.grants.splice(indexToRemove, 1);
      characterBuild = buildWithUpdatedSkill(
        characterBuild,
        skillToRemove._id,
        characterSkill
      );
      if (!skillIsGranted(characterBuild, skillToRemove)) {
        const costToAddBack = skillCostForLevel(
          skillToRemove,
          characterBuild,
          getSkillTotal(characterBuild, skillToRemove),
          allSkills
        );
        characterBuild.character_build_cost += costToAddBack;
      }
    }
  });
  return characterBuild;
}

function skillIsGranted(characterBuild, skill) {
  if (skill.skill_options) return false;

  const characterSkill = getCharacterSkill(characterBuild, skill);
  return (
    characterSkill.amount.grants.length > 0 &&
    (skill.skill_max_purchases === 1 ||
      characterSkill.amount.purchase_count === 0)
  );
}

function skillIsGrantedBy(characterBuild, skill) {
  if (skill.skill_options) return [];

  return (
    getCharacterSkill(characterBuild, skill).amount.grants.map(
      (grant) => grant.granted_by
    ) || []
  );
}

function getSkillGrantOptions(skill) {
  const skillGrantOptions = [];
  for (const grant of skill.skill_grants) {
    if (grant?.variety) {
      if (grant?.tagged) {
        skillGrantOptions.push(
          skill.skills_to_grant.filter((sk) => {
            return sk.skill_tags.includes(grant.tagged);
          })
        );
      } else {
        skillGrantOptions.push([]);
      }
    } else {
      skillGrantOptions.push([]);
    }
  }
  return skillGrantOptions;
}

function getSpecialtyOptions(
  characterBuild,
  skill,
  specialtyIndex,
  specialtyList,
  specialtyKey
) {
  const characterSkill = getCharacterSkill(characterBuild, skill);
  const alreadyTakenSpecialties = characterSkill[specialtyKey].slice(
    0,
    specialtyList.length - 1
  );
  return specialtyList.filter(
    (specialty) =>
      !alreadyTakenSpecialties.includes(specialty) ||
      (specialtyIndex < specialtyList.length - 1 &&
        specialty === characterSkill[specialtyKey][specialtyIndex])
  );
}

const alchemySpecialties = ["apothecary", "poisoner", "bombardier"];

function getAlchemySpecialtyOptions(characterBuild, skill, specialtyIndex) {
  return getSpecialtyOptions(
    characterBuild,
    skill,
    specialtyIndex,
    alchemySpecialties,
    "specialties"
  );
}

function setAlchemySpecialty(
  characterBuild,
  skill,
  specialtyIndex,
  newSpecialty
) {
  const characterSkill = { ...getCharacterSkill(characterBuild, skill) };
  characterSkill.specialties = characterSkill.specialties.slice();
  characterSkill.specialties[specialtyIndex] = newSpecialty;
  if (!characterSkill.levels[specialtyIndex]) {
    characterSkill.levels[specialtyIndex] = [0, 0, 0, 0, 0, 0];
  }
  return buildWithUpdatedSkill(characterBuild, skill._id, characterSkill);
}

function removeLastAlchemySpecialty(characterBuild, skill) {
  const characterSkill = { ...getCharacterSkill(characterBuild, skill) };
  characterSkill.specialties = characterSkill.specialties.slice();
  characterSkill.specialties.pop();
  characterSkill.levels = characterSkill.levels.slice();
  characterSkill.levels.pop();
  return buildWithUpdatedSkill(characterBuild, skill._id, characterSkill);
}

const magicSchools = [
  "arcane",
  "elementalism",
  "manifestation",
  "immutation",
  "divine",
  "necromancy",
  "revelationism",
  "shamanism",
  "psionic",
];

function getSpellSchoolOptions(
  characterBuild,
  skill,
  schoolIndex,
  additionalSchools
) {
  return getSpecialtyOptions(
    characterBuild,
    skill,
    schoolIndex,
    [...magicSchools, ...(additionalSchools || [])],
    "spell_schools"
  );
}

function getAllowedSpellSchoolOptions(
  schoolOptions,
  characterLevel,
  characterBuild,
  skill
) {
  return schoolOptions.filter((school) => {
    const prereq =
      skill.skill_prereq_model.by_school[getSchoolUmbrella(school)];
    if (prereq) {
      return checkOnePrerequisite(
        characterLevel,
        characterBuild,
        skill,
        1,
        null,
        prereq
      );
    }
    return true;
  });
}

function setSpellSchool(characterBuild, skill, specialtyIndex, newSpecialty) {
  const characterSkill = { ...getCharacterSkill(characterBuild, skill) };
  characterSkill.spell_schools = characterSkill.spell_schools.slice();
  characterSkill.spell_schools[specialtyIndex] = newSpecialty;
  if (!characterSkill.levels[specialtyIndex]) {
    characterSkill.levels[specialtyIndex] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
  }
  return buildWithUpdatedSkill(characterBuild, skill._id, characterSkill);
}

function removeLastSpellSchool(characterBuild, skill) {
  const characterSkill = { ...getCharacterSkill(characterBuild, skill) };
  characterSkill.spell_schools = characterSkill.spell_schools.slice();
  characterSkill.spell_schools.pop();
  characterSkill.levels = characterSkill.levels.slice();
  characterSkill.levels.pop();
  return buildWithUpdatedSkill(characterBuild, skill._id, characterSkill);
}

function validPyramidLevelLocal(lowerAmount, levelAmount) {
  if (lowerAmount === 0) return !levelAmount;
  if (lowerAmount == null || levelAmount == null) return true;
  if (lowerAmount < levelAmount || levelAmount < 0) return false;

  return lowerAmount >= 3
    ? lowerAmount - levelAmount <= (lowerAmount === 3 ? 2 : 1)
    : [1, 2].includes(lowerAmount - levelAmount);
}

function pyramidLevelCanAdjust(levelAmounts, level, adjustment) {
  const maxLevel = levelAmounts.length;
  const levelAmount = levelAmounts[level - 1];
  const lowerLevelAmount = level === 1 ? null : levelAmounts[level - 2];
  const higherLevelAmount = level === maxLevel ? null : levelAmounts[level];
  return (
    validPyramidLevelLocal(lowerLevelAmount, levelAmount + adjustment) &&
    validPyramidLevelLocal(levelAmount + adjustment, higherLevelAmount)
  );
}

function pyramidLevelCanIncrease(levelAmounts, level) {
  return pyramidLevelCanAdjust(levelAmounts, level, 1);
}

function pyramidLevelCanDecrease(levelAmounts, level) {
  return pyramidLevelCanAdjust(levelAmounts, level, -1);
}

function checkCharacterLevelPrereq(
  characterLevel,
  prereqModel,
  skill,
  purchaseAmount
) {
  if (prereqModel.character_level.level_thresholds) {
    return (
      characterLevel >=
      prereqModel.character_level.level_thresholds[purchaseAmount - 1]
    );
  } else {
    throw new UnrecognizedPrerequisiteFormatError(prereqModel, skill);
  }
}

function checkSkillPrereq(
  characterBuild,
  prereqModel,
  skill,
  options,
  purchaseAmount
) {
  try {
    const prereqSkills = getPrereqSkills(prereqModel, skill.prereq_skills);
    if (!prereqSkills) {
      throw new SkillError(`skill not found ${JSON.stringify(prereqModel)}`);
    }
    const prereqSkillOptions = { ...options };
    if (prereqModel.skill.hand) {
      prereqSkillOptions.hand = prereqModel.skill.hand;
    }
    if (!prereqModel.skill.variety) {
      let timesTaken;
      if (prereqModel.skill.times_taken_per) {
        timesTaken = purchaseAmount * prereqModel.skill.times_taken_per;
      } else if (prereqModel.skill.times_taken) {
        timesTaken = prereqModel.skill.times_taken;
      } else {
        timesTaken = 1;
      }
      if (timesTaken === 1) {
        for (const prereqSkill of prereqSkills) {
          if (characterHasSkill(characterBuild, prereqSkill, options)) {
            return true;
          }
        }
      } else {
        let totalLevel = 0;
        for (const prereqSkill of prereqSkills) {
          totalLevel += getSkillTotal(
            characterBuild,
            prereqSkill,
            prereqSkillOptions
          );
          if (totalLevel >= timesTaken) {
            return true;
          }
        }
      }
    } else {
      const variety = prereqModel.skill.variety;
      let count = 0;
      for (const prereqSkill of prereqSkills) {
        if (characterHasSkill(characterBuild, prereqSkill, options)) {
          count += 1;
          if (count === variety) {
            return true;
          }
        }
      }
    }
  } catch (err) {
    throw new SkillError(
      `Error with prereq for ${skill.skill_name}: ${JSON.stringify(
        prereqModel
      )}\n${err.message}`
    );
  }
}

function checkSpellSlotPrereq(characterBuild, prereqModel, skill, options) {
  if (prereqModel.spell_slot.level) {
    const spellSlotSkill = skill.prereq_skills.find(
      (sk) => sk.skill_options?.spell_slot
    );
    const characterSkill = getCharacterSkill(characterBuild, spellSlotSkill);
    for (const schoolIndex in characterSkill.levels) {
      if (
        getSkillTotal(
          characterBuild,
          spellSlotSkill,
          null,
          prereqModel.spell_slot.level,
          schoolIndex
        ) > 0
      ) {
        if (prereqModel.spell_slot.matching_school) {
          if (
            underSchoolUmbrella(
              characterSkill.spell_schools[schoolIndex],
              options.magic_school
            )
          ) {
            return true;
          }
        } else if (prereqModel.spell_slot.allowed_schools) {
          for (const allowedSchool of prereqModel.spell_slot.allowed_schools) {
            if (
              underSchoolUmbrella(
                characterSkill.spell_schools[schoolIndex],
                allowedSchool
              )
            ) {
              return true;
            }
          }
        } else {
          return true;
        }
      }
    }
    return false;
  }
}

function checkAlchemySlotPrereq(characterBuild, prereqModel, skill) {
  const alchemySlotSkill = skill.prereq_skills.find(
    (sk) => sk.skill_options?.alchemy_slot
  );
  const characterSkill = getCharacterSkill(characterBuild, alchemySlotSkill);
  for (const schoolIndex in characterSkill.levels) {
    if (
      getSkillTotal(
        characterBuild,
        alchemySlotSkill,
        null,
        prereqModel.alchemy_slot.level,
        schoolIndex
      ) > 0
    ) {
      return true;
    }
  }
  return false;
}

function checkStrictPyramidPrereq(
  characterBuild,
  prereqModel,
  skill,
  options,
  purchaseAmount
) {
  const pyramidLevel = prereqModel.strict_pyramid.findIndex(
    (sk) => sk.skill === skill.skill_name
  );
  // check prev level
  if (pyramidLevel !== 0) {
    const lowerSkill = getSkillsFromName(
      prereqModel.strict_pyramid[pyramidLevel - 1].skill,
      skill.prereq_skills
    )[0];
    const lowerSkillLevel = getSkillTotal(characterBuild, lowerSkill, options);
    if (
      !(
        lowerSkillLevel >= purchaseAmount * 2 &&
        lowerSkillLevel <= (purchaseAmount + 1) * 2
      )
    ) {
      return false;
    }
  }
  // check higher levels
  for (let i = pyramidLevel + 1; i < prereqModel.strict_pyramid.length; i++) {
    const higherSkill = getSkillsFromName(
      prereqModel.strict_pyramid[i].skill,
      skill.prereq_skills
    )[0];
    const higherSkillLevel = getSkillTotal(
      characterBuild,
      higherSkill,
      options
    );
    if (
      !(
        purchaseAmount / 2 ** (i - pyramidLevel) - 1 <= higherSkillLevel &&
        purchaseAmount / 2 ** (i - pyramidLevel) >= higherSkillLevel
      )
    ) {
      return false;
    }
  }
  return true;
}

function checkPrerequisites(
  characterLevel,
  characterBuild,
  skill,
  purchaseAmount,
  options
) {
  const skillPrereqModel = skill.skill_prereq_model;
  if (!skillPrereqModel) {
    return true;
  }
  if (purchaseAmount === 0) {
    return true;
  } else if (!purchaseAmount) {
    if (!skill.skill_options) {
      return checkPrerequisites(
        characterLevel,
        characterBuild,
        skill,
        getSkillPurchaseCount(characterBuild, skill, options),
        options
      );
    } else if (skill.skill_options.handed) {
      if (options?.hand) {
        return checkPrerequisites(
          characterLevel,
          characterBuild,
          skill,
          getSkillPurchaseCount(characterBuild, skill, options),
          options
        );
      } else {
        return ["left", "right"].every((hand) => {
          const newOptions = { ...options, hand };
          return checkPrerequisites(
            characterLevel,
            characterBuild,
            skill,
            getSkillPurchaseCount(characterBuild, skill, newOptions),
            newOptions
          );
        });
      }
    } else if (skill.skill_options.spell_slot) {
      throw new SkillError("Spell slot prereqs cannot be checked.");
    } else if (skill.skill_options.alchemy_slot) {
      return checkPrerequisites(
        characterLevel,
        characterBuild,
        skill,
        1,
        options
      );
    } else if (skill.skill_options.magic_schools) {
      if (options?.magic_school) {
        return checkPrerequisites(
          characterLevel,
          characterBuild,
          skill,
          getSkillPurchaseCount(characterBuild, skill, options),
          options
        );
      } else {
        return skill.skill_options.magic_schools.every((magic_school) => {
          const newOptions = { ...options, magic_school };
          return checkPrerequisites(
            characterLevel,
            characterBuild,
            skill,
            getSkillPurchaseCount(characterBuild, skill, newOptions),
            newOptions
          );
        });
      }
    } else {
      throw new UnrecognizedSkillFormatError(skill);
    }
  }
  return checkOnePrerequisite(
    characterLevel,
    characterBuild,
    skill,
    purchaseAmount,
    options,
    skillPrereqModel
  );
}

function checkOnePrerequisite(
  characterLevel,
  characterBuild,
  skill,
  purchaseAmount,
  options,
  prereqModel
) {
  if (prereqModel?.op === "and") {
    for (const cond of prereqModel.conditions) {
      if (
        !checkOnePrerequisite(
          characterLevel,
          characterBuild,
          skill,
          purchaseAmount,
          options,
          cond
        )
      ) {
        return false;
      }
    }
    return true;
  } else if (prereqModel?.op === "or") {
    for (const cond of prereqModel.conditions) {
      if (
        checkOnePrerequisite(
          characterLevel,
          characterBuild,
          skill,
          purchaseAmount,
          options,
          cond
        )
      ) {
        return true;
      }
    }
    return false;
  } else if (prereqModel.trait_group) {
    return checkTraitGroupPrereq(characterBuild, prereqModel);
  } else if (prereqModel.character_level) {
    return checkCharacterLevelPrereq(
      characterLevel,
      prereqModel,
      skill,
      purchaseAmount
    );
  } else if (prereqModel.skill) {
    return checkSkillPrereq(
      characterBuild,
      prereqModel,
      skill,
      options,
      purchaseAmount
    );
  } else if (prereqModel.spell_slot) {
    return checkSpellSlotPrereq(characterBuild, prereqModel, skill, options);
  } else if (prereqModel.alchemy_slot) {
    return checkAlchemySlotPrereq(characterBuild, prereqModel, skill);
  } else if (prereqModel.strict_pyramid) {
    return checkStrictPyramidPrereq(
      characterBuild,
      prereqModel,
      skill,
      options,
      purchaseAmount
    );
  }
  return false;
}

function getSkillsFromName(skillName, skills) {
  return skills.filter((sk) => sk.skill_name === skillName);
}

function underSchoolUmbrella(specificSchool, umbrellaSchool) {
  if (umbrellaSchool === "arcane")
    return ["arcane", "elementalism", "manifestation", "immutation"].includes(
      specificSchool
    );
  else if (umbrellaSchool === "divine")
    return ["divine", "necromancy", "revelationism", "shamanism"].includes(
      specificSchool
    );
  else return specificSchool === umbrellaSchool;
}

function getSchoolUmbrella(specificSchool) {
  if (
    ["arcane", "elementalism", "manifestation", "immutation"].includes(
      specificSchool
    )
  )
    return "arcane";
  else if (
    ["divine", "necromancy", "revelationism", "shamanism"].includes(
      specificSchool
    )
  )
    return "divine";
  else return specificSchool;
}

function getPrereqSkills(prereq, skills) {
  if (prereq === null) {
    return [];
  }
  if (["and", "or"].includes(prereq?.op)) {
    return prereq.conditions.reduce(
      (accumulatedSkills, condition) => [
        ...accumulatedSkills,
        ...getPrereqSkills(condition, skills),
      ],
      []
    );
  } else if (prereq.by_school) {
    return Object.values(prereq.by_school).reduce(
      (accumulatedSkills, condition) => [
        ...accumulatedSkills,
        ...getPrereqSkills(condition, skills),
      ],
      []
    );
  } else if (prereq.skill) {
    try {
      if (typeof prereq.skill === "string") {
        return getSkillsFromName(prereq.skill, skills);
      } else if (prereq.skill.name) {
        return getSkillsFromName(prereq.skill.name, skills);
      } else if (prereq.skill.tagged || prereq.skill.types) {
        return skills.filter((sk) => {
          if (
            prereq.skill.tagged &&
            sk.skill_tags?.includes(prereq.skill.tagged)
          ) {
            return true;
          }
          if (prereq.skill.types) {
            for (const typeObj of prereq.skill.types) {
              if (typeof typeObj === "string") {
                if (sk.skill_type === typeObj) {
                  return true;
                }
              } else {
                for (const type of Object.keys(typeObj)) {
                  for (const subtype of typeObj[type]) {
                    if (
                      sk.skill_type === type &&
                      sk.skill_subtype === subtype
                    ) {
                      return true;
                    }
                  }
                }
              }
            }
          }
          return false;
        });
      }
    } catch (err) {
      throw new SkillError(
        `Error with prereq for ${skill.skill_name}: ${JSON.stringify(
          prereqModel
        )}\n${err.message}`
      );
    }
  } else if (prereq.spell_slot) {
    return skills.filter((sk) => sk.skill_options?.spell_slot);
  } else if (prereq.alchemy_slot) {
    return skills.filter((sk) => sk.skill_options?.alchemy_slot);
  } else if (prereq.strict_pyramid) {
    return prereq.strict_pyramid.reduce(
      (accumulatedSkills, condition) => [
        ...accumulatedSkills,
        ...getPrereqSkills(condition, skills),
      ],
      []
    );
  }
  return [];
}

function skillBreaksBuild(skill, build, characterLevel, options) {
  for (const reliantSkill of skill.prereq_of) {
    if (!reliantSkill.skill_options) {
      const reliantSkillLevel = getSkillTotal(build, reliantSkill, options);
      if (
        !checkPrerequisites(
          characterLevel,
          build,
          reliantSkill,
          reliantSkillLevel
        )
      ) {
        return true;
      }
    } else if (reliantSkill.skill_options.handed) {
      for (const hand of ["left", "right"]) {
        const reliantSkillLevel = getSkillTotal(build, reliantSkill, {
          ...options,
          ...{ hand },
        });
        if (
          !checkPrerequisites(
            characterLevel,
            build,
            reliantSkill,
            reliantSkillLevel,
            { ...options, ...{ hand } }
          )
        ) {
          return true;
        }
      }
    } else if (reliantSkill.skill_options.spell_slot) {
      const chosenSchools = getCharacterSkill(
        build,
        reliantSkill
      ).spell_schools;
      const allowedSchools = getAllowedSpellSchoolOptions(
        chosenSchools,
        characterLevel,
        build,
        reliantSkill
      );
      if (allowedSchools.length !== chosenSchools.length) {
        return true;
      }
    } else if (reliantSkill.skill_options.alchemy_slot) {
      if (
        !checkPrerequisites(
          characterLevel,
          build,
          reliantSkill,
          getSkillTotal(build, reliantSkill, null, 1, 0) === 0 ? 0 : 1
        )
      ) {
        return true;
      }
    } else if (reliantSkill.skill_options.magic_schools) {
      for (const magic_school of reliantSkill.skill_options.magic_schools) {
        const reliantSkillLevel = getSkillTotal(build, reliantSkill, {
          ...options,
          ...{ magic_school },
        });
        if (
          !checkPrerequisites(
            characterLevel,
            build,
            reliantSkill,
            reliantSkillLevel,
            { ...options, ...{ magic_school } }
          )
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

function skillIsNecessary(
  skill,
  characterLevel,
  characterBuild,
  options,
  row,
  specialtyIndex,
  allSkills
) {
  if (!skill.prereq_of) {
    return false;
  }
  const buildWithoutSkill = decreaseSkillPurchaseAmount(
    characterBuild,
    skill,
    options,
    row,
    specialtyIndex,
    allSkills
  );
  return skillBreaksBuild(skill, buildWithoutSkill, characterLevel, options);
}

function checkSkillReceiptNotRegressed(
  buildReceipt,
  committedReceipt,
  committedBuild,
  skill
) {
  if (buildReceipt.purchase_count < committedReceipt.purchase_count) {
    return false;
  }
  if (!committedReceipt.grants.length) return true;
  if (
    skill.skill_max_purchases === 1 &&
    skillIsGranted(committedBuild, skill) &&
    buildReceipt.purchase_count != committedReceipt.purchase_count
  ) {
    return false;
  }
  return committedReceipt.grants.every((grant, index) =>
    grantsEqual(grant, buildReceipt.grants[index])
  );
}

function checkSkillNotRegressed(build, committed, skill) {
  const buildSkill = getCharacterSkill(build, skill);
  const committedSkill = getCharacterSkill(committed, skill);

  if (!skill.skill_options) {
    return checkSkillReceiptNotRegressed(
      buildSkill.amount,
      committedSkill.amount,
      committed,
      skill
    );
  } else if (skill.skill_options.handed) {
    return ["left", "right"].every((hand) =>
      checkSkillReceiptNotRegressed(
        buildSkill.hand[hand],
        committedSkill.hand[hand],
        committed,
        skill
      )
    );
  } else if (skill.skill_options.spell_slot) {
    if (!committedSkill.spell_schools.length) return true;
    if (
      !committedSkill.spell_schools.every(
        (school, index) => school === buildSkill.spell_schools[index]
      )
    ) {
      return false;
    }
    return committedSkill.levels.every((amounts, index) => {
      const pyramid = getSpecialtyPyramid(build, skill, index);
      return amounts.every((amount, level) => amount <= pyramid[level]);
    });
  } else if (skill.skill_options.alchemy_slot) {
    if (!committedSkill.specialties.length) return true;
    if (
      !committedSkill.specialties.every(
        (specialty, index) =>
          !specialty || specialty === buildSkill.specialties[index]
      )
    ) {
      return false;
    }
    return committedSkill.levels.every((amounts, index) => {
      const pyramid = getSpecialtyPyramid(build, skill, index);
      return amounts.every((amount, level) => amount <= pyramid[level]);
    });
  } else if (skill.skill_options.magic_schools) {
    return skill.skill_options.magic_schools.every((school) =>
      checkSkillReceiptNotRegressed(
        buildSkill.schools[school],
        committedSkill.schools[school],
        committed,
        skill
      )
    );
  } else {
    throw new UnrecognizedSkillFormatError(skill);
  }
}

module.exports = {
  SkillGrant,
  getAllGrantsFromCharacterSkills,
  grantsEqual,
  attachSkillMetaData,
  getPurchasableSkills,
  displaySkillCostForLevel,
  skillCostForLevel,
  increaseSkillPurchaseAmount,
  decreaseSkillPurchaseAmount,
  skillIsGranted,
  skillIsGrantedBy,
  getSkillGrantOptions,
  getSkillToGrant,
  getDeterminedSkillGrant,
  getAlchemySpecialtyOptions,
  setAlchemySpecialty,
  removeLastAlchemySpecialty,
  getSpellSchoolOptions,
  getAllowedSpellSchoolOptions,
  setSpellSchool,
  getSpecialtyPyramid,
  removeLastSpellSchool,
  validPyramidLevelLocal,
  pyramidLevelCanIncrease,
  pyramidLevelCanDecrease,
  checkPrerequisites,
  checkOnePrerequisite,
  getSkillsFromName,
  getSchoolUmbrella,
  getPrereqSkills,
  skillBreaksBuild,
  skillIsNecessary,
  checkSkillNotRegressed,
  alchemySpecialties,
  magicSchools,
};
