import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { getPurchasableSkills } from "character-build-helper";
import { useState } from "react";
import SkillCategory from "./SkillCategory";

function sortSkillsByName(skills, atBeginning, atEnd) {
  skills?.sort((skill_1, skill_2) => {
    const beg_ind1 = atBeginning
      ? atBeginning.findIndex((skill_name) => skill_1.skill_name === skill_name)
      : -1;
    const beg_ind2 = atBeginning
      ? atBeginning.findIndex((skill_name) => skill_2.skill_name === skill_name)
      : -1;
    const end_ind1 = atEnd
      ? atEnd.findIndex((skill_name) => skill_1.skill_name === skill_name)
      : -1;
    const end_ind2 = atEnd
      ? atEnd.findIndex((skill_name) => skill_2.skill_name === skill_name)
      : -1;
    if (beg_ind1 < 0 && beg_ind2 < 0 && end_ind1 < 0 && end_ind2 < 0) {
      return skill_1.skill_name.localeCompare(skill_2.skill_name);
    } else if (beg_ind1 >= 0 && beg_ind2 >= 0) {
      return beg_ind1 - beg_ind2;
    } else if (end_ind1 >= 0 && end_ind2 >= 0) {
      return end_ind1 - end_ind2;
    } else if (beg_ind1 >= 0 || end_ind2 >= 0) {
      return -1;
    } else {
      return 1;
    }
  });
}

function getSkillsMappedToType(characterBuild, skills) {
  const typeMap = {
    racial: {},
    class: {},
    weapon: {},
    academic: {},
    mental: {},
    magic: {},
    crafting: {},
    general: {},
    strike: { fighter: [], rogue: [] },
    maneuver: {},
    advanced_maneuver: {},
    stance: {
      shield: [],
      dual_wield: [],
      duelist: [],
      "dual_wield,duelist": [],
      two_handed: [],
      ranged: [],
      "fighter,templar": [],
      "rogue,shadow": [],
    },
  };
  getPurchasableSkills(characterBuild, skills).forEach((skill) => {
    typeMap[skill.skill_type] = typeMap[skill.skill_type] || {};
    typeMap[skill.skill_type][skill.skill_subtype || "default"] =
      typeMap[skill.skill_type][skill.skill_subtype || "default"] || [];
    typeMap[skill.skill_type][skill.skill_subtype || "default"].push(skill);
  });
  sortSkillsByName(typeMap.strike.fighter, [
    "Critical Strike",
    "Perilous Strike",
    "Vital Strike",
    "Savage Strike",
  ]);
  sortSkillsByName(typeMap.strike.rogue, [
    "Precise Strike",
    "Viper Strike",
    "Lotus Strike",
    "Cunning Strike",
  ]);
  sortSkillsByName(typeMap.crafting.default, null, ["Alchemy"]);
  sortSkillsByName(typeMap.magic.default, null, ["Spell Slot"]);
  return typeMap;
}

export default function SkillList({
  skills,
  character,
  characterBuild,
  mode,
  renderAlchemyPyramidDisplay,
  renderSpellPyramidDisplay,
  renderAmountDisplay,
}) {
  const [displayOption, setDisplayOption] = useState("all");

  const skillsMappedToType = getSkillsMappedToType(characterBuild, skills);
  return (
    <>
      <Row className="align-items-center">
        <Col className="title" xs="auto">
          Skills
        </Col>
        {mode === "edit" ? (
          <Col>
            <Form>
              <Form.Group>
                <Form.Check
                  inline
                  className="me-2"
                  type="radio"
                  label="All Skills"
                  checked={displayOption === "all"}
                  onChange={() => setDisplayOption("all")}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Purchasable Skills"
                  onChange={() => setDisplayOption("purchasable")}
                  checked={displayOption === "purchasable"}
                />
              </Form.Group>
            </Form>
          </Col>
        ) : null}
      </Row>
      {Object.keys(skillsMappedToType).map((skill_type) => (
        <SkillCategory
          key={skill_type}
          displayOption={displayOption}
          skills={skillsMappedToType[skill_type]}
          skill_type={skill_type}
          characterBuild={characterBuild}
          character={character}
          renderAlchemyPyramidDisplay={renderAlchemyPyramidDisplay}
          renderSpellPyramidDisplay={renderSpellPyramidDisplay}
          renderAmountDisplay={renderAmountDisplay}
        />
      ))}
    </>
  );
}
