import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import {
  checkPrerequisites,
  getCharacterSkill,
  getSkillTotal,
  skillIsUpgraded,
} from "character-build-helper";

export default function BasicSkill({
  skill,
  characterBuild,
  characterLevel,
  displayOption,
  renderAmountDisplay,
}) {
  let optionName;
  let options;
  if (!skill.skill_options) {
    if (skillIsUpgraded(characterBuild, skill)) {
      return null;
    }
    optionName = null;
    options = [null];
  } else if (skill.skill_options.handed) {
    optionName = "hand";
    options = ["left", "right"];
  } else if (skill.skill_options.magic_schools) {
    optionName = "magic_school";
    options = skill.skill_options.magic_schools;
  }

  let anyOption = false;
  const amountDisplays = options.map((option) => {
    const optionParam = option == null ? null : { [optionName]: option };
    const totalAmount = getSkillTotal(characterBuild, skill, optionParam);
    const purchaseLevel =
      (skill.prev_upgrade
        ? getSkillTotal(
            characterBuild,
            getCharacterSkill(characterBuild, skill.prev_upgrade),
            optionParam
          )
        : totalAmount) + 1;
    const prereqMet = checkPrerequisites(
      characterLevel,
      characterBuild,
      skill,
      purchaseLevel,
      optionParam
    );

    if (displayOption === "purchasable" && !prereqMet && totalAmount < 1) {
      return null;
    } else {
      anyOption = true;
    }

    return (
      <>
        {option == null ? null : (
          <Col className="text-capitalize" xs={3} lg={2}>
            {option}:
          </Col>
        )}
        {renderAmountDisplay(skill, optionParam, prereqMet)}
      </>
    );
  });

  if (anyOption) {
    return (
      <Row className="align-items-center skill">
        <Col xs={4} sm={5} lg={3} xxl={2}>
          <OverlayTrigger
            placement={"top"}
            overlay={<Tooltip>{skill.skill_description}</Tooltip>}
          >
            <span>{skill.skill_name}</span>
          </OverlayTrigger>
        </Col>
        <Col>
          {amountDisplays?.map((row, index) => (
            <Row key={index}>{row}</Row>
          ))}
        </Col>
      </Row>
    );
  }
}
