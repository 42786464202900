import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function EventCardList({ event_card_type, events, ...props }) {
  const EventCardType = event_card_type;
  return (
    <Row>
      {events.map((event) => {
        return (
          <Col className="mb-3" xs={12} md={6} lg={4} key={event._id}>
            <EventCardType event={event} {...props} />
          </Col>
        );
      })}
    </Row>
  );
}

export default EventCardList;
