import React, { useState } from "react";
import { useLoaderData, useSubmit } from "react-router-dom";

import { getRaces } from "../utils/apiInterface";
import { postNewCharacter } from "../utils/apiInterface";
import CharacterAttributesForm from "../components/forms/CharacterAttributesForm";
import SubmissionForm, {
  submissionActionFactory,
  submitFormFactory,
} from "../components/SubmissionForm";
import FormPage from "./FormPage";

function NewCharacterPage() {
  const { races } = useLoaderData();
  const submit = useSubmit();

  const [characterName, setCharacterName] = useState("");
  const [characterRace, setCharacterRace] = useState(races[0]);
  const [characterClass, setCharacterClass] = useState("fighter");
  const [chosenAttribute, setChosenAttribute] = useState(
    characterRace.attribute_choice?.[0]
  );

  const handleCreate = submitFormFactory(
    {
      character_name: characterName,
      race: characterRace,
      chosen_attribute: chosenAttribute,
      character_class: characterClass,
    },
    submit
  );

  return (
    <FormPage backLabel="Back" backLink="/">
      <SubmissionForm
        onSubmit={handleCreate}
        submitLabel="Save"
        navigateOnSubmit="/"
        pendingMessage="Please wait while we initialize your character..."
        errorMessage="Error initializing character. Please try again later."
        invalidMessage="Incorrect info. Please try again."
      >
        <h1>Provide us with some basic information about your character:</h1>
        <h2>
          This can be changed until the first time your character is committed
        </h2>
        <CharacterAttributesForm
          races={races}
          setCharacterName={setCharacterName}
          setCharacterRace={setCharacterRace}
          setCharacterClass={setCharacterClass}
          setChosenAttribute={setChosenAttribute}
          characterName={characterName}
          characterRace={characterRace}
          characterClass={characterClass}
          chosenAttribute={chosenAttribute}
        />
      </SubmissionForm>
    </FormPage>
  );
}

NewCharacterPage.loader = async () => getRaces();

NewCharacterPage.action = submissionActionFactory(postNewCharacter);

export default NewCharacterPage;
