import { Link, Outlet, useNavigate } from "react-router-dom";

import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AdminLinks from "../components/AdminLinks";
import { postLogout } from "../utils/apiInterface";
import VisibleForRoles from "../components/VisibleForRoles";

function HomeNavBar() {
  const navigate = useNavigate();

  return (
    <Container className="home-navbar-container" fluid>
      <Row>
        <Col className="p-0">
          <Navbar expand="sm">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <div>
                <Link
                  onClick={async () => {
                    await postLogout();
                    navigate("/login");
                  }}
                >
                  Sign Out
                </Link>
                <Link to="/">Home</Link>
                <Link to="/events">Events</Link>
                <Link to="/account">Account</Link>
                <br />
                <VisibleForRoles roles={["admin"]}>
                  <AdminLinks />
                </VisibleForRoles>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
      <Row className="home-navbar-content">
        <Col>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default HomeNavBar;
