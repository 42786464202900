import { useFetcher } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { postResendVerification } from "../utils/apiInterface";
import FetchComponent, {
  fetchActionFactory,
  submitFetchFactory,
} from "./FetchComponent";
import { useState } from "react";

function SendVerifyComponent({ email, inputEmail }) {
  const verificationFetcher = useFetcher();

  const [emailValue, setEmailValue] = useState(email);

  const submitFunction = submitFetchFactory(
    { email: emailValue },
    { method: "post", action: `/resend_verification` },
    verificationFetcher
  );

  return (
    <FetchComponent
      fetcher={verificationFetcher}
      pendingMessage="Sending..."
      fetchedMessage="Sent! Please check your email."
      errorMessage="Error sending email, please try again later"
      invalidMessage="A problem occurred, please try again."
    >
      {inputEmail ? (
        <Form.Group as={Row} className="mb-3" controlId="formEmail">
          <Form.Label column sm={1}>
            Email
          </Form.Label>
          <Col sm={11}>
            <Form.Control
              type="email"
              placeholder="Email"
              value={emailValue}
              onChange={(event) => setEmailValue(event.target.value)}
            />
          </Col>
        </Form.Group>
      ) : null}
      <Button onClick={submitFunction}>Send Verification Email</Button>
    </FetchComponent>
  );
}

SendVerifyComponent.sendAction = fetchActionFactory(postResendVerification);

export default SendVerifyComponent;
