import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";

export default function RegistrationAcceptanceTable({
  eventRegistrations,
  acceptanceState,
  setAcceptanceState,
  waitlistMessages,
  setWaitlistMessages,
  setSelection,
}) {
  const handleAccept = (eventRegistration) => {
    if (acceptanceState[eventRegistration._id] == "accept") {
      setAcceptanceState(
        Object.fromEntries(
          Object.entries(acceptanceState).filter(
            ([k, _v]) => k != eventRegistration._id
          )
        )
      );
    } else {
      setAcceptanceState({
        ...acceptanceState,
        [eventRegistration._id]: "accept",
      });
    }
  };

  const handleWaitlist = (eventRegistration) => {
    if (acceptanceState[eventRegistration._id] == "waitlist") {
      setAcceptanceState(
        Object.fromEntries(
          Object.entries(acceptanceState).filter(
            ([k, _v]) => k != eventRegistration._id
          )
        )
      );
    } else {
      setAcceptanceState({
        ...acceptanceState,
        [eventRegistration._id]: "waitlist",
      });
    }
  };

  const updateWaitlistMessage = (eventRegistration, message) => {
    setWaitlistMessages({
      ...waitlistMessages,
      [eventRegistration._id]: message,
    });
  };

  return (
    <Table className="acceptance-list" responsive>
      <thead>
        <tr>
          <th>Accept</th>
          <th>Waitlist</th>
          <th>User</th>
          <th>Role/Character</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        {eventRegistrations.map((eventRegistration) => (
          <tr
            key={eventRegistration._id}
            onClick={() => {
              setSelection(eventRegistration);
            }}
          >
            <td>
              <Form.Check
                onChange={() => handleAccept(eventRegistration)}
                checked={acceptanceState[eventRegistration._id] === "accept"}
              />
            </td>
            <td>
              <Form.Check
                onChange={() => handleWaitlist(eventRegistration)}
                checked={acceptanceState[eventRegistration._id] === "waitlist"}
              />
            </td>
            <td>
              {eventRegistration.user.first_name +
                " " +
                eventRegistration.user.last_name}
            </td>
            <td>
              {eventRegistration.event_role === "npc"
                ? "NPC"
                : eventRegistration.character.committed_build?.character_name ||
                  eventRegistration.character.saved_build.character_name}
            </td>
            <td>
              <Form.Control
                type="text"
                required
                disabled={acceptanceState[eventRegistration._id] !== "waitlist"}
                value={
                  acceptanceState[eventRegistration._id] === "waitlist"
                    ? waitlistMessages[eventRegistration._id] || ""
                    : ""
                }
                onChange={(event) =>
                  updateWaitlistMessage(eventRegistration, event.target.value)
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
